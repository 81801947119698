import { Injectable } from '@angular/core';
import * as moment from 'moment';

// This is now the global format that should be used everywhere - as agreed with api
export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const DATETIME_FMT = {
  DATE_TIME_SLASH: 'DD/MM/YYYY HH:mm:ss',
  DATE_TIME_DASH: 'DD-MM-YYYY HH:mm:ss',
  DATE_SLASH: 'DD/MM/YYYY',
  DATE_DASH: 'DD-MM-YYYY',
  DATE_DASH_ISO_8601: 'YYYY-MM-DD',
  DATE_DASH_TIME_ISO_8601: 'YYYY-MM-DDTHH:mm',
  UTC_DATE_TIME: 'YYYY-MM-DD HH:mm:00[Z]',
  LEGACY_DATE: 'YYYY-MM-DD[T]00:00:00.0000000',
  FRIENDLY_DATE_TIME: 'dddd, MMMM Do [at] h:mma',
  TIME12HR: 'h:mma',
  TIME24HR: 'HH:mm',
};

@Injectable({
  providedIn: 'root',
})
export class DatesService {
  constructor() {}

  populateDate(dateString: string) {
    const [dd, mm, yyyy] = moment(dateString).isValid()
      ? moment(dateString).format('DD-MM-YYYY').split('-')
      : ['', '', ''];
    return { dd: dd, mm: mm, yyyy: yyyy };
  }

  // this is not a valid UTC date format by moments standard.
  getUTCDate(year: string, month: string, day: string): string {
    return year + '-' + month + '-' + day + ' 00:00:00Z';
  }

  /**
   * Converts a datestring into 'Today' or day of the week
   * @param strDateTime format DD/MM/YYYY or DD/MM/YYYY H:mm:ssss
   */
  getDayFromDateString(strDateTime: string, humanize = true) {
    const mo = this.parseDateTime(strDateTime);
    return mo.isSame(moment(), 'day') && humanize ? 'Today' : mo.format('dddd');
  }

  /**
   * Takes date string and tells you if its in the past
   * @param strDateTime format DD/MM/YYYY or DD/MM/YYYY H:mm:ssss
   */
  isInThePast(strDateTime: string) {
    if (!strDateTime) return false;
    const mo = this.parseDateTime(strDateTime);
    return mo.isBefore(moment(), 'minutes');
  }

  /**
   * Takes a date time string and using the clients clock decides if is within 30 minutes
   * @param strDateTime DD/MM/YYYY H:mm:ssss
   */
  isWithin30Minutes(strDateTime) {
    if (!strDateTime) return false;
    return this.parseDateTime(strDateTime).subtract(30, 'minutes').isBefore(moment());
  }

  /**
   * Converts dates from one format to another
   * @param strDateTime any date time format (default expected DD/MM/YYYY HH:mm:ss)
   * @param toFormat a moment format string
   * @param fromFormat a moment format string (default: DD/MM/YYYY HH:mm:ss)
   */
  convert(strDateTime, toFormat = '', fromFormat = 'DD/MM/YYYY HH:mm:ss') {
    return this.parseDateTime(strDateTime, fromFormat).format(toFormat);
  }

  /**
   * takes a date timem string and converts to a Day, Date Month or Today, Date Month
   * @param strDateTime format DD/MM/YYYY or DD/MM/YYYY H:mm:ssss
   * @param showDateAndMonth boolean, whether to show Date Month aspect
   */
  getDayAndDateAndMonthFromDateString(strDateTime: string, showDateAndMonth: boolean) {
    const mo = this.parseDateTime(strDateTime);
    const dayString = this.getDayFromDateString(strDateTime);
    return showDateAndMonth ? `${dayString}, ${mo.format('Do MMMM')}` : dayString;
  }

  /**
   * COnvert a datetime string into the time only with am or pm suffix
   * @param strDateTime format DD/MM/YYYY or DD/MM/YYYY H:mm:ssss
   */
  timeWithAmPm(strDateTime: string) {
    return this.parseDateTime(strDateTime).format(DATETIME_FMT.TIME12HR);
  }

  /**
   * Utility function which parses the datestring into a moment- Moment can handle a string missing time part
   * @param strDateTime format DD/MM/YYYY or DD/MM/YYYY H:mm:ssss
   * @param format format string default DD/MM/YYYY HH:mm:ss
   */
  private parseDateTime(strDateTime, format = 'DD/MM/YYYY HH:mm:ss') {
    return moment(strDateTime, format);
  }
}

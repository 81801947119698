import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { DATETIME_FORMAT } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ClinicianType } from '@pushdr/common/types';

export interface Availability {
  AppointmentSlots: {
    Id: string;
    Price: number;
    OrderDetails: { AppointmentPrice: number; Discount: string };
    PartnerId: string;
    ClinicianType: ClinicianType;
  }[];
  StartUtc: Moment;
}

export enum PartnerAvailabilityState {
  OK,
  INVITE_REQUIRED,
}

export enum AccessQuestionnaireState {
  OK,
  ACCESS_QUESTIONNAIRE_REQUIRED,
}

export interface ApiAvailabilityStateObj {
  PartnerAvailability: {
    State: PartnerAvailabilityState;
  };
  AccessQuestionnaire: {
    State: AccessQuestionnaireState;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AvailabilityApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/';
  }

  getAppointments(
    partnerId: string,
    startUTC: Moment,
    endUTC: Moment,
    clinicianType: ClinicianType
  ): Observable<Availability[]> {
    return this.get(
      'availability',
      {
        startUTC: moment.utc(startUTC.startOf('day')).format(DATETIME_FORMAT),
        endUTC: moment.utc(endUTC.endOf('day')).format(DATETIME_FORMAT),
        partnerId,
        clinicianType,
      },
      this.headerService.bearerTokenHeaders(),
      2
    ).pipe(map(res => res?.Availability));
  }

  getAvailabilityState(partnerId: string): Observable<ApiAvailabilityStateObj> {
    return this.get<any>(
      'availabilityState',
      {
        partnerId,
      },
      this.headerService.bearerTokenHeaders(),
      2
    );
  }
}

<div *ngIf="rules$ | async as rules; else loading">
  <form [formGroup]="form" (ngSubmit)="form.valid && submitForm(form)" class="c-form-part u-pt0@xs">
    <h3 class="c-form-part__title">Change password</h3>

    <div class="c-form-part__field-wrap">
      <pushdr-old-password-input [passwordLabelText]="'Current password'" [parent]="form">
      </pushdr-old-password-input>
    </div>

    <div class="c-form-part__field-wrap">
      <pushdr-password-input
        [rules]="rules"
        [passwordLabelText]="'New password'"
        [parent]="form"
        [errors]="errors">
      </pushdr-password-input>
    </div>

    <div class="c-form-part__field-wrap">
      <pushdr-confirm-password-input [passwordLabelText]="'Confirm password'" [parent]="form">
      </pushdr-confirm-password-input>
    </div>

    <pushdr-password-valid-alert
      [parent]="form"
      passwordValidText="Great! Your passwords match and are fully secure and you're good to go!">
    </pushdr-password-valid-alert>

    <button
      class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin"
      type="submit"
      [disabled]="!form.valid">
      Save
    </button>
  </form>
</div>

<!--  Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { catchError, finalize, map, merge, of, Subject, switchMap, tap } from 'rxjs';

import { SpinnerLoaderModule } from '@pushdr/common/components';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';
import { GPPractice } from '@pushdr/common/types';
import { GeneralPracticeService } from '@pushdr/patientapp/common/data-access/patient-api';
import { SelectSurgeryItemComponent } from '../select-surgery-item/select-surgery-item.component';
import { FindSurgeryHelpDirective } from './find-surgery-help.directive';

@Component({
  selector: 'pushdr-select-surgery',
  templateUrl: './select-surgery.component.html',
  styleUrls: ['./select-surgery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SpinnerLoaderModule,
    AnalyticsDirectivesModule,
    SelectSurgeryItemComponent,
    FindSurgeryHelpDirective,
  ],
})
export class SelectSurgeryComponent {
  @Output() pdrSelected = new EventEmitter<GPPractice>();

  private searchTerm = new Subject<string>();

  protected loading = false;
  protected surgeries$ = merge(
    // Clears up the list once a new search has been triggered
    this.searchTerm.pipe(map(() => null)),
    this.searchTerm.pipe(switchMap(term => this.searchSurgeryByTerm(term)))
  );

  constructor(private modal: ModalService, private gpApi: GeneralPracticeService) {}

  searchSurgery(searchText: string): void {
    if (searchText?.trim()) {
      this.searchTerm.next(searchText);
    }
  }

  private searchSurgeryByTerm(searchTerm: string) {
    this.loading = true;
    return this.gpApi.searchSurgery(searchTerm).pipe(
      tap({ error: err => this.modal.error(err.message, 'search-error') }),
      finalize(() => (this.loading = false)),
      catchError(() => of([]))
    );
  }
}

import { NgModule } from '@angular/core';
import { IsoDatePipe } from './iso-date/iso-date.pipe';
import { ToIsoDatePipe } from './to-iso-date/to-iso-date.pipe';
import { SafeHtmlPipe } from './safeHtml-pipe/safe-html.pipe';
import { ToTitleCasePipe } from './toTitleCase/to-title-case.pipe';
import { FullTextSearchPipe } from './full-text-search/full-text-search.pipe';
import { TrackByPropertyPipe } from './track-by-property.pipe';
import { FormatNHSNumberPipe } from './formatNhsNumber/format-nhs-number.pipe';
import { OrderByPipe } from './orderBy/order-by.pipe';
import { BritishDatePipe } from './british-date/british-date.pipe';
import { EnumKeysPipe } from './enum-keys-pipe/enum-keys.pipe';
import { NumberToWordsPipe } from './number-to-words/number-to-words.pipe';
import { OrdinalPipe } from './ordinal/ordinal.pipe';

const pipes = [
  IsoDatePipe,
  ToIsoDatePipe,
  OrderByPipe,
  SafeHtmlPipe,
  ToTitleCasePipe,
  FullTextSearchPipe,
  TrackByPropertyPipe,
  FormatNHSNumberPipe,
  BritishDatePipe,
  EnumKeysPipe,
  NumberToWordsPipe,
  OrdinalPipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class CommonPipesModule {}

import { Injectable } from '@angular/core';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { HomeAddress } from '@pushdr/common/types';
import { catchError, map } from 'rxjs/operators';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, EMPTY, zip } from 'rxjs';
import { EncryptedUserService } from '@pushdr/patientapp/common/utils';
import { SimpleModalService } from 'ngx-simple-modal';
import { RequestDetailsPageErrorModalComponent } from './request-details-page-error-modal/request-details-page-error-modal.component';

export enum RequestDetailsTypes {
  ELECTRONIC = 'electronic',
  POST = 'post',
}
type RequestDetailsObject = HomeAddress | string;

interface RequestDetailsData {
  address: RequestDetailsObject | null;
  email: RequestDetailsObject | null;
  type: RequestDetailsTypes;
}

interface ResolveData extends RequestDetailsData {
  custId: string;
}

@Injectable({
  providedIn: 'root',
})
export class RequestDetailsPageResolver implements Resolve<Observable<ResolveData>> {
  constructor(
    private router: Router,
    private api: ApiPatientService,
    private encryptedUserService: EncryptedUserService,
    private simpleModal: SimpleModalService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const customerId$ = this.getCustomerId();
    let customerDetails$: Observable<RequestDetailsData>;
    let resolve$: Observable<ResolveData>;

    switch (route.queryParams.type) {
      case RequestDetailsTypes.ELECTRONIC:
        customerDetails$ = this.resolveElectronicRequest();
        break;
      case RequestDetailsTypes.POST:
        customerDetails$ = this.resolvePostRequest();
        break;
      default:
        resolve$ = this.returnToMedicalPage();
        return resolve$;
    }

    resolve$ = zip(customerDetails$, customerId$).pipe(
      map(([customerDetails, custId]) => {
        return {
          type: customerDetails.type,
          email: customerDetails.email,
          address: customerDetails.address,
          custId: custId,
        };
      }),
      catchError(error => this.returnToMedicalPage())
    );

    return resolve$;
  }

  private getCustomerId() {
    return this.encryptedUserService.userId$;
  }

  private getAddressOrEmail(type: RequestDetailsTypes): Observable<RequestDetailsObject> {
    if (type === RequestDetailsTypes.POST) {
      return this.api.account.getMyAddress();
    } else {
      return this.api.account.getDetails().pipe(map(details => details.EmailAddress));
    }
  }

  private resolveElectronicRequest() {
    return this.getAddressOrEmail(RequestDetailsTypes.ELECTRONIC).pipe(
      map(data => {
        return { type: RequestDetailsTypes.ELECTRONIC, email: data, address: null };
      })
    );
  }

  private resolvePostRequest() {
    return this.getAddressOrEmail(RequestDetailsTypes.POST).pipe(
      map(data => {
        return { type: RequestDetailsTypes.POST, email: null, address: data };
      })
    );
  }

  private returnToMedicalPage() {
    this.simpleModal.addModal(RequestDetailsPageErrorModalComponent, null, {
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
    this.router.navigate(['account/medical']);
    return EMPTY;
  }
}

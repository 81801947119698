import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pushdr-appointment-menu-shell',
  templateUrl: './appointment-menu-shell.component.html',
})
export class AppointmentMenuShellComponent implements OnInit {
  ngOnInit() {
    console.log('Appointment Menu Shell Initialised...');
  }
}

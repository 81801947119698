import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileHandlerComponent } from './file-handler.component';

@NgModule({
  declarations: [FileHandlerComponent],
  imports: [CommonModule],
  exports: [FileHandlerComponent],
})
export class FileHandlerModule {}

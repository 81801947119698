import { Injectable } from '@angular/core';
import { AppIntegrationService, TokenService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  AccountProfileService,
  resolveTheCustomersAccountProfile,
} from '@pushdr/patientapp/common/utils';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  constructor(
    private envProxy: EnvironmentProxyService,
    private accountProfile: AccountProfileService,
    private token: TokenService,
    private isAppService: AppIntegrationService
  ) {}

  getAppConfig() {
    return Promise.all([
      this.redirectToSigninIfUserLacksAuth(this.token, this.isAppService, this.envProxy),
      resolveTheCustomersAccountProfile(this.accountProfile),
    ]);
  }

  redirectToSigninIfUserLacksAuth(
    token: TokenService,
    isAppService: AppIntegrationService,
    envProxy: EnvironmentProxyService
  ) {
    const hasInvite = location.search.indexOf('invitationId=') > -1;
    if (hasInvite || (!isAppService.app && !token.exists() && !envProxy.environment.testingMode)) {
      const prefix = location.search || '?';
      window.location.href =
        envProxy.environment.patient.funnel.signin +
        prefix +
        '&returnUrl=' +
        encodeURIComponent(location.href);
    }
    return Promise.resolve();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchPostcodeModule } from '@pushdr/common/components';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { HomeAddressManualComponent } from './home-address-manual/home-address-manual.component';
import { HomeAddressSearchComponent } from './home-address-search/home-address-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    SearchPostcodeModule,
    ReactiveFormsModule,
    AnalyticsDirectivesModule,
  ],
  exports: [HomeAddressSearchComponent, HomeAddressManualComponent],
  declarations: [HomeAddressSearchComponent, HomeAddressManualComponent],
})
export class HomeAddressModule {}

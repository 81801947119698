import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedHeaderModule } from './header/shared-header.module';
import { SharedFooterModule } from './footer/shared-footer.module';
import { SharedEditFormModule } from './edit-forms/shared-edit-form.module';
import { CommonComponentsModule, SharedLogoModule } from '@pushdr/common/components';
import { HomeAddressModule } from './home-address/home-address.module';
import { AccountContentWrapperModule } from './account-content-wrapper/account-content-wrapper.module';
import { BigGreenTickComponent } from './big-green-tick/big-green-tick.component';
import { PasswordInputModule } from './password-inputs/password-inputs.module';
import { SignInModalComponent } from './sign-in-modal/sign-in-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { SystemSupportModule } from './system-support/system-support.module';
import { FindSurgeryHelpModalComponent } from './find-surgery-help-modal/find-surgery-help-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CommonComponentsModule,
    SharedLogoModule,
    SharedHeaderModule,
    SharedFooterModule,
    SharedEditFormModule,
    AccountContentWrapperModule,
    FormsModule,
    ReactiveFormsModule,
    HomeAddressModule,
    A11yModule,
    SystemSupportModule,
    PasswordInputModule,
  ],
  exports: [
    CommonComponentsModule,
    SharedHeaderModule,
    SharedFooterModule,
    SharedEditFormModule,
    AccountContentWrapperModule,
    BigGreenTickComponent,
    HomeAddressModule,
  ],
  declarations: [BigGreenTickComponent, SignInModalComponent, FindSurgeryHelpModalComponent],
})
export class PatientappCommonComponentsModule {}

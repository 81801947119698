import { Injectable } from '@angular/core';
import { SystemCheckService } from '@pushdr/common/utils';
import { Observable, throwError } from 'rxjs';
import { BookingApiService } from '@pushdr/patientapp/common/data-access/patient-api';

@Injectable({
  providedIn: 'root',
})
export class CanConsultService {
  constructor(private systemCheck: SystemCheckService, private api: BookingApiService) {}

  canConsult(orderId: number): Observable<boolean> {
    if (!orderId || orderId == 0) return throwError(new Error('Invalid OrderId'));
    if (this.systemCheck.isCompatible && this.systemCheck.isBrowserCompatible) {
      return this.api.canConsult(orderId);
    } else {
      const err = new Error("You're using incompatible browser");
      return throwError(err);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FeedbackReminderService } from '@pushdr/feedback/reminder';

@Component({
  selector: 'pushdr-app-shell',
  template: ` <router-outlet></router-outlet> `,
})
export class AppShellComponent implements OnInit {
  constructor(private reminder: FeedbackReminderService) {}

  ngOnInit(): void {
    this.reminder.remind().subscribe({
      // Silently logs failing API requests
      error: err => console.error(err),
    });
  }
}

import { SurgeryType } from '../common';

export interface GetGPResult {
  GetGPResult: GP;
}

export enum GPPartnershipType {
  NOT_A_PARTNER = 0,
  NHS_PARTNER = 1,
}

export enum GPShareStatus {
  DO_SHARE = 1,
  DO_NOT_SHARE = 2,
  NO_NHS_GP = 3,
  TBC = 4,
  VERIFYING = 5,
}

export interface GP {
  Address: {
    Line1: string;
    Line2: string;
    Line3: string;
    Line4: string;
    Line5: string;
    Postcode: string;
  };
  Id: number;
  Location: {
    __type: string;
    Latitude: number;
    Longitude: number;
  };
  Name: string;
  OdsCode: string;
  PartnershipType: GPPartnershipType;
  Telephone: string;
  ShareStatus: GPShareStatus;
}

export const GPFactory = (
  ShareStatus: GPShareStatus = GPShareStatus.DO_NOT_SHARE,
  PartnershipType: GPPartnershipType = GPPartnershipType.NOT_A_PARTNER
) => {
  return {
    ShareStatus,
    Id: 7834,
    OdsCode: 'P82613',
    Name: 'Spring View Medical Centre',
    Telephone: '01204 578128',
    Address: {
      Line1: 'Spring View Medical Ctr.',
      Line2: 'Mytham Road',
      Line3: 'Little Lever',
      Line4: 'Bolton',
      Line5: null,
      Postcode: 'BL3 1HQ',
    },
    Location: { __type: 'WGS 1984', Latitude: 53.560836, Longitude: -2.369742 },
    PartnershipType,
  } as GP;
};

export interface FindGPPracticesResult {
  FindGPPracticesResult: GPPractice[];
}

export interface GPPractice {
  Address: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  AddressLine4: string;
  AddressLine5: string;
  County: string;
  CustomerID: number;
  DistanceDescription: string;
  DistanceValue: number;
  GPEmail: number;
  Id: number;
  Latitude: number;
  Longitude: number;
  Name: string;
  OdsCode: string;
  PartnershipType: GPPartnershipType;
  PostCode: string;
  PostTown: string;
  SurgeryTypeId: SurgeryType;
}

export interface ManualGPInput {
  strAddressLine1: string;
  strAddressLine2: string;
  strAddressLine3: string;
  strTown: string;
  strCounty: string;
  strPostCode: string;
}

export interface ManualGPSearch {
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  Town: string;
  County: string;
  PostCode: string;
}

export const ManualGPInputFactory = (): ManualGPInput => {
  return {
    strAddressLine1: '',
    strAddressLine2: '',
    strAddressLine3: '',
    strTown: '',
    strCounty: '',
    strPostCode: '',
  };
};

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { validAdultAge, validChildAge, validDob } from './dob-validator.directive';

@Component({
  selector: 'pushdr-input-dob',
  templateUrl: './input-dob.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDobComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputDobComponent),
      multi: true,
    },
  ],
})
export class InputDobComponent implements ControlValueAccessor, OnInit {
  @Input() isForChild = false;

  onTouched: () => void;

  dateForm = this._fb.group({
    yyyy: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    mm: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
    dd: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
  });

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit() {
    const validatorsArr = [validDob];
    this.isForChild ? validatorsArr.push(validChildAge) : validatorsArr.push(validAdultAge);
    this.dateForm.setValidators(validatorsArr);
  }

  writeValue(val: any) {
    if (val) {
      this.dateForm.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: (val: any) => void) {
    this.dateForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.dateForm.disable() : this.dateForm.enable();
  }

  validate(c: UntypedFormControl) {
    return this.dateForm.valid ? null : this.dateForm.errors;
  }

  focusOnNextInputOnInput(event) {
    if (
      event.srcElement &&
      event.srcElement.nextElementSibling &&
      event.target.value.length === event.target.maxLength
    ) {
      this.focusOnNextInput(event);
    }
  }

  focusOnPreviousInput(event) {
    if (event.srcElement && event.target.selectionStart === 0) {
      const previousElement = event.srcElement.previousElementSibling;
      const endPositionOfPreviousElement = previousElement.maxLength;
      event.preventDefault();
      previousElement.setSelectionRange(endPositionOfPreviousElement, endPositionOfPreviousElement);
      previousElement.focus();
    }
  }

  focusOnNextInput(event) {
    if (event.srcElement && event.target.selectionEnd === event.target.maxLength) {
      const nextElement = event.srcElement.nextElementSibling;
      const startPositionOfNextElement = 0;
      event.preventDefault();
      nextElement.setSelectionRange(startPositionOfNextElement, startPositionOfNextElement);
      nextElement.focus();
    }
  }

  deleteLastNumberOfPreviousInput(event) {
    if (event.srcElement && event.target.selectionStart === 0 && event.target.selectionEnd === 0) {
      event.srcElement.previousElementSibling.value =
        event.srcElement.previousElementSibling.value.slice(0, -1);
      this.focusOnPreviousInput(event);
    }
  }
}

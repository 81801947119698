import { toTitleCase } from './toTitleCase';

/** Build an address label from a given interface */
export function buildAddressLabel<T>(
  address: T,
  labelGroupProperties: Array<string[] | string>,
  delim = ', ',
  formatExceptionKeys: string[] = []
) {
  return labelGroupProperties
    .map(group => {
      const groupArray = Array.isArray(group) ? group : [group];
      return groupArray
        .filter(key => !!address[key] && address[key].length)
        .map(key => {
          if (formatExceptionKeys.includes(key)) {
            return address[key];
          } else {
            return toTitleCase(address[key]);
          }
        })
        .join(' ');
    })
    .filter(label => !!label)
    .join(delim);
}

<div class="c-modal c-modal--is-visible">
  <div class="c-modal__wrap c-modal__wrap--40">
    <h3 class="mb-8 text-center">Your feedback is really important to us</h3>
    <div class="mb-8 modal__body">
      <p class="mb-4">
        Please fill out this short survey and let us know how we did for the below consultation
      </p>
      <p class="mb-0 text-lg">
        Appointment Details:
        <span class="font-bold">{{ dateTime | date: 'EEEE, MMMM d, y, h:mm a' }}</span>
      </p>
    </div>
    <div class="modal__footer">
      <a
        class="c-btn c-btn--primary feedback-leave-btn"
        [href]="leaveFeedbackUrl"
        (click)="done(true)">
        Leave feedback
      </a>
      <button
        type="button"
        class="mb-2 w-full h-14 px-6 bg-transparent hover:bg-indigo-500 text-indigo-500 hover:text-white border border-indigo-500 hover:border-transparent rounded"
        (click)="done(true)">
        Maybe later
      </button>
      <button
        type="button"
        class="mb-0 w-full h-14 px-6 bg-transparent text-black border-none rounded hover:bg-indigo-500 hover:text-white hover:border-transparent"
        (click)="done(false)">
        No thanks
      </button>
    </div>
  </div>
</div>

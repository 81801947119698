import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-find-surgery-help-modal',
  templateUrl: './find-surgery-help-modal.component.html',
  styleUrls: ['./find-surgery-help-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindSurgeryHelpModalComponent extends AbstractModal<null> {
  constructor() {
    super();
  }
}

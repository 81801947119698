<ng-container *ngIf="appointmentSummary$ | async as appointments; else loading">
  <account-content-wrapper>
    <h3 class="u-mb24@xs" trackView="upcoming">Upcoming appointments</h3>
    <pushdr-appointment-upcoming
      [appointments]="appointments"
      (cancel)="showCancelScreen($event)"
      (startEvent)="startAppointment($event)"
      (forceRefresh)="forceRefresh($event)"
      (book)="bookAppointment($event)"></pushdr-appointment-upcoming>
  </account-content-wrapper>
</ng-container>

<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>

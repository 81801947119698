import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentProxyService } from '@pushdr/environment';
import { map, switchMap, take } from 'rxjs/operators';
import {
  GetGPResult,
  FindGPPracticesResult,
  GetDetailsResult,
  GetMyAddressResult,
  ManualGPInput,
  HasAllDetailsResult,
} from '@pushdr/common/types';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiAccountPatientService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  versioning = false;

  endpoint() {
    return this.proxy.environment.patient.api + '/account.svc/accountW/';
  }

  getIdentificationWarningText() {
    return this.post(
      'GetIdentificationWarningTextV2',
      {},
      this.headerService.authorisedHeadersLegacy
    );
  }

  uploadIdentityDocument(idDocumentBase64: string, reason: string) {
    const idDocument = idDocumentBase64.split(',')[1];
    const documentType = idDocumentBase64.split(',')[0].split(';')[0].split(':')[1];
    return this.post(
      'UploadIdentityDocument',
      {
        documentBase64: idDocument,
        documentType: documentType,
        reason: reason,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  getGP() {
    return this.post<GetGPResult>('GetGP', {}, this.headerService.authorisedHeadersLegacy).pipe(
      map(response => response.GetGPResult)
    );
  }

  findGPPractices(postcode: string) {
    return this.post<FindGPPracticesResult>(
      'FindGPPractices',
      {
        strPostCode: postcode,
      },
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(response => response.FindGPPracticesResult));
  }

  getPrescriptionSteps() {
    return this.post('GetPrescriptionSteps', {}, this.headerService.authorisedHeadersLegacy);
  }

  addPatientLogRange(Logs: any[]) {
    return this.post(
      'AddPatientLogRange',
      {
        Logs,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  addPatientLog(log) {
    return this.post('AddPatientLog', log, this.headerService.authorisedHeadersLegacy);
  }

  // requestNotification(intType: number) {
  //   return this.post(
  //     'RequestNotification',
  //     {
  //       intType,
  //     },
  //     this.headerService.authorisedHeadersLegacy
  //   );
  // }

  getDetails() {
    return this.post<GetDetailsResult>(
      'GetDetails',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.GetDetailsResult));
  }

  getMyAddress() {
    return this.post<GetMyAddressResult>(
      'GetMyAddress',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.GetMyAddressResult));
  }

  findAddress(strPostCode: string) {
    return this.post(
      'FindAddress',
      {
        strPostCode,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  medicalComplaint(strConsultation: string, strComplaint: string) {
    return this.post(
      'MedicalComplaint',
      {
        strConsultation,
        strComplaint,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  hasAllDetails() {
    return this.post<HasAllDetailsResult>(
      'HasAllDetails',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.HasAllDetailsResult));
  }

  /** @param strID selected address id from picker */

  selectAddress(strID) {
    return this.post(
      'SelectAddress',
      {
        strID: strID,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  addManualAddress(
    strAddressLine1: string,
    strAddressLine2: string,
    strAddressLine3: string,
    strTown: string,
    strCounty: string,
    strPostCode: string
  ) {
    return this.post(
      'AddManualAddress',
      {
        strAddressLine1,
        strAddressLine2,
        strAddressLine3,
        strTown,
        strCounty,
        strPostCode,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  updatePatient(firstName: string, lastName: string, dob: string, gender: number) {
    return this.post(
      'UpdatePatient',
      {
        firstName,
        lastName,
        dob,
        gender,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  /** @param strDOB date format dd-mm-yyyy */

  updateDOB(strDOB) {
    return this.post(
      'UpdateDOB',
      {
        strDOB,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  updateFirstName(strFirstName: string) {
    return this.post(
      'UpdateFirstName',
      {
        strFirstName,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  updateLastName(strLastName: string) {
    return this.post(
      'UpdateLastName',
      {
        strLastName,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  updateGender(intGender: number) {
    return this.post(
      'UpdateGender',
      {
        intGender,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  updateGPManualObject(intShareStatusID: number, manualAddress: ManualGPInput) {
    return this.post(
      'UpdateGPManual',
      {
        intShareStatusID,
        ...manualAddress,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  updateGP(strID: string = null, intShareStatusID: number = null, strGPName: string = null) {
    return this.post(
      'UpdateGP',
      {
        strID,
        intShareStatusID,
        strGPName,
      },
      this.headerService.authorisedHeadersLegacy
    ).pipe(take(1));
  }

  updateGPManual(
    intShareStatusID: number,
    strAddressLine1: string,
    strAddressLine2: string,
    strAddressLine3: string,
    strTown: string,
    strCounty: string,
    strPostCode: string
  ) {
    return this.post(
      'UpdateGPManual',
      {
        intShareStatusID,
        strAddressLine1,
        strAddressLine2,
        strAddressLine3,
        strTown,
        strCounty,
        strPostCode,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  updatePassword(strOldPassword: string, strNewPassword: string) {
    return this.post(
      'UpdatePassword',
      {
        strOldPassword,
        strNewPassword,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  validateActiveBooking() {
    return this.post<{
      ValidateEditSurgeryResult: {
        Status: boolean;
        responseMessage: string;
      };
    }>('ValidateEditSurgery', {}, this.headerService.authorisedHeadersLegacy).pipe(
      map(response => response.ValidateEditSurgeryResult),
      switchMap(response => {
        if (response.Status === false) {
          return throwError(() => new Error(response.responseMessage));
        }
        return of(response);
      })
    );
  }
}

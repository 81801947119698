import { Moment } from 'moment';
import { ClinicianType } from '../partner/partner';

export interface BookSpecificAppointment {
  decTotalCost: number;
  intMinutes: number;
  strDate: string;
  strDateString: string;
  strFormattedDateString: string;
  strOrderNumber: string;
}

export interface BookSpecificAppointmentResult {
  BookSpecificAppointmentResult: BookSpecificAppointment;
}

export interface GetBookingConfirmation {
  TotalCost: number;
  Minutes: number;
  Date: string;
  DateTimeUtc: Moment;
  DateString: string;
  FormattedDateString: string;
  OrderNumber: string;
}

export interface GetBookingConfirmationResult {
  GetBookingConfirmationResult: GetBookingConfirmation;
}

// example
// intID: 1;
// strDate: '05/07/2018';
// strDateTime: '05/07/2018 06:00:00';
// strEndDateTime: '05/07/2018 06:10:00';
// strFormattedDateString: 'Thursday 5th July at 6:00AM';
export interface GetAppointmentAvailabilityForDay {
  intID?: number;
  strDate: string;
  strDateTime: string;
  strEndDateTime: string;
  strFormattedDateString: string;

  strDay?: string;
  strAmPmStart?: string;
  strAmPmEnd?: string;
  partnerId?: string;
  price?: number;
  totalPrice?: number;
  discount?: string;
  clinicianType: ClinicianType;
  startAppointmentTime: string;
  startAppointmentDate: string;
}

export interface GetAppointmentAvailability {
  strDay?: string;
  strDate?: string;
  strEndDateTime: string;
  strFormattedDateString: string;
}
export class AppointmentAvailability {
  availability: any[];
  actionDate?: string;

  constructor(apiResponse: any) {
    this.availability = apiResponse.Availability.map(r => {
      const mapped: GetAppointmentAvailability = {
        strDay: r.DateTime,
        strDate: r.Date,
        strEndDateTime: r.EndDateTime,
        strFormattedDateString: r.FormattedDate,
      };
      return mapped;
    });
    this.actionDate = apiResponse.ActionDate;
  }

  get length() {
    return this.availability.length;
  }

  map(callbackFn) {
    return this.availability.map(callbackFn);
  }
}

/**
 "DiscountDetails" property is null if Private patient doesn't have any applied coupon
 "Price" is the amount of money that the patient will be charged if they confirm the payment.
 "CancellationDetails" should be null for NHS Patient.
 */
export interface IReserveAppointmentDetails {
  OrderId: number; // 100010862
  StartUtc: string; // "2021-07-28T21:40:00"
  EndUtc: string; // "2021-07-28T21:50:00"
  Price: number; // 3599
  DiscountDetails: {
    FullPrice: number; // 3999
    DiscountAmount: number; // 4000
    DiscountFormatted: string; // "10%"
  };
  CancellationDetails: {
    Deadline: number; // minutes before free appointment cancellation
  };
  PatientId?: string;
}

import { Component, Input } from '@angular/core';
import { FooterLike } from '../../pd-footer-interface';
import { SystemCheckService } from '@pushdr/common/utils';

@Component({
  selector: 'pushdr-nhs-footer-default',
  templateUrl: './nhs-footer-default.component.html',
  styleUrls: ['./nhs-footer-default.component.scss'],
})
export class NhsFooterDefaultComponent implements FooterLike {
  @Input() homeUrl;
  @Input() downloadLink;
  isAndroid: boolean = this.systemCheck.isAndroid;
  isIos: boolean = this.systemCheck.isiOS;

  constructor(private systemCheck: SystemCheckService) {}
}

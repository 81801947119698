import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { private_registration } from '@pushdr/common/utils';
import { RestErrorParserService } from '@pushdr/common/data-access/rest-http-core';
import { ModalService } from '@pushdr/common/overlay';
import { GP, GPShareStatus, PDServerError } from '@pushdr/common/types';
import {
  AppIntegrationService,
  BrowserDetectorService,
  SystemCheckService,
  TokenService,
} from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { FOOTER_TYPE, SignInModalComponent } from '@pushdr/patientapp/common/components';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { AccountProfileService, SignOutService } from '@pushdr/patientapp/common/utils';
import { SimpleModalService } from 'ngx-simple-modal';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'pushdr-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isIE10 = this.browserDetectorService.isIE10();

  @HostBinding('attr.pdr-version')
  get pdrVersion(): string {
    return this.envProxy.environment.version;
  }

  constructor(
    private patientApi: ApiPatientService,
    private nhsApi: ApiNHSPatientService,
    private errors: RestErrorParserService,
    private profile: AccountProfileService,
    private token: TokenService,
    private simpleModal: SimpleModalService,
    private router: Router,
    private analytics: AnalyticsService,
    private modal: ModalService,
    private appIntegration: AppIntegrationService,
    private signoutService: SignOutService,
    private systemCheck: SystemCheckService,
    private envProxy: EnvironmentProxyService,
    private browserDetectorService: BrowserDetectorService
  ) {}

  ngOnInit() {
    this.checkForMaintenanceFeatureFlag();
    this.systemCheck.initialise();
    this.checkUserCompletedFunnel();
    this.appIntegration.initialise();
    this.subscribeToErrorStreams();
    this.analytics.initialise('patientapp-account');
    this.setUser();
    this.scrollToTopOnNavigationChange();
  }

  setUser() {
    this.profile.externalId$.subscribe((id: string) => {
      if (id) {
        this.analytics.setUser({ id });
      }
    });
  }

  get inAccount() {
    return this.router.url.startsWith('/account');
  }

  checkForMaintenanceFeatureFlag() {
    // Replaces 'maintenance' optimizely flag
    // TODO: Add proper maintenance flag toggling from API
    const maintenanceEnabled = false;
    if (maintenanceEnabled) {
      window.location.href = this.envProxy.environment.patient.funnel.maintenance;
    }
  }

  private subscribeToErrorStreams() {
    this.errors.errors$.subscribe((error: PDServerError) => {
      switch (error.status) {
        case 401:
        case 403:
          if (this.envProxy.environment.testingMode) {
            this.simpleModal
              .addModal(SignInModalComponent, {
                username: '',
                processLogin: this.processLogin.bind(this),
              })
              .subscribe(token => {
                this.token.set(token);
                location.reload();
              });
          } else {
            setTimeout(() => {
              this.modal
                .error(
                  'Your session seems to have expired. Please log in again and you can continue.'
                )
                .pipe(
                  take(1),
                  mergeMap(() => this.signoutService.signout(true))
                )
                .subscribe();
            }, 500);
          }
      }
    });
  }

  /*
    TODO this is a logic fail on NHS part
    if you get a token and go back to home page before you complete surgery pick OR id checcks
    you can still ented into the account area so we need to bounce back to the correct page
  */
  checkUserCompletedFunnel() {
    this.modal.showLoader({ bottomText: 'Loading your account' });
    // temp guard for NHS - all in one app
    const hasSurgery$ = this.patientApi.account.getGP();
    const consultationStatus$ = this.nhsApi.customer.consultationStatus();
    const gotoFunnel = (route = '') => {
      this.modal.showLoader({ bottomText: 'Please complete registration' });
      window.location.href = this.envProxy.environment.patient.siteURL + route;
    };

    forkJoin([hasSurgery$, consultationStatus$])
      .pipe(take(1))
      .subscribe(
        ([hasSurgery, consultationStatus]) => {
          if (!consultationStatus.ProfileComplete) {
            gotoFunnel('/patient/name');
          } else if (!this.didUserPickAValidSurgery(hasSurgery)) {
            gotoFunnel('/verification/surgery');
          }
        },
        err => {
          console.error(err);
        },
        () => {
          this.modal.close();
        }
      );
  }

  private didUserPickAValidSurgery(pickedSurgery: GP) {
    const hasGP = pickedSurgery.Id;
    const shareStatus = pickedSurgery.ShareStatus;
    // Replaces optimizely 'private_registration' flag
    // TODO: Add proper config for 'private_registration' flag
    const privateRegistrationConfig = private_registration;
    if (privateRegistrationConfig.enabled) {
      return hasGP;
    } else {
      return (
        (hasGP &&
          [GPShareStatus.DO_SHARE, GPShareStatus.DO_NOT_SHARE, GPShareStatus.TBC].includes(
            shareStatus
          )) ||
        [GPShareStatus.DO_NOT_SHARE, GPShareStatus.TBC, GPShareStatus.NO_NHS_GP].includes(
          shareStatus
        )
      );
    }
  }

  getFooterType() {
    return this.profile.isNHS ? FOOTER_TYPE.NHS_DEFAULT : FOOTER_TYPE.PDR_DEFAULT;
  }

  processLogin(username, password): Observable<string> {
    return this.patientApi.general.loginCheck(username, password).pipe(
      take(1),
      mergeMap(encryptedUser => this.patientApi.general.getCustomerToken(encryptedUser))
    );
  }

  scrollToTopOnNavigationChange() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import {
  EncryptedUser,
  ResetPasswordRequest,
  ResetResult,
  NamedEncryptedUser,
} from '@pushdr/common/types';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/authentication';
  }

  login(Username: string, Password: string) {
    return this.post<EncryptedUser>('/login', { Username, Password });
  }

  /** @deprecated No longer used, kept only for API capability. */
  loginWith(provider: 'Facebook' | 'Google', Token: string) {
    return this.post<NamedEncryptedUser>(`/Login/${provider}`, { Token });
  }

  resetPassword(request: ResetPasswordRequest) {
    return this.post<ResetResult>('/resetpassword', request);
  }

  forgotPassword(Username: string, Mobile: string) {
    return this.post<ResetResult>('/forgotpassword', {
      Username,
      Mobile,
    });
  }

  sendVerificationCode() {
    const user = this.headerService.user;
    user.Mobile = user.Mobile.replace(/\+44/g, '0');
    return this.post<any>('/sendverificationcode', user, this.headerService.encryptedUserHeaders());
  }

  verification(code: string) {
    const user = this.headerService.user;
    return this.post<string>(
      '/verification',
      {
        EncryptedUser: user,
        VerificationCode: code,
      },
      this.headerService.encryptedUserHeaders()
    );
  }

  generateToken() {
    const user = this.headerService.user;
    return this.post<string>('/generatetoken', user, this.headerService.encryptedUserHeaders());
  }

  invalidateToken(token = '') {
    return this.post<string>('/logout', {}, this.headerService.bearerTokenHeaders(token));
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputWithIconModule } from '@pushdr/common/components';
import { PasswordInputComponent } from './new-password-input/new-password-input.component';
import { OldPasswordInputComponent } from './old-password-input/old-password-input.component';
import { ConfirmPasswordInputComponent } from './confirm-password-input/confirm-password-input.component';
import { PasswordValidAlertComponent } from './password-valid-alert/password-valid-alert.component';
import { PasswordInputsService } from './password-inputs.service';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputWithIconModule],
  exports: [
    PasswordInputComponent,
    OldPasswordInputComponent,
    ConfirmPasswordInputComponent,
    PasswordValidAlertComponent,
  ],
  declarations: [
    PasswordInputComponent,
    OldPasswordInputComponent,
    ConfirmPasswordInputComponent,
    PasswordValidAlertComponent,
  ],
  providers: [PasswordInputsService],
})
export class PasswordInputModule {}

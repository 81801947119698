<ng-container *ngIf="details; else loading" [ngSwitch]="editMode">
  <account-content-wrapper>
    <h3 *ngIf="!editMode" class="u-mb24@xs">About me</h3>

    <div *ngIf="details?.HasPendingIdChange" class="c-jumbotron">
      <h5>
        Pending identity update, we need to confirm these changes before you can consult. This may
        take up to 12 hours.
      </h5>
    </div>

    <div></div>

    <div *ngSwitchCase="'mobile'">
      <pushdr-edit-phone-form
        [phoneNumber]="details?.Mobile"
        (save)="updateMobile($event)"></pushdr-edit-phone-form>
    </div>

    <div *ngSwitchCase="'password'">
      <pushdr-edit-password-form (saved)="updatedPassword($event)"></pushdr-edit-password-form>
    </div>

    <div *ngSwitchCase="'details'">
      <pushdr-common-edit-details-form
        [firstName]="details?.FirstName"
        [lastName]="details?.LastName"
        [dob]="details?.DOB"
        [gender]="details.Gender"
        (save)="updateDetails($event)"></pushdr-common-edit-details-form>
    </div>

    <div *ngSwitchCase="'address'">
      <section class="c-form-part" trackView="address">
        <h1 class="c-form-part__title" pdFocus="true">Edit address</h1>
        <p>Please ensure this address matches the one your GP surgery has on record.</p>
        <p>
          <strong>Your current address is</strong>:<br />
          <span
            class="capitalize"
            [innerHTML]="addressString(address, { delimiterBreak: true })"></span>
        </p>

        <ng-container *ngIf="!showManual">
          <pushdr-home-address-search (addressSelected)="setAddress($event)" [buttonText]="'Save'">
          </pushdr-home-address-search>
          <p class="c-form-part__bold-text">
            Can't find your new address?
            <button class="c-btn-anchor" data-cy="manual-reveal" (click)="toggleManualForm()">
              Enter home address manually
            </button>
          </p>
          <p>
            <button class="c-btn c-btn--secondary" (click)="goBackToAboutMe()">
              Back to About me
            </button>
          </p>
        </ng-container>

        <ng-container *ngIf="showManual">
          <pushdr-home-address-manual
            (manualAddressSubmitted)="addManualAddress($event)"
            [buttonText]="'Save'">
          </pushdr-home-address-manual>
          <p class="c-form-part__bold-text">
            <button class="c-btn-anchor" (click)="toggleManualForm()">
              Search for your postcode and select from a list
            </button>
          </p>
        </ng-container>
      </section>
    </div>

    <!-- Otherwise use the rows we defined in the component to populate the row-->

    <ng-container *ngSwitchDefault>
      <ul class="c-listview" trackView="about">
        <ng-container *ngIf="nhsSignInFeature">
          <li
            class="c-listview__item c-listview__item--editable c-listview__item--editable-newpage">
            <a href="https://settings.login.nhs.uk/" target="_blank">
              <h2 class="c-listview__heading">NHS login</h2>
              <p>Manage your NHS login</p>
            </a>
          </li>
        </ng-container>
        <li
          [class.hidden]="!row.label"
          class="c-listview__item"
          [class.c-listview__item--editable]="row.queryParamName"
          *ngFor="let row of rows"
          [routerLink]="[]"
          [queryParams]="row.queryParamName ? { edit: row.queryParamName } : {}">
          <h2 class="c-listview__heading">{{ row.label }}</h2>
          <p>{{ row.value }}</p>
        </li>
      </ul>
    </ng-container>
  </account-content-wrapper>
</ng-container>

<!--  Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemSupportComponent } from './system-support.component';

@NgModule({
  declarations: [SystemSupportComponent],
  exports: [SystemSupportComponent],
  imports: [CommonModule],
})
export class SystemSupportModule {}

import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomerAddress } from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-search-postcode',
  templateUrl: './search-postcode.component.html',
})
export class SearchPostcodeComponent {
  submitted = false;
  pattern =
    /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))(\s*)?$/;
  addressForm = this._fb.group({
    postcode: ['', [Validators.pattern(this.pattern), Validators.required]],
  });
  @Output() search = new EventEmitter<CustomerAddress[]>();

  constructor(
    private api: ApiNHSPatientService,
    private modal: ModalService,
    private _fb: UntypedFormBuilder
  ) {}

  get postCode() {
    return this.addressForm.get('postcode');
  }

  searchPostcode(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.modal.showLoader();
    const pc = this.postCode.value.trim();
    this.api.address.search(pc).subscribe(
      response => {
        this.search.emit(response);
        this.modal.close();
      },
      error => {
        const { message } = this.api.errorMessages.addresses.getAddress(error);
        this.modal.error(message);
      }
    );
  }

  handleSearchClick(event) {
    this.submitted = true;
    if (this.addressForm.get('postcode').valid) {
      this.searchPostcode(event);
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  CustomerTokenResult,
  FacebookAuthenticationResult,
  GoogleAuthenticationResult,
  NhsAuthenticationResult,
} from '@pushdr/common/types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiGeneralPatientService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.api + '/general.svc/generalW/';
  }

  getCustomerToken(encryptedUser): Observable<string> {
    return this.post<CustomerTokenResult>('GetCustomerToken', { encryptedUser }).pipe(
      map(res => res.GetCustomerTokenResult)
    );
  }

  /** @deprecated No longer used, kept only for API capability. */
  facebookAuthentication(token: string) {
    return this.post<FacebookAuthenticationResult>('FacebookAuthentication', { token }).pipe(
      map(res => res.FacebookAuthenticationResult)
    );
  }

  /** @deprecated No longer used, kept only for API capability. */
  googleAuthentication(token: string) {
    return this.post<GoogleAuthenticationResult>('GoogleAuthentication', { token }).pipe(
      map(res => res.GoogleAuthenticationResult)
    );
  }

  nhsAuthentication(token: string) {
    return this.post<NhsAuthenticationResult>(
      'NhsAuthentication',
      { token },
      this.headerService.emptyHeaders()
    ).pipe(map(res => res.NhsAuthenticationResult));
  }

  getCoreServicePrices() {
    return this.post('GetCoreServicePrices').pipe(map(res => res.GetCoreServicePricesResult));
  }

  loginCheck(email: string, password: string) {
    return this.post('LoginCheck', {
      strEmail: email,
      strPassword: password,
    }).pipe(map(res => res.LoginCheckResult));
  }

  getServicePrice(serviceId: number) {
    const body = { intServiceID: serviceId };
    return this.post('GetServicePrice', body, this.headerService.authorisedHeadersLegacy).pipe(
      map(res => res.GetServicePriceResult)
    );
  }

  cancellationMessage() {
    return this.post('CancellationMessage', {}, this.headerService.authorisedHeadersLegacy);
  }

  availabilityNotify(email: string) {
    const body = { strEmail: email };
    return this.post('AvailabilityNotify', body);
  }
}

import { ErrorMessageAdaptor } from './utils';
import { PDServerError } from '@pushdr/common/types';
import { ErrorMessageCore } from './core.errors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccountErrorsService extends ErrorMessageCore {
  createUpdateErrors(error: PDServerError): PDServerError {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(
          error,
          'Something has gone wrong creating your account. ' + error.message
        );
      default:
        return this.commonErrors(error);
    }
  }
  getAccountErrors(error: PDServerError) {
    switch (error.status) {
      case 404:
        return ErrorMessageAdaptor(error, "We couldn't find your account");
      default:
        return this.commonErrors(error);
    }
  }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AppointmentSummaryDetail,
  BookingApiService,
} from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import { Observable } from 'rxjs';
import { map, mergeMap, take, tap, switchMap } from 'rxjs/operators';
import {
  AnalyticsService,
  cancelOptions,
  GTMEventTrackingService,
} from '@pushdr/common/data-access/analytics';
import * as moment from 'moment';
import { CancelAppointmentModalComponent } from '../../components/cancel-appointment-modal/cancel-appointment-modal.component';

export enum CancellationType {
  NHS,
  CHARGE,
  NO_CHARGE,
}

@Component({
  selector: 'pushdr-appointment-cancel',
  templateUrl: './appointment-cancel.component.html',
})
export class AppointmentCancelComponent implements OnInit {
  CancellationType = CancellationType;

  details$: Observable<AppointmentSummaryDetail>;
  appointmentId = '';
  cancellationType: CancellationType;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: BookingApiService,
    private modal: ModalService,
    private gtm: GTMEventTrackingService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.details$ = this.route.params.pipe(
      mergeMap(params =>
        this.api
          .getBookedAppointmentDetail(params['id'])
          .pipe(
            tap(
              appointmentDetail =>
                (this.cancellationType = this.getCancellationMessage(appointmentDetail))
            )
          )
      )
    );
  }

  back() {
    this.location.back();
  }

  submit() {
    this.showLoader();
    this.route.params
      .pipe(
        map(params => params['id']),
        tap(appointmentId => this.gtm.trackRefund(appointmentId)),
        mergeMap(appointmentId =>
          this.cancellationType === CancellationType.NHS
            ? this.api.cancel(appointmentId)
            : this.api.cancelMarketplaceOrder(appointmentId)
        ),
        switchMap(() =>
          this.modal.showCustom(CancelAppointmentModalComponent, { cancelOptions: cancelOptions })
        ),
        take(1)
      )
      .subscribe(
        cancellationReason => {
          this.logCancellationReason(cancellationReason);
          this.modal.acknowledge(
            'Thanks, your appointment has been successfully cancelled',
            'Telling us the reason will help improve our service.'
          );
          this.back();
        },
        error => {
          this.modal.error(`
          Please contact our patient experience team at support@pushdoctor.co.uk, who will be happy to help you with this issue.
          `);
        }
      );
  }

  private getCancellationMessage(appointmentDetail: AppointmentSummaryDetail): CancellationType {
    const cancellationDeadline = moment(appointmentDetail.StartUtc).subtract(
      appointmentDetail.CancellationDeadline,
      'minutes'
    );
    const isBeforeCancellatiionDeadline = moment().isBefore(cancellationDeadline);
    switch (true) {
      case appointmentDetail.Price === 0 ||
        (appointmentDetail.Price > 0 && isBeforeCancellatiionDeadline):
        return CancellationType.NO_CHARGE;
      case appointmentDetail.Price > 0:
        return CancellationType.CHARGE;
      default:
        return CancellationType.NHS;
    }
  }

  private logCancellationReason(cancellationReason: number) {
    const reason = cancelOptions.find(element => {
      return element.id === cancellationReason;
    });
    this.analytics.trackEvent({ action: 'cancel_appointment', properties: reason });
  }

  private showLoader() {
    this.modal.showLoader({
      header: 'Cancelling consultation',
      bottomText: 'Please be patient while we update your account',
    });
  }
}

import { Injectable } from '@angular/core';
import {
  AcceptanceApiService,
  AccountApiService,
  AddressApiService,
  AuthenticationApiService,
  BookingApiService,
  CustomerApiService,
  GeneralPracticeService,
  InvitationsApiService,
  ValidationApiService,
  AvailabilityApiService,
  PartnerApiService,
  ActionRequestApiService,
} from './endpoints';
import { LinkedAccountsApiService } from './endpoints/api.linked-accounts.service';
import { HttpErrorMessageService } from './errors/factory-facade.errors';

@Injectable({
  providedIn: 'root',
})
export class ApiNHSPatientService {
  constructor(
    public authentication: AuthenticationApiService,
    public acceptance: AcceptanceApiService,
    public account: AccountApiService,
    public actionRequest: ActionRequestApiService,
    public address: AddressApiService,
    public availabiliity: AvailabilityApiService,
    public customer: CustomerApiService,
    public validation: ValidationApiService,
    public invitation: InvitationsApiService,
    public linkedAccounts: LinkedAccountsApiService,
    public gppractices: GeneralPracticeService,
    public booking: BookingApiService,
    public partner: PartnerApiService,
    public errorMessages: HttpErrorMessageService
  ) {}
}

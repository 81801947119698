import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CancelReasonSelectableOptions } from '@pushdr/common/data-access/analytics';
import { AbstractModal } from '@pushdr/common/overlay';

export interface CancelAppointmentInputModel {
  cancelOptions: CancelReasonSelectableOptions[];
}

@Component({
  selector: 'pushdr-cancel-appointment-modal',
  templateUrl: './cancel-appointment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelAppointmentModalComponent
  extends AbstractModal<{ cancelOptions: CancelReasonSelectableOptions[] }>
  implements OnInit
{
  cancelOptions: CancelReasonSelectableOptions[];
  result: number;

  constructor() {
    super();
  }

  ngOnInit() {
    this.cancelOptions = this.data.cancelOptions;
  }

  confirmCancellation(selected: number[]) {
    this.result = selected[0];
    this.close();
  }
}

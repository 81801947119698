<ng-container *ngIf="!isApp">
  <ng-container [ngSwitch]="type">
    <pushdr-pdr-footer-default
      *ngSwitchCase="'pdr_default'"
      [homeUrl]="homeUrl"
      [downloadLink]="downloadLink"></pushdr-pdr-footer-default>
    <pushdr-nhs-footer-default
      *ngSwitchCase="'nhs_default'"
      [homeUrl]="homeUrl"
      [downloadLink]="downloadLink"></pushdr-nhs-footer-default>
    <pushdr-footer-funnel
      *ngSwitchCase="'common_funnel'"
      [homeUrl]="homeUrl"
      [downloadLink]="downloadLink"></pushdr-footer-funnel>
    <div *ngSwitchDefault></div>
  </ng-container>
</ng-container>

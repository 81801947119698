import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeLogoComponent } from './home-logo/home-logo.component';

@NgModule({
  imports: [CommonModule],
  declarations: [HomeLogoComponent],
  exports: [HomeLogoComponent],
})
export class SharedLogoModule {}

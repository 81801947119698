import { Environment } from '../models';

// prettier-ignore
export const environment: Environment = {
  id: 'stub',
  version: '',
  testingMode: false,
  webHooksAPI: '',
  download: '',
  homepage: '',
  patient: {
    api: '',
    questionnaireAPI: '',
    nhsAPI: '',
    funnel: {
      signin: 'sign-in',
      register: '',
      forgotpass: '',
      updateterms: '',
      maintenance: '',
    },
    waitingRoom: {
      domainv3: '',
      signalRv3: '',
    },
    surgery: {
      domainv3: '',
      signalRv3: '',
      url: '',
      postSurgery: ''
    },
    account: {
      url: '',
      preAppointment: '',
      appointments: '',
      medicalComplaint: '',
      paymentDetails: '',
    },
    booking: {
      url: '',
    },
    troubleshooting: {
      windows: {
        cameraIssuesUrl: '',
        microphoneIssuesUrl: '',
        soundIssuesUrl: '',
        connectionIssuesUrl: ''
      },
      mac: {
        cameraIssuesUrl: '',
        microphoneIssuesUrl: '',
        soundIssuesUrl: '',
        connectionIssuesUrl: ''
      },
      generic: {
        cameraIssuesUrl: '',
        microphoneIssuesUrl: '',
        soundIssuesUrl: '',
        connectionIssuesUrl: ''
      },
    },
    siteURL: '',
    surgeryURL: '',
    pdPrivateWebsiteDownloadPage: '',
    pdPrivateWebsiteBookingPage: '',
  },
  production: false,
  appInsights: {
    config: {
      instrumentationKey: 'MOCK_KEY'
    }
  },
  google: {
    gtm: '',
  },
  sentry: {
    environment: 'test'
  },
  nhsAuth: {
    nhsRootDomain: '',
    clientId: '',
    redirectUri: ''
  },
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  zendesk: {
    key: '',
  },
};

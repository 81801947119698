<label for="search" class="mb-2">Surgery name or postcode</label>

<form class="flex border rounded mb-4" (ngSubmit)="searchSurgery(search.value)">
  <input #search="ngModel" id="search" name="search" class="rounded w-0 grow px-4 m-0" ngModel />
  <button type="submit" class="c-btn c-btn--primary rounded-l-none w-auto -m-px">Search</button>
</form>

<p class="mb-4">
  <button
    class="mb-4 text-primary cursor-pointer hover:underline"
    type="button"
    pushdrFindSurgeryHelp
    trackClick="I can't find my GP surgery">
    I can't find my GP surgery
  </button>
</p>

<div class="grow overflow-auto">
  <pushdr-spinner-loader *ngIf="loading" [minheight]="0"></pushdr-spinner-loader>
  <ng-container *ngIf="surgeries$ | async as surgeries">
    <pushdr-select-surgery-item
      tabindex="0"
      *ngFor="let surgery of surgeries"
      [surgery]="surgery"
      (click)="pdrSelected.emit(surgery)"
      (keyup.enter)="pdrSelected.emit(surgery)">
    </pushdr-select-surgery-item>
    <p *ngIf="surgeries?.length === 0" class="m-0">
      Sorry, we can’t find any surgeries from those details. Please try again or click
      <strong>‘I can’t find my GP surgery’</strong> for more help.
    </p>
  </ng-container>
</div>

export interface InviteGPMeta {
  id: number;
  address: string;
  odscode: string;
}

export interface InviteMeta {
  name?: string;
  gp?: InviteGPMeta;
}

export enum InviteStatus {
  UNCLAIMED,
  CLAIMED,
  EXPIRED,
  SPENT,
}

export enum InviteType {
  TBD,
  BOOKING,
  REGISTRATION,
}

export enum PartnerType {
  None = 0,
  NHS = 1,
  CORPORATE = 2,
  PDR = 3,
}
export interface ActionRequest {
  id: string;
  code: string;
  partnerSetupActionRequest: {
    partnerId: string;
    partnerName: string;
    partnerType: PartnerType;
  };
}
export interface Invite {
  partnerId: string;
  promotionId: number;
  link?: string;
  code?: string;
  inviteStatus?: InviteStatus;
  type: InviteType;
  meta?: InviteMeta;
  partnerType?: PartnerType;
}
export interface MetaDataNHS {
  SurgeryAddress: string;
  SurgeryId: number;
  SurgeryName: string;
  SurgeryOdsCode: string;
}

export interface InviteResponse {
  PromotionId: number;
  PartnerId: string;
  Code?: string;
  Link?: string;
  InviteStatus?: InviteStatus;
  MetaData?: any;
  ApplicationType: number;
  PartnerType: number;
}

export type InviteTypeString = 'Booking' | 'Registration' | '';

export const InviteTypeEnumAsString = (inviteType: InviteType): InviteTypeString => {
  switch (inviteType) {
    case InviteType.BOOKING:
      return 'Booking';
    case InviteType.REGISTRATION:
      return 'Registration';
    default:
      return '';
  }
};

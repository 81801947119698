import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { HeaderService } from '../../pd-header.service';
import { HeaderLike } from '../pd-header-interface';
import { BookingService } from '@pushdr/patientapp/booking/common';

@Component({
  selector: 'pushdr-pdr-header-default',
  templateUrl: './pdr-header-default.component.html',
  styleUrls: ['./pdr-header-default.component.scss'],
})
export class PdrHeaderDefaultComponent implements HeaderLike {
  @Input() homeUrl = '';
  @Input() accountUrl = '';
  @Input() loggedIn = false;
  @Input() inAccount = false;
  @Output() signedOut = new EventEmitter();

  menuIsOpen$ = this.header.menuIsOpen$;
  isBookingEnabled$ = this.booking.isBookingEnabled();

  constructor(
    private envProxy: EnvironmentProxyService,
    private header: HeaderService,
    private booking: BookingService
  ) {}

  get bookingURL() {
    const bookingBaseUrl = this.envProxy.environment.patient.booking.url;
    return `${bookingBaseUrl}/emergency`;
  }

  toggleBurgerMenu() {
    this.header.toggleBurgerMenu();
  }
}

<div class="c-card">
  <h2 class="u-font-style-p u-font-weight-normal">You have no upcoming appointments.</h2>
  <p>To see a clinician, book an appointment below.</p>
  <p>If you are suffering from a medical emergency, please call 999 immediately.</p>
  <div class="u-text-center">
    <button
      trackClick="book an appointment"
      class="c-btn c-btn--primary u-mb0@xs"
      (click)="pdrBookAppointment.emit()">
      Book an appointment
    </button>
  </div>
</div>

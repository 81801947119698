import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PasswordInputsService } from '../password-inputs.service';

@Component({
  selector: 'pushdr-old-password-input',
  templateUrl: './old-password-input.component.html',
  styleUrls: ['./old-password-input.component.scss'],
})
export class OldPasswordInputComponent {
  @Input() parent: UntypedFormGroup;
  @Input() errors: string[] = [];
  @Input() passwordLabelText = 'Current Password';

  showHidePasswordText: 'Show' | 'Hide' = 'Show';

  @ViewChild('oldPasswordInput', { static: false }) oldPasswordInput: ElementRef;

  constructor(private passwordInputService: PasswordInputsService) {}

  showHidePassword(): void {
    this.passwordInputService.showHidePassword(this.oldPasswordInput);
    this.showHidePasswordText = this.showHidePasswordText === 'Show' ? 'Hide' : 'Show';
  }

  resetPasswordField() {
    this.passwordInputService.resetPasswordField(this.oldPasswordInput, this.parent, 'oldPassword');
  }
}

import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule, SearchPostcodeModule } from '@pushdr/common/components';
import {
  AnalyticsDirectivesModule,
  AnalyticsService,
  LOG_CONFIG,
} from '@pushdr/common/data-access/analytics';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import {
  ClipboardModule,
  CommonPipesModule,
  FocusOnElementModule,
  StorageService,
  TokenModule,
} from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { MockApiInterceptor } from './mocks/mock-api.interceptor';
import {
  PatientappCommonComponentsModule,
  SharedHeaderModule,
} from '@pushdr/patientapp/common/components';
import { PatientappCommonConfigModule } from '@pushdr/patientapp/common/config';
import { ApiAccountPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { AccountProfileModule, PatientAnalyticsService } from '@pushdr/patientapp/common/utils';
import { SimpleModalModule } from 'ngx-simple-modal';
import { environment } from '../environments/environment';
import { AcceptancesPageComponent } from './account/acceptances-page/acceptances-page.component';
import { MedicalPageComponent } from './account/medical-page/medical-page.component';
import { AccountProfilePageComponent } from './account/profile-page/account-profile-page.component';
import { RequestDetailsPageErrorModalComponent } from './account/request-details-page/request-details-page-error-modal/request-details-page-error-modal.component';
import { RequestDetailsPageComponent } from './account/request-details-page/request-details-page.component';
import { UploadIdPageComponent } from './account/upload-id-page/upload-id-page.component';
import { AccountAppRoutingModule } from './app-routing.module';
import { AppShellComponent } from './app-shell.component';
import { AppComponent } from './app.component';
import { AppConfigService } from './app.config.service';
import { AppointmentMenuShellComponent } from './appointments/appointments-menu-shell/appointment-menu-shell.component';
import { AppointmentCancelComponent } from './appointments/cancel-page/appointment-cancel.component';
import { AppointmentFuturePageComponent } from './appointments/future-page/appointment-future-page.component';
import { AppointmentHistoryPageComponent } from './appointments/history-page/appointment-history-page.component';
import { AccountNavListComponent } from './common/account-nav-list/account-nav-list.component';
import { SharedAccountComponentsModule } from './components';
import { IsNHSCustomerGuard } from './guards/is-nhs-customer';
import { AppointmentMedicalComplaintPageComponent } from './medical/medical-complaint-page/appointment-medical-complaint.component';
import { MocksComponent } from './mocks/mocks.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CommonModule } from '@angular/common';
import { FeedbackReminderModule } from '@pushdr/feedback/reminder';
import { SearchSurgeryModalComponent } from './account/medical-page/search-surgery-modal/search-surgery-modal.component';

export function loadConfig(appService: AppConfigService) {
  return () => appService.getAppConfig();
}

export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent,
    AppShellComponent,
    AccountProfilePageComponent,
    AccountNavListComponent,
    AppointmentMenuShellComponent,
    AppointmentFuturePageComponent,
    AppointmentHistoryPageComponent,
    AppointmentMedicalComplaintPageComponent,
    AppointmentCancelComponent,
    UploadIdPageComponent,
    AcceptancesPageComponent,
    MedicalPageComponent,
    RequestDetailsPageComponent,
    RequestDetailsPageErrorModalComponent,
    MocksComponent,
  ],
  imports: [
    CommonModule,
    CommonPipesModule,
    SharedHeaderModule,
    PatientappCommonConfigModule,
    PatientappCommonComponentsModule,
    AccountProfileModule,
    AnalyticsDirectivesModule,
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        closeOnEscape: false,
        closeOnClickOutside: false,
        wrapperDefaultClasses: 'c-modal',
        bodyClass: 'js-modal-open',
        wrapperClass: 'c-modal--is-visible',
        animationDuration: 300,
        autoFocus: true,
      }
    ),
    CommonOverlayModule,
    AccountAppRoutingModule,
    SharedAccountComponentsModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    HttpClientJsonpModule,
    LottieModule.forRoot({ player: playerFactory }),
    FormsModule,
    CommonComponentsModule,
    TokenModule.forRoot({ tokenName: 'patient_token' }),
    SearchPostcodeModule,
    ClipboardModule,
    FocusOnElementModule,
    FeedbackReminderModule,
    SearchSurgeryModalComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MockApiInterceptor, multi: true },
    {
      provide: AnalyticsService,
      useClass: PatientAnalyticsService,
    },
    {
      provide: LOG_CONFIG,
      useFactory: (accountApi: ApiAccountPatientService, storage: StorageService) => {
        return {
          applicationName: 'Patient Account',
          get: storage.getSessionStorage,
          set: storage.setSessionStorage,
          delete: storage.deleteSessionStorage,
          emitStored: logs => accountApi.addPatientLogRange(logs),
          emit: log => accountApi.addPatientLog(log),
        };
      },
      deps: [ApiAccountPatientService, StorageService],
    },
    {
      provide: EnvironmentProxyService,
      useValue: new EnvironmentProxyService().setup(environment),
    },
    IsNHSCustomerGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [AppConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { ErrorMessageAdaptor } from './utils';
import { PDServerError } from '@pushdr/common/types';
import { ErrorMessageCore } from './core.errors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressErrorsService extends ErrorMessageCore {
  getAddress(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(
          error,
          "Sorry we don't recognise this as a UK postcode, please enter your address manually"
        );
      case 404:
        return ErrorMessageAdaptor(error, 'No addresses found, try adding your address manually');
      default:
        return this.commonErrors(error);
    }
  }
}

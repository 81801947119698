import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface MarketplacePartner {
  Id: string;
  Name: string;
}

@Injectable({
  providedIn: 'root',
})
export class PartnerApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/partner';
  }

  getMarketplacePartners(): Observable<MarketplacePartner[]> {
    return this.get('/marketplace', {}, this.headerService.bearerTokenHeaders(), 1).pipe(
      map(res => res?.Partners)
    );
  }
}

<form [formGroup]="mobileForm" (submit)="(submitForm)" class="c-form-part u-pt0@xs" novalidate>
  <h3 class="c-form-part__title">Edit your mobile number</h3>

  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="
      mobileForm.get('MobileNumber').dirty &&
      mobileForm.get('MobileNumber').hasError('invalidNumber')
    ">
    <label class="c-form-part__field-label" for="ipt-tel"> Mobile number </label>
    <input
      data-cy="mobile-input"
      formControlName="MobileNumber"
      id="ipt-tel"
      [placeholder]="phoneNumber"
      name="phoneNumber"
      class="c-form-part__field sessioncamexclude"
      aria-required="true"
      type="tel" />
    <p
      class="c-form-part__error-message"
      data-cy="required-num-error"
      *ngIf="mobileForm.get('MobileNumber').hasError('required')">
      A UK mobile number is required
    </p>
    <p
      class="c-form-part__error-message"
      data-cy="valid-num-error"
      *ngIf="mobileForm.get('MobileNumber').hasError('invalidNumber')">
      Must be a valid UK mobile number
    </p>
  </div>
  <p>
    We need this incase we need to contact you in a medical emergency or about your appointment.
  </p>

  <button
    [disabled]="mobileForm.get('MobileNumber').hasError('invalidNumber')"
    class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin"
    type="button"
    data-cy="request-code"
    (click)="requestVerificationCode()">
    Request Verification Code
  </button>

  <div *ngIf="codeRequested">
    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="
        mobileForm.get('VerificationCode').dirty && mobileForm.get('VerificationCode').errors
      ">
      <label class="c-form-part__field-label" for="ipt-confirm"> Verification Code </label>
      <input
        data-cy="verification-input"
        id="ipt-confirm"
        name="VerificationCode"
        formControlName="VerificationCode"
        class="c-form-part__field sessioncamexclude"
        aria-required="true"
        type="tel" />
      <p
        class="c-form-part__error-message"
        data-cy="required-code-error"
        *ngIf="
          mobileForm.get('VerificationCode').dirty && mobileForm.get('VerificationCode').errors
        ">
        A four digit verification code is required
      </p>
    </div>
    <p>
      Please enter the 4 digit verification code we have sent to your number. If you haven't
      received one you can request another.
    </p>

    <button
      [disabled]="!mobileForm.valid"
      class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin"
      type="button"
      data-cy="update-number"
      (click)="submitForm()">
      Update mobile number
    </button>
  </div>
</form>

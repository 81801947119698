import { Component, Input } from '@angular/core';
import { AppIntegrationService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';

export const FOOTER_TYPE = {
  NONE: 'none',
  PDR_DEFAULT: 'pdr_default',
  NHS_DEFAULT: 'nhs_default',
  COMMON_FUNNEL: 'common_funnel',
};
@Component({
  selector: 'pushdr-footer',
  templateUrl: 'pd-footer.component.html',
})
export class FooterComponent {
  @Input() type = FOOTER_TYPE.PDR_DEFAULT;
  homeUrl = this.env.environment.homepage;
  downloadLink = this.env.environment.download;

  constructor(
    private appIntegration: AppIntegrationService,
    private env: EnvironmentProxyService
  ) {}

  get isApp() {
    return this.appIntegration.app;
  }
}

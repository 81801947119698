<ul class="flex-col c-header__meganav-list">
  <li *ngIf="shouldShowAppointments" class="c-header__meganav-list-item">
    <a [headerRouterLink]="['account', 'appointments']">Appointments</a>
    <ul>
      <li *ngIf="isNhsAccount" routerLinkActive="c-header__meganav-list-item--is-active">
        <a [headerRouterLink]="['account', 'appointments', 'upcoming']">Upcoming</a>
      </li>
      <li routerLinkActive="c-header__meganav-list-item--is-active">
        <a [headerRouterLink]="['account', 'appointments', 'history']">History</a>
      </li>
    </ul>
  </li>
  <li class="c-header__meganav-list-item">
    <a [headerRouterLink]="['account']">Account</a>
    <ul>
      <li routerLinkActive="c-header__meganav-list-item--is-active">
        <a [headerRouterLink]="['account', 'about']">About me</a>
      </li>
      <li routerLinkActive="c-header__meganav-list-item--is-active">
        <a [headerRouterLink]="['account', 'medical']">Medical</a>
      </li>
      <li routerLinkActive="c-header__meganav-list-item--is-active">
        <a [headerRouterLink]="['account', 'acceptances']">Preferences</a>
      </li>
    </ul>
  </li>
  <li class="c-header__meganav-list-item" routerLinkActive="c-header__meganav-list-item--is-active">
    <a [headerRouterLink]="['account', 'medicalcomplaint']">Register a complaint</a>
  </li>
</ul>

<p class="u-sr-only" tabindex="0" *ngIf="multi === false">
  You must select only 1 of the following answers to continue.
</p>
<p class="u-sr-only" tabindex="0" *ngIf="multi === true">
  You must select at least 1 of the following answers to continue.
</p>
<form
  class="c-form-part u-pt0@xs u-pb0@xs"
  [attr.aria-label]="customAriaLabel"
  tabindex="0"
  data-cy="selection-question"
  novalidate>
  <label
    class="c-option--triage"
    [class.selected]="selection.isSelected(option.id)"
    *ngFor="let option of options">
    {{ option.name }}
    <span class="triage-cb" [class.dont-show]="!selection.isMultipleSelection()">
      <input
        type="checkbox"
        name="{{ option.name }}"
        [checked]="selection.isSelected(option.id)"
        (click)="selectOption(option.id)"
        data-cy="selection-checkbox"
    /></span>
  </label>

  <button
    class="c-btn c-btn--primary c-btn--triage u-mb0@xs"
    type="button"
    [disabled]="selection.isEmpty()"
    (click)="selected.emit(selection.selected)"
    data-cy="primary-button"
    aria-live="polite">
    {{ buttonText }}
  </button>
</form>

import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export const validDob: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const yyyy = control.get('yyyy').value;
  const mm = control.get('mm').value;
  const dd = control.get('dd').value;
  const errors = [];

  if (parseInt(dd, 10) < 1 || parseInt(dd, 10) > 31) {
    errors.push({ invalidDay: true });
  }

  if (parseInt(mm, 10) < 1 || parseInt(mm, 10) > 12) {
    errors.push({ invalidMonth: true });
  }

  if (parseInt(yyyy, 10) < 1900) {
    errors.push({ invalidYear: true });
  }

  const validDate = moment(`${dd}-${mm}-${yyyy}`, 'DD-MM-YYYY').isValid();
  if (!validDate || isNaN(dd) || isNaN(mm) || isNaN(yyyy)) {
    errors.push({ invalidDate: true });
  }

  return errors.length ? Object.assign({}, ...errors) : null;
};

export const validAdultAge: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const yyyy = control.get('yyyy').value;
  const mm = control.get('mm').value;
  const dd = control.get('dd').value;

  const diff = moment().diff(moment(`${dd}-${mm}-${yyyy}`, 'DD-MM-YYYY'), 'years', true);
  if (diff < 18) {
    return { tooYoung: true };
  }
  return null;
};

export const validChildAge: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const yyyy = control.get('yyyy').value;
  const mm = control.get('mm').value;
  const dd = control.get('dd').value;

  const diff = moment().diff(moment(`${dd}-${mm}-${yyyy}`, 'DD-MM-YYYY'), 'years', true);
  if (diff < 2 || Math.floor(diff) > 17) {
    return { wrongAge: true };
  }
  return null;
};

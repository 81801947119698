import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { CustomerAddress, HomeAddress, PDDetails } from '@pushdr/common/types';
import { buildAddressLabel } from '@pushdr/common/utils';
import {
  HomeAddressManualComponentFormProps,
  validAddress,
} from '@pushdr/patientapp/common/components';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { mergeMap, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'pushdr-account-profile-page',
  templateUrl: './account-profile-page.component.html',
})
export class AccountProfilePageComponent implements OnInit, OnDestroy {
  refresh$: Subject<boolean> = new Subject();
  details: PDDetails;
  address: HomeAddress;
  pharmacyAddress = '';
  editMode = '';
  rows = [];
  searchingAddress = true;
  nhsSignInFeature = false;

  postCode = '';
  showManual = false;
  submitted = false;

  subscriptions: Subscription[] = [];
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.showManual = false;
  }

  constructor(
    private api: ApiPatientService,
    private nhsApi: ApiNHSPatientService,
    private route: ActivatedRoute,
    private router: Router,
    private modal: ModalService,
    private location: Location,
    private profile: AccountProfileService
  ) {}

  ngOnInit() {
    this.setupDetailsRefresher();
    this.checkIfEditing();

    this.nhsSignInFeature = this.profile.isNHS;
  }

  setupDetailsRefresher() {
    const details$ = this.api.account.getDetails();
    const address$ = this.api.account.getMyAddress();
    const dataToRefresh$ = combineLatest([details$, address$]);

    // get a fresh copy of the details per page visit once only
    this.sub = this.refresh$
      .pipe(
        startWith(true),
        mergeMap(update => dataToRefresh$)
      )
      .subscribe(res => {
        const [details, address] = res;
        this.details = details;
        this.address = address;
        this.rows = this.generateRows(details, address);
      });
  }

  checkIfEditing() {
    this.sub = this.route.queryParams.subscribe(params => {
      this.editMode = params['edit'];
    });
  }

  generateRows(details: PDDetails, address: HomeAddress) {
    return [
      {
        label: 'Email address',
        value: details.EmailAddress,
        queryParamName: '',
      },
      {
        label: 'Mobile number',
        value: details.Mobile,
        queryParamName: 'mobile',
      },
      {
        label: 'Password',
        value: '',
        queryParamName: 'password',
      },
      {
        label: 'Name',
        value: this.createFullName(details),
        queryParamName: 'details',
      },
      {
        label: this.profile.isNHS ? '' : 'Referred to as',
        value: details.Title,
        queryParamName: 'title',
      },
      {
        label: 'Date of birth',
        value: details.DOB,
        queryParamName: 'details',
      },
      {
        label: 'Gender',
        value: this.genderString(details.Gender),
        queryParamName: 'details',
      },
      {
        label: 'Home address',
        value: this.addressString(address),
        queryParamName: 'address',
      },
    ];
  }

  createFullName(details: PDDetails) {
    return `${details.FirstName} ${details.LastName}`;
  }

  genderString(genderInt: number) {
    switch (genderInt) {
      case 2:
        return 'Female';
      case 1:
        return 'Male';
      default:
        return 'Not specified';
    }
  }

  updateMobile({ MobileNumber, VerificationCode }) {
    this.modal.showLoader({ bottomText: 'Updating new number' });
    this.sendAndRefresh(this.nhsApi.account.updateMobile(MobileNumber, VerificationCode));
  }

  updatedPassword(saved: boolean) {
    this.location.back();
  }

  addressString(addr: HomeAddress, delimiterBreak = false) {
    return buildAddressLabel(
      addr,
      [
        ['AddressLine1', 'AddressLine2', 'AddressLine3', 'AddressLine4', 'AddressLine5'],
        'PostTown',
        'PostCode',
      ],
      delimiterBreak ? '<br />' : ' - ',
      ['PostCode']
    );
  }

  // Functions to handle the saving of form data we get back
  updateNames({ firstName, lastName, previousNames }) {
    this.modal.showLoader({ bottomText: 'Updating your names' });
    const updateFirstName$ = this.api.account.updateFirstName(firstName);
    const updateLastName$ = this.api.account.updateFirstName(lastName);
    // TODO add update to previous names here

    this.sendAndRefresh(combineLatest([updateFirstName$, updateLastName$]));
  }

  updateDetails({ firstName, lastName, dob, gender }) {
    this.modal.showLoader({ bottomText: 'Updating your details' });
    this.sendAndRefresh(this.api.account.updatePatient(firstName, lastName, dob, gender));
  }

  updateDOB({ dob }) {
    this.modal.showLoader({ bottomText: 'Updating your date of birth' });
    this.sendAndRefresh(this.api.account.updateDOB(dob));
  }

  updateAddress(strID: string) {
    this.modal.showLoader({ bottomText: 'Updating your address' });
    this.sendAndRefresh(this.api.account.selectAddress(strID));
    this.searchingAddress = false;
  }

  updateGender(intGender) {
    this.modal.showLoader({ bottomText: 'Updating your gender' });
    this.sendAndRefresh(this.api.account.updateGender(intGender));
  }

  sendAndRefresh<T>(apiCall: Observable<T>) {
    apiCall.pipe(take(1)).subscribe(
      res => {
        this.refresh$.next(true);
        this.modal.success('Updated');
        this.location.back();
      },
      error => {
        switch (error.status) {
          case 400:
            this.modal.error(error.message);
            break;
          case 409:
            this.modal.error(
              'The details that you have provided match an existing account. To ensure that duplicate health records are not created, this account has been blocked. A member of our customer experience team will be in touch to resolve this issue. Please log in with your existing account to continue.'
            );
            break;
          case 412:
            this.router.navigate(['account', 'uploadid']);
            this.modal.close();
            break;
          case 0:
            this.modal.error(error.message);
            break;
          default:
            console.error(error);
            this.modal.error("Oops - something didn't work");
        }
      }
    );
  }

  // helper to store any subscribes we make that need unsubbing
  private set sub(val: Subscription) {
    this.subscriptions.push(val);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  toggleManualForm() {
    this.showManual = !this.showManual;
    window.scrollTo(0, 0);
  }

  setAddress(event) {
    this.modal.showLoader({ bottomText: 'Updating address' });
    this.submitted = true;
    if (validAddress(event)) {
      this.address = event;
      this.sendAndRefresh(this.api.account.selectAddress(this.address.Id));
    } else {
      this.modal.error('Please enter a valid address');
    }
  }

  goBackToAboutMe() {
    this.location.back();
    this.showManual = false;
  }

  addManualAddress(manualAddressSubmitted: HomeAddressManualComponentFormProps) {
    if (this.address) {
      this.api.account
        .addManualAddress(
          manualAddressSubmitted.AddressLine1,
          manualAddressSubmitted.AddressLine2,
          manualAddressSubmitted.AddressLine3,
          manualAddressSubmitted.PostTown,
          manualAddressSubmitted.County,
          manualAddressSubmitted.PostCode
        )
        .subscribe(
          response => {
            this.updateAddress(response.AddManualAddressResult);
            this.showManual = false;
          },
          error => {
            this.modal.error(error.message);
          }
        );
    } else {
      // This is caught on the front end
      // this.modal.error('Please enter a valid address');
    }
  }
}

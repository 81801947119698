import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  private menuIsOpenSubject = new BehaviorSubject<boolean>(false);
  menuIsOpen$ = this.menuIsOpenSubject.asObservable();
  constructor(@Inject(DOCUMENT) private document: any) {}

  toggleBurgerMenu() {
    if (this.menuIsOpenSubject.value) {
      this.closeHeaderMenu();
    } else {
      this.openHeaderMenu();
    }
  }

  closeHeaderMenu() {
    this.document.body.classList.remove('c-header--meganav-is-visible');
    this.menuIsOpenSubject.next(false);
  }

  openHeaderMenu() {
    this.document.body.classList.add('c-header--meganav-is-visible');
    this.menuIsOpenSubject.next(true);
  }
}

import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[trimOnBlur]',
})
export class TrimOnBlurDirective {
  @HostListener('blur', ['$event.target', '$event.target.value'])
  onBlur(el: HTMLInputElement, value: string): void {
    if (value.trim() !== value) {
      el.value = value.trim();
      this.dispatchEvent(el, 'input');
    }
  }

  private dispatchEvent(el: HTMLInputElement, eventType: string) {
    const event = document.createEvent('Event');
    event.initEvent(eventType, false, false);
    el.dispatchEvent(event);
  }
}

@NgModule({
  declarations: [TrimOnBlurDirective],
  exports: [TrimOnBlurDirective],
})
export class TrimOnBlurModule {}

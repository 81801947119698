import { PDServerError } from '@pushdr/common/types';

export const ErrorMessageAdaptor = (
  error: PDServerError,
  message: string = '',
  header = 'Sorry'
): PDServerError => {
  error.message = message || error.message;
  error.header = header;
  return error;
};

import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of } from 'rxjs';

import { CustomerApiService } from '@pushdr/patientapp/common/data-access/patient-api';
import { PatientAppointmentsService } from '@pushdr/patientapp/common/services';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(
    private patientAppointmentsService: PatientAppointmentsService,
    private customer: CustomerApiService,
    private profile: AccountProfileService
  ) {}

  isBookingEnabled(): Observable<boolean> {
    return combineLatest([
      this.patientAppointmentsService.hasBookedAppointments$,
      this.customer.getPartner().pipe(map(partner => partner.IsMultipleAppointmentEnabled)),
    ]).pipe(
      map(
        ([hasAppointment, isMultipleAppointmentEnabled]) =>
          isMultipleAppointmentEnabled || hasAppointment === false
      ),
      // Always disable booking for private accounts
      map(isBookingEnabled => (this.profile.isPrivate ? false : isBookingEnabled))
    );
  }
}

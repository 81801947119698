<div class="o-row">
  <div
    [ngClass]="
      hasNav
        ? 'col-lg-offset-1 col-xs-12 col-md-10'
        : 'col-xs-12 col-md-8 col-md-offset-2 col-lg-4 col-lg-offset-4'
    ">
    <ng-content></ng-content>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  ApiNHSPatientService,
  LinkedAccountsApiService,
} from '@pushdr/patientapp/common/data-access/patient-api';
import { RestHttpHeaderService } from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { LinkedAccount } from '@pushdr/common/types';
import { tap } from 'rxjs/operators';

export enum AccountProfileType {
  NHS = 'nhs',
  PRIVATE = 'private',
}

//factory used in app_init
export function resolveTheCustomersAccountProfile(accountProfile: AccountProfileService) {
  return accountProfile.resolve();
}

/**
 * Reflects the customers accountProfile value
 * For v1.0 a user will only have one account profile or none which reflects isNHS or iPrivate.
 * It may well happen that a user can have multiple profiles they are capable of and the the
 * current mode of the user is decided by something else. Update this when that day comes.
 */
@Injectable({
  providedIn: 'root',
})
export class AccountProfileService {
  accountProfileTypes$: BehaviorSubject<AccountProfileType[]>;
  externalId$: ReplaySubject<string>;
  linkedAccounts$: BehaviorSubject<LinkedAccount[]>;

  constructor(
    private api: ApiNHSPatientService,
    private headerService: RestHttpHeaderService,
    private envProxy: EnvironmentProxyService,
    private analytics: AnalyticsBusService,
    private linkedAccsApi: LinkedAccountsApiService
  ) {
    this.accountProfileTypes$ = new BehaviorSubject<AccountProfileType[]>([]);
    this.externalId$ = new ReplaySubject<string>();
    this.linkedAccounts$ = new BehaviorSubject<LinkedAccount[]>([]);
  }

  get isNHS() {
    const res = this.accountProfileTypes$.value.includes(AccountProfileType.NHS);
    return res;
  }

  get isPrivate() {
    const res = !this.accountProfileTypes$.value.some(f =>
      [AccountProfileType.NHS].includes(f as AccountProfileType)
    );
    return res;
  }

  asString(): string {
    switch (true) {
      case this.isNHS:
        return AccountProfileType.NHS;
      case this.isPrivate:
        return AccountProfileType.PRIVATE;
    }
    return '';
  }

  resolve(): Promise<void> {
    //override from envuronement so we can ignore customer type if we want...
    if (this.envProxy.environment.profileOverride) {
      this.update(this.envProxy.environment.profileOverride);
      return Promise.resolve();
    }

    const httpHeaders = this.headerService.headers();
    if (!httpHeaders) return Promise.resolve();

    const promise = this.api.customer
      .getCustomer()
      .toPromise()
      .then(customer => {
        this.analytics.trackEvent(AnalyticsEvent.userType(this.isNHS ? 'NHS' : 'Private'));
        this.externalId$.next(customer.ExternalId);
        this.update(customer.AccountProfiles);
      })
      .catch(reason => {
        this.update(this.accountProfileTypes$.value);
      });

    return promise;
  }

  getLinkedAccounts() {
    if (this.linkedAccounts$.value.length) {
      return this.linkedAccounts$.asObservable();
    } else {
      return this.linkedAccsApi
        .getLinkedAccounts()
        .pipe(tap(res => this.linkedAccounts$.next(res)));
    }
  }

  update(profiles: string[]) {
    profiles = profiles || [];
    this.accountProfileTypes$.next(
      profiles.map(value => value.toLowerCase() as AccountProfileType)
    );
  }
}

<footer class="c-footer">
  <div class="o-wrapper o-container o-container--fluid">
    <div class="o-row o-row--no-margin-bottom">
      <div class="c-footer__row">
        <div class="c-footer__item c-footer__icon-and-apps">
          <div class="c-footer__push-doctor-icon">
            <div class="c-footer__logo">
              <a [href]="homeUrl" class="logo" title="Push Doctor Logo">
                <span class="u-sr-only">Push Doctor homepage</span>
              </a>
            </div>
          </div>
          <div class="c-footer__app-links">
            <a
              target="_blank"
              title="App Store"
              [href]="downloadLink"
              aria-label="Download the Push Doctor iOS App from the Apple App store">
              <img
                alt="Download the app on the Apple App Store"
                aria-hidden="true"
                class="c-footer__app-logo"
                src="./assets/images/apple_store.png" />
            </a>
            <a
              target="_blank"
              title="Play Store"
              aria-label="Download the Push Doctor Android App from the Google Play store"
              [href]="downloadLink">
              <img
                alt="Download the app on the Google Play Store"
                class="c-footer__app-logo"
                aria-hidden="true"
                src="./assets/images/google_play.png" />
            </a>
          </div>
        </div>
        <div class="c-footer__item c-footer__link-column">
          <h2 class="c-footer__heading">Discover</h2>
          <nav>
            <ul class="c-footer__links">
              <li>
                <a [href]="homeUrl + '/how-it-works'">How it works</a>
              </li>
              <li>
                <a [href]="homeUrl + '/what-we-treat'" title="What We Treat">What we treat</a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="c-footer__item c-footer__link-column">
          <h2 class="c-footer__heading">Learn more</h2>
          <nav>
            <ul class="c-footer__links">
              <li>
                <a [href]="homeUrl + '/contact'">Contact</a>
              </li>
              <li>
                <a [href]="homeUrl + '/privacy'">Privacy</a>
              </li>
              <li>
                <a [href]="homeUrl + '/sitemap'">Sitemap</a>
              </li>
              <li>
                <a [href]="homeUrl + '/terms'">Terms & conditions</a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="c-footer__item">
          <h2 class="c-footer__heading">Connect with us</h2>
          <div class="c-footer__social-icons">
            <a
              href="https://www.facebook.com/Pushdoctor"
              target="blank"
              class="c-footer__social-icon"
              title="Contact Push Doctor NHS on Facebook">
              <svg
                version="1.1"
                class="c-footer__social-icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 96.124 96.123"
                xml:space="preserve">
                <g>
                  <path
                    d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z" />
                </g>
              </svg>
            </a>
            <a
              href="https://twitter.com/pushdoctor"
              target="blank"
              class="c-footer__social-icon"
              title="Contact Push Doctor NHS on Twitter">
              <svg
                version="1.1"
                class="c-footer__social-icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 612 612"
                xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M612,116.258c-22.525,9.981-46.694,16.75-72.088,19.772c25.929-15.527,45.777-40.155,55.184-69.411c-24.322,14.379-51.169,24.82-79.775,30.48c-22.907-24.437-55.49-39.658-91.63-39.658c-69.334,0-125.551,56.217-125.551,125.513c0,9.828,1.109,19.427,3.251,28.606C197.065,206.32,104.556,156.337,42.641,80.386c-10.823,18.51-16.98,40.078-16.98,63.101c0,43.559,22.181,81.993,55.835,104.479c-20.575-0.688-39.926-6.348-56.867-15.756v1.568c0,60.806,43.291,111.554,100.693,123.104c-10.517,2.83-21.607,4.398-33.08,4.398c-8.107,0-15.947-0.803-23.634-2.333c15.985,49.907,62.336,86.199,117.253,87.194c-42.947,33.654-97.099,53.655-155.916,53.655c-10.134,0-20.116-0.612-29.944-1.721c55.567,35.681,121.536,56.485,192.438,56.485c230.948,0,357.188-191.291,357.188-357.188l-0.421-16.253C573.872,163.526,595.211,141.422,612,116.258z" />
                  </g>
                </g>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/pushdoctor"
              target="blank"
              class="c-footer__social-icon"
              title="Contact Push Doctor NHS on Instagram">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="c-footer__social-icon-svg"
                viewBox="0 0 24 24">
                <path
                  d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

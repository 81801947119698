<div class="c-card">
  <h2 class="mb-0 text-indigo-500 font-normal text-2xl leading-6">
    {{ appointment.StartUtc | date: 'hh:mma' | lowercase }}
  </h2>
  <p class="font-normal">{{ appointment.StartUtc | isoDate: 'dddd Do MMMM' }}</p>
  <hr class="text-bluegrey-400" />
  <div class="flex justify-between mt-5 font-light">
    <p class="m-0">Clinician type:</p>
    <p class="m-0">{{ appointment.ClinicianType | clinicianTypeName }}</p>
  </div>
  <ng-container [ngSwitch]="appointment.Status">
    <div class="u-text-center mt-7" *ngSwitchCase="AppointmentStatus['In Progress']">
      <pushdr-system-support>
        <button
          class="c-btn c-btn--primary u-mb0@xs"
          system-is-supported
          (click)="pdrStartAppointment.emit(appointment)">
          Start your appointment
        </button>
      </pushdr-system-support>
    </div>
    <div class="u-text-center mt-7" *ngSwitchCase="AppointmentStatus.Scheduled">
      <pushdr-system-support></pushdr-system-support>
      <button
        class="c-btn c-btn--primary c-btn--white-bg u-mb0@xs"
        (click)="pdrCancelAppointment.emit(appointment)">
        Cancel appointment
      </button>
    </div>
  </ng-container>
</div>

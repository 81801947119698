import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { PasswordStrengthConditions } from '@pushdr/common/types';
import { MappedPasswordConditions } from '../password-mapping/password-conditions-map';
import { PasswordInputsService } from '../password-inputs.service';

@Component({
  selector: 'pushdr-password-input',
  templateUrl: './new-password-input.component.html',
  styleUrls: ['./new-password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
  @Input() parent: UntypedFormGroup;
  @Input() rules: PasswordStrengthConditions;
  @Input() errors: string[] = [];
  @Input() passwordLabelText = 'Password';

  passwordValid = false;
  conditions: MappedPasswordConditions[] = [];
  showHidePasswordText: 'Show' | 'Hide' = 'Show';

  @ViewChild('passwordInput', { static: false }) passwordInput: ElementRef;

  constructor(private passwordInputService: PasswordInputsService) {}

  ngOnInit() {
    this.conditions = this.passwordInputService.mapConditions(this.rules);
    this.parent.get('password').setValidators(this.passwordValidator.bind(this));
    this.parent.updateValueAndValidity();
  }

  checkPassword(password: string): void {
    this.conditions.forEach(condition => {
      if (condition.conditionToMatch) condition.matched = condition.conditionToMatch.test(password);
      else condition.matched = false;

      if (condition.errorConditionToMatch)
        condition.errorMatched = condition.errorConditionToMatch.test(password);
      else condition.errorMatched = false;
    });

    const matchedConditions = this.conditions.filter(cond => cond.matched === true);
    const conditionsToMatch = this.conditions.filter(cond => cond.conditionToMatch);
    const allMatched = conditionsToMatch.length - matchedConditions.length === 0;
    const errorsMatched = this.conditions.filter(cond => cond.errorMatched);

    this.passwordValid = allMatched && errorsMatched.length === 0;

    this.parent.get('password').setErrors(this.passwordValid ? null : { notValid: true });
  }

  showHidePassword(): void {
    this.passwordInputService.showHidePassword(this.passwordInput);
    this.showHidePasswordText = this.showHidePasswordText === 'Show' ? 'Hide' : 'Show';
  }

  resetPasswordField() {
    this.passwordInputService.resetPasswordField(this.passwordInput, this.parent, 'password');
  }

  passwordValidator(control: AbstractControl) {
    return this.passwordValid ? null : { notValid: true };
  }
}

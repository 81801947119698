import { Directive, OnChanges, OnDestroy, HostBinding, Input, HostListener } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  QueryParamsHandling,
  NavigationEnd,
  Event,
  UrlTree,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { HeaderService } from './pd-header.service';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'a[headerRouterLink],area[headerRouterLink]' })
export class HeaderRouterLinkDirective implements OnChanges, OnDestroy {
  // TODO(issue/24571): remove '!'.
  @HostBinding('attr.target') @Input() target!: string;
  // TODO(issue/24571): remove '!'.
  @Input() queryParams!: { [k: string]: any };
  // TODO(issue/24571): remove '!'.
  @Input() fragment!: string;
  // TODO(issue/24571): remove '!'.
  @Input() queryParamsHandling!: QueryParamsHandling;
  // TODO(issue/24571): remove '!'.
  @Input() preserveFragment!: boolean;
  // TODO(issue/24571): remove '!'.
  @Input() skipLocationChange!: boolean;
  // TODO(issue/24571): remove '!'.
  @Input() replaceUrl!: boolean;
  @Input() state?: { [k: string]: any };
  private commands: any[] = [];
  private subscription: Subscription;
  // TODO(issue/24571): remove '!'.
  private preserve!: boolean;

  // the url displayed on the anchor element.
  // TODO(issue/24571): remove '!'.
  @HostBinding() href!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationStrategy: LocationStrategy,
    private header: HeaderService
  ) {
    this.subscription = router.events.subscribe((s: Event) => {
      if (s instanceof NavigationEnd) {
        this.updateTargetUrlAndHref();
      }
    });
  }

  @Input()
  set headerRouterLink(commands: any[] | string) {
    if (commands != null) {
      this.commands = Array.isArray(commands) ? commands : [commands];
    } else {
      this.commands = [];
    }
  }

  @Input()
  set preserveQueryParams(value: boolean) {
    // if (isDevMode() && <any>console && <any>console.warn) {
    //   console.warn('preserveQueryParams is deprecated, use queryParamsHandling instead.');
    // }
    this.preserve = value;
  }

  ngOnChanges(changes: Record<string, unknown>): any {
    this.updateTargetUrlAndHref();
  }
  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey'])
  onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
    if (button !== 0 || ctrlKey || metaKey || shiftKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }

    const extras = {
      skipLocationChange: attrBoolValue(this.skipLocationChange),
      replaceUrl: attrBoolValue(this.replaceUrl),
      state: this.state,
    };
    this.router.navigateByUrl(this.urlTree, extras);
    this.header.closeHeaderMenu();
    return false;
  }

  private updateTargetUrlAndHref(): void {
    this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.urlTree));
  }

  get urlTree(): UrlTree {
    return this.router.createUrlTree(this.commands, {
      relativeTo: this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
      queryParamsHandling: this.queryParamsHandling,
      preserveFragment: attrBoolValue(this.preserveFragment),
    });
  }
}

function attrBoolValue(s: any): boolean {
  return s === '' || !!s;
}

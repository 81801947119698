import { NgModule } from '@angular/core';
import { InputDobComponent } from './input-dob.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LeadingZerosModule } from './leading-zeros.directive';

@NgModule({
  imports: [ReactiveFormsModule, LeadingZerosModule],
  declarations: [InputDobComponent],
  exports: [InputDobComponent],
})
export class InputDobModule {}

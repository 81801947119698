import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'pushdr-request-details-page-error-modal',
  templateUrl: './request-details-page-error-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestDetailsPageErrorModalComponent extends SimpleModalComponent<null, null> {
  constructor() {
    super();
  }

  closeModal() {
    this.close();
  }
}

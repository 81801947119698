import { Injectable, OnDestroy, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { AnalyticsIntegrationConfig } from '../analytics-types';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';
import { cancelReasonsMap } from './cancel_appointment.types';
import { ExtendedWindow, WINDOW } from '@pushdr/common/utils';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements OnDestroy {
  private config: AnalyticsIntegrationConfig;
  private ngUnsubscribe$ = new Subject<void>();
  private renderer: Renderer2;

  constructor(
    private bus: AnalyticsBusService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initialise(config: AnalyticsIntegrationConfig) {
    if (!!config && !this.config) {
      this.config = config;
      this.addGAScriptToDocument();
      this.subscribeToEvents();
    } else {
      throw new Error('Google Analytics failed to initialise or was already initialised.');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private addGAScriptToDocument() {
    const s = this.renderer.createElement('script');
    s.type = 'text/javascript';
    s.text =
      "window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;ga('create', 'UA-42760371-7', 'auto');ga('send', 'pageview');";
    this.renderer.appendChild(this._document.head, s);

    const async = this.renderer.createElement('script');
    async.type = 'text/javascript';
    async.async = true;
    async.src = `https://www.google-analytics.com/analytics.js`;
    this.renderer.appendChild(this._document.head, async);
  }

  private subscribeToEvents() {
    this.bus.eventBus$
      .pipe(
        filter(event => this.config.events.indexOf(event.action) > -1),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(event => {
        if (event.action === 'cancel_appointment') {
          if (cancelReasonsMap[event.properties.name]) {
            this.window.ga(
              'send',
              'event',
              'cancel_appointment',
              cancelReasonsMap[event.properties.name]
            );
          } else {
            console.error(
              event.properties.name,
              'is not a known reason in the cancel reasons summary mapping'
            );
          }
        } else {
          this.window.ga('send', 'event', {
            eventCategory: event.category ?? 'Misc',
            eventAction: event.action,
            eventLabel: event.label,
            eventValue: event.value,
          });
        }
      });
  }
}

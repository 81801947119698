<div *ngIf="browserSupported && deviceSupported" data-cy="system-supported">
  <ng-content select="[system-is-supported]"></ng-content>
</div>

<div
  class="systemNotSupported"
  data-cy="system-unsupported"
  *ngIf="!browserSupported || !deviceSupported">
  <div>
    <ng-content select="[system-not-supported]"></ng-content>
  </div>
  <div>
    <div
      *ngIf="deviceSupported"
      data-cy="device-supported"
      class="systemNotSupported__recommendedAction">
      <p class="u-font-weight-normal">Unfortunately we don't support this browser.</p>
      <p class="u-font-weight-normal">We recommend using chrome on macOS or Windows</p>
      <p>
        <a
          href="https://www.google.co.uk/chrome/browser/features.html"
          target="_blank"
          class="u-text-primary">
          Download Chrome <i class="material-icons c-material-icons-inline">open_in_new</i>
        </a>
      </p>
    </div>

    <div
      *ngIf="!deviceSupported"
      data-cy="device-unsupported"
      class="systemNotSupported__recommendedAction">
      <h3 class="u-font-style-p u-font-weight-normal">
        To consult on this device you need to download the app.
      </h3>
    </div>
  </div>
</div>

<ng-container *ngIf="isAndroid || isIos">
  <a
    trackClick="download app"
    class="m-0 c-btn c-btn--primary"
    target="_blank"
    [href]="downloadLink">
    Download app
  </a>
</ng-container>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { CustomerAddress } from '@pushdr/common/types';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AddressApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/Address';
  }

  search(postcode: any) {
    return this.get<CustomerAddress[]>('', { postcode }, this.headerService.headers()).pipe(
      map(addresses => {
        return addresses.map(address => {
          return address;
        });
      })
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedLogoModule } from '@pushdr/common/components';
import { HeaderFunnelComponent } from './header-types/common-header-funnel/common-header-funnel.component';
import { PdrHeaderDefaultComponent } from './header-types/pdr-header-default/pdr-header-default.component';
import { HeaderComponent } from './pd-header.component';
import { HeaderRouterLinkDirective } from './header-router-link.directive';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { AccountProfileModule } from '@pushdr/patientapp/common/utils';

@NgModule({
  imports: [CommonModule, AnalyticsDirectivesModule, SharedLogoModule, AccountProfileModule],
  declarations: [
    HeaderComponent,
    HeaderFunnelComponent,
    PdrHeaderDefaultComponent,
    HeaderRouterLinkDirective,
  ],
  exports: [HeaderComponent, HeaderRouterLinkDirective],
})
export class SharedHeaderModule {}

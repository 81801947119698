import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

export function nameValidator(c: UntypedFormControl) {
  if (c.value) {
    const val = c.value;
    const invalidNameRegex = /.*[;:,*()&$£!<>=+].*/;
    const isInvalid = invalidNameRegex.test(val) || val.replace(/\s/g, '').length < 1;
    if (isInvalid) {
      return {
        invalidName: true,
      };
    }
  }
  return null;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[pdrValidName][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: nameValidator,
      multi: true,
    },
  ],
})
export class InputNameValidatorDirective {}

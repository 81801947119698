import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { ConfirmManualGpComponent } from './confirm-manual-gp/confirm-manual-gp.component';
import { ContentRequiredValidatorDirective } from './directives/content-required-validator/content-required-validator.directive';
import { InputMaxNumberDirective } from './directives/input-max-number/input-max-number.directive';
import { InputNameValidatorDirective } from './directives/input-name-validator/input-name-validator.directive';
import { EditAddressFormComponent } from './edit-address/edit-address-form.component';
import { EditGpshareFormComponent } from './edit-gpshare-form/edit-gpshare-form.component';
import { EditSearchAddressFormComponent } from './edit-search-address/edit-search-address-form.component';
import { EditSurgeryService } from './edit-surgery';
import { EditSurgeryAddressManualFormComponent } from './edit-surgery-manual-address/edit-surgery-manual-address.component';
import { EditSurgeryComponent } from './edit-surgery/edit-surgery.component';
import { SurgeryListviewComponent } from './surgery-listview/surgery-listview.component';
import { InputWithIconModule } from '@pushdr/common/components';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { FocusOnElementModule, TrimOnBlurModule } from '@pushdr/common/utils';
import { AccountProfileModule } from '@pushdr/patientapp/common/utils';
import { EditGPShareSkipSharingModalComponent } from './edit-gpshare-form/edit-gpshare-skip-sharing-modal/edit-gpshare-skip-sharing-modal.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [
    CommonModule,
    AnalyticsDirectivesModule,
    FormsModule,
    SpinnerLoaderModule,
    AccountProfileModule,
    InputWithIconModule,
    FocusOnElementModule,
    TrimOnBlurModule,
    A11yModule,
  ],
  declarations: [
    EditGpshareFormComponent,
    EditSurgeryAddressManualFormComponent,
    EditAddressFormComponent,
    EditSearchAddressFormComponent,
    EditSurgeryComponent,
    SurgeryListviewComponent,
    ConfirmManualGpComponent,
    InputMaxNumberDirective,
    InputNameValidatorDirective,
    ContentRequiredValidatorDirective,
    EditGPShareSkipSharingModalComponent,
  ],
  providers: [EditSurgeryService],
  exports: [
    EditGpshareFormComponent,
    EditAddressFormComponent,
    EditSearchAddressFormComponent,
    EditSurgeryAddressManualFormComponent,
    EditSurgeryComponent,
    SurgeryListviewComponent,
    ConfirmManualGpComponent,
    InputMaxNumberDirective,
    InputNameValidatorDirective,
    ContentRequiredValidatorDirective,
    EditGPShareSkipSharingModalComponent,
  ],
})
export class SharedEditFormModule {}

import { Component, ViewChild, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PasswordInputsService } from '../password-inputs.service';

@Component({
  selector: 'pushdr-confirm-password-input',
  templateUrl: './confirm-password-input.component.html',
  styleUrls: ['./confirm-password-input.component.scss'],
})
export class ConfirmPasswordInputComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject<void>();

  @Input() parent: UntypedFormGroup;
  @Input() errors: string[] = [];
  @Input() passwordLabelText = 'Current Password';

  showHidePasswordText: 'Show' | 'Hide' = 'Show';
  newPassword = '';

  @ViewChild('confirmPasswordInput', { static: false }) confirmPasswordInput: ElementRef;

  constructor(private passwordInputService: PasswordInputsService) {}

  ngOnInit() {
    this.parent.get('confirmPassword').setValidators(this.confirmPasswordValidator.bind(this));
    this.parent.updateValueAndValidity();

    if (!this.parent.controls['password']) {
      return;
    } else {
      this.parent
        .get('password')
        .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(value => {
          this.newPassword = value;
          this.confirmPasswordValidator();
        });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  showHidePassword(): void {
    this.passwordInputService.showHidePassword(this.confirmPasswordInput);
    this.showHidePasswordText = this.showHidePasswordText === 'Show' ? 'Hide' : 'Show';
  }

  resetPasswordField() {
    this.passwordInputService.resetPasswordField(
      this.confirmPasswordInput,
      this.parent,
      'confirmPassword'
    );
  }

  confirmPasswordValidator() {
    this.parent
      .get('confirmPassword')
      .setErrors(
        this.parent.get('confirmPassword').value === this.newPassword ? null : { notMatched: true }
      );
    return this.parent.get('confirmPassword').value === this.newPassword
      ? null
      : { notMatched: true };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { Observable } from 'rxjs';
import { CustomerPreference } from '@pushdr/common/types';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreferenceApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/preference';
  }

  getPreferences(): Observable<CustomerPreference[]> {
    return this.get('', {}, this.headerService.bearerTokenHeaders()).pipe(pluck('PreferenceList'));
  }

  updatePreference(preference: CustomerPreference): Observable<CustomerPreference[]> {
    return this.put(
      `/${preference.Id}`,
      { preferenceValue: preference.Value },
      this.headerService.bearerTokenHeaders()
    );
  }
}

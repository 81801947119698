import { PDServerError } from '@pushdr/common/types';
import { ErrorMessageAdaptor } from './utils';
import { ErrorMessageCore } from './core.errors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomerErrorsService extends ErrorMessageCore {
  getCustomerAcceptancesErrors(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(error, 'Unknown error occured getting your patient');
      default:
        return this.commonCustomerErrors(error);
    }
  }

  postCustomerAcceptancesErrors(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(
          error,
          'Acceptances not correctly specified and/or mandatory flag not respected'
        );
      default:
        return this.commonCustomerErrors(error);
    }
  }

  uploadImageErrors(error: PDServerError) {
    switch (error.status) {
      case 415:
        return ErrorMessageAdaptor(
          error,
          'Accepted types are Jpeg, jpg, png and pdf',
          'Unsupported file type'
        );
      case 400:
        return ErrorMessageAdaptor(error, 'Uploading failed');
      default:
        return this.commonCustomerErrors(error);
    }
  }

  setSurgery(error: PDServerError) {
    switch (error.status) {
      case 204:
        return ErrorMessageAdaptor(error);
      case 400:
        return ErrorMessageAdaptor(error);
      default:
        return this.commonCustomerErrors(error);
    }
  }

  getLinksError(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(error, 'Failed to get your link');
      default:
        return this.commonCustomerErrors(error);
    }
  }

  updateCustomerErrors(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(
          error,
          'Something in your customer is not compatible with our server',
          'Invalid customer object'
        );
      case 409:
        return ErrorMessageAdaptor(error, '', 'Account Blocked');
      default:
        return this.commonCustomerErrors(error);
    }
  }

  commonCustomerErrors(error: PDServerError) {
    switch (error.status) {
      case 404:
        return ErrorMessageAdaptor(
          error,
          "Sorry it appears you are not really here, we couldn't find your customer details",
          'Customer details not found'
        );
      default:
        return this.commonErrors(error);
    }
  }
}

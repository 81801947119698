<form
  novalidate
  #f="ngForm"
  class="c-form-part u-pt0@xs"
  (ngSubmit)="f.valid && gender !== 3 && submit(f)">
  <h3 class="c-form-part__title">Edit details</h3>

  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="f.submitted && fname.invalid">
    <label class="c-form-part__label" for="firstName">First name</label>
    <input
      class="c-form-part__field sessioncamexclude"
      type="text"
      id="firstName"
      name="firstName"
      [(ngModel)]="firstName"
      required
      pdrValidName
      #fname="ngModel" />
    <p
      class="c-form-part__error-message"
      *ngIf="fname?.errors?.required || fname?.errors?.invalidName">
      A valid first name is required
    </p>
  </div>

  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="f.submitted && sname.invalid">
    <label class="c-form-part__label" for="lastName">Last name</label>
    <input
      id="lastName"
      class="c-form-part__field sessioncamexclude"
      type="text"
      name="lastName"
      [(ngModel)]="lastName"
      required
      pdrValidName
      #sname="ngModel" />
    <p
      class="c-form-part__error-message"
      *ngIf="sname?.errors?.required || sname?.errors?.invalidName">
      A valid last name is required
    </p>
  </div>

  <div
    class="c-form-part__margin-wrap"
    [class.c-form-part__entry--is-invalid]="(f.invalid || gender === 3) && f.submitted">
    <label class="c-form-part__label">Gender</label>
    <div class="c-form-part__dbl-btn-wrap">
      <button
        (click)="gender = 1"
        class="c-btn c-btn--primary c-btn--no-hover c-form-part__btn sessioncamexclude"
        type="button"
        [class.c-btn--primary]="gender === 1"
        value="Choose option">
        Male
      </button>
      <button
        (click)="gender = 2"
        class="c-btn c-btn--primary c-btn--no-hover c-form-part__btn sessioncamexclude"
        type="button"
        [class.c-btn--primary]="gender === 2"
        value="Choose option">
        Female
      </button>
    </div>
  </div>

  <div
    class="c-form-part__margin-wrap"
    [class.c-form-part__entry--is-invalid]="f.touched && dobModel.invalid">
    <pushdr-input-dob
      #dobModel="ngModel"
      name="dobComposite"
      [(ngModel)]="dobComposite"></pushdr-input-dob>
    <p class="c-form-part__error-message" *ngIf="dobModel.hasError('invalidDay')" role="alert">
      The field for day 'DD' is invalid
    </p>
    <p class="c-form-part__error-message" *ngIf="dobModel.hasError('invalidMonth')" role="alert">
      The field for month 'MM' is invalid
    </p>
    <p class="c-form-part__error-message" *ngIf="dobModel.hasError('invalidYear')" role="alert">
      The field for year 'YYYY' is invalid
    </p>
    <p class="c-form-part__error-message" *ngIf="dobModel.hasError('invalidDate')" role="alert">
      Please enter a valid date in the format 'dd/mm/yyyy'
    </p>
    <p class="c-form-part__error-message" *ngIf="dobModel.hasError('tooYoung')" role="alert">
      Account holders cannot be under 18
    </p>
  </div>

  <button
    class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin"
    type="submit"
    value="Submit">
    Save
  </button>
</form>

import { Injectable } from '@angular/core';
import { PricingApiService } from '@pushdr/patientapp/common/data-access/patient-api';
import { retryBackoff } from 'backoff-rxjs';
import { Observable, of } from 'rxjs';
import { catchError, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PatientPricingService {
  private _appointmentPrice$: Observable<number>;

  constructor(private pricingApi: PricingApiService) {}

  get appointmentPrice$(): Observable<number> {
    if (!this._appointmentPrice$) {
      this._appointmentPrice$ = this.pricingApi.appointmentPrice.pipe(
        retryBackoff({
          initialInterval: 1000,
          maxRetries: 3,
          shouldRetry: () => true,
        }),
        catchError(() => of(undefined)),
        publishReplay(1),
        refCount()
      );
    }
    return this._appointmentPrice$;
  }

  clearCache() {
    this._appointmentPrice$ = null;
  }
}

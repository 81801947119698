import { Component, OnInit } from '@angular/core';
import { HomeAddress, PDServerError } from '@pushdr/common/types';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestDetailsTypes } from './request-details-page-resolver';
import { StorageService, buildAddressLabel } from '@pushdr/common/utils';
import { ModalService } from '@pushdr/common/overlay';
import { CustomerApiService } from '@pushdr/patientapp/common/data-access/patient-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pushdr-request-details-page',
  styleUrls: ['./request-details-page.component.scss'],
  templateUrl: './request-details-page.component.html',
})
export class RequestDetailsPageComponent implements OnInit {
  address: HomeAddress | null = null;
  email: string | null = null;
  type: RequestDetailsTypes = RequestDetailsTypes.ELECTRONIC;
  showMore = false;
  sentRequest = false;
  custId = '';
  readonly STORE_KEY_PREFIX = 'record_sent_by_';

  constructor(
    private modal: ModalService,
    private route: ActivatedRoute,
    private store: StorageService,
    private customerApi: CustomerApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.type = this.route.snapshot.data['details'].type;
    this.address = this.route.snapshot.data['details'].address;
    this.email = this.route.snapshot.data['details'].email;
    this.custId = this.route.snapshot.data['details'].custId;
    this.sentRequest = this.store.get(this.STORE_KEY_PREFIX + this.custId);
  }

  addressString(addr: HomeAddress, delimiterBreak = false) {
    return buildAddressLabel(
      addr,
      [
        ['AddressLine1', 'AddressLine2', 'AddressLine3', 'AddressLine4', 'AddressLine5'],
        'PostTown',
        'PostCode',
      ],
      delimiterBreak ? '<br />' : ' - '
    );
  }

  requestPrintedRecords() {
    // TODO: support line breaks in address string
    this.modal
      .confirm('Confirm your home address', this.addressString(this.address), 'Confirm', 'Cancel')
      .pipe(take(1))
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.logSarsRequest();
        } else {
          this.router.navigate(['/account/about'], { queryParams: { edit: 'address' } });
        }
      });
  }

  requestElectronicRecords() {
    this.modal
      .confirm('Confirm your email address', this.email)
      ?.pipe(take(1))
      .subscribe(confirmed => {
        if (confirmed) {
          this.logSarsRequest();
        }
      });
  }

  logSarsRequest() {
    this.customerApi.logSarsRequest(this.type).subscribe({
      next: () => {
        this.recordsRequestSent();
      },
      error: (error: PDServerError) => {
        let errorMessage = '';
        switch (error.status) {
          case 409:
            errorMessage = error.message; // You already have a pending request
            break;
          default:
            errorMessage =
              'Could not process your request, please contact our customer experience team';
        }
        this.modal.error(errorMessage);
      },
    });
  }

  private recordsRequestSent() {
    this.sentRequest = true;
    this.store.set(this.STORE_KEY_PREFIX + this.custId, true, null, 30);
  }
}

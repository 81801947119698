import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackApiService } from './feedback-api.service';

@NgModule({
  imports: [CommonModule],
  providers: [FeedbackApiService],
})
export class FeedbackApiModule {}

import { Component, OnInit, Input } from '@angular/core';
import { FooterLike } from '../../pd-footer-interface';

@Component({
  selector: 'pushdr-footer-funnel',
  templateUrl: './common-footer-funnel.component.html',
  styleUrls: ['./common-footer-funnel.component.scss'],
})
export class CommonFooterFunnelComponent implements FooterLike {
  @Input() homeUrl;
  @Input() downloadLink;
}

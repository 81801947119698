import { Component } from '@angular/core';
import { filter } from 'rxjs';

import { AbstractModal, ModalService } from '@pushdr/common/overlay';
import { GPPractice } from '@pushdr/common/types';
import { SelectSurgeryComponent } from '../select-surgery/select-surgery.component';

@Component({
  selector: 'pushdr-search-surgery-modal',
  templateUrl: './search-surgery-modal.component.html',
  styleUrls: ['./search-surgery-modal.component.scss'],
  standalone: true,
  imports: [SelectSurgeryComponent],
})
export class SearchSurgeryModalComponent extends AbstractModal<any, GPPractice> {
  constructor(private modal: ModalService) {
    super();
  }

  confirmSelectedSurgery(selectedSurgery: GPPractice) {
    this.openConfirmModal(selectedSurgery).subscribe({
      next: () => this.done(selectedSurgery),
    });
  }

  private openConfirmModal(selectedSurgery: GPPractice) {
    return this.modal
      .confirm('Confirm your surgery', selectedSurgery.Name, 'Confirm', 'Cancel', 'confirm-modal')
      .pipe(filter(res => res === true));
  }
}

import { Directive, HostListener } from '@angular/core';

import { ModalService } from '@pushdr/common/overlay';
import { FindSurgeryHelpModalComponent } from '@pushdr/patientapp/common/components';

@Directive({
  selector: '[pushdrFindSurgeryHelp]',
  standalone: true,
})
export class FindSurgeryHelpDirective {
  constructor(private modal: ModalService) {}

  @HostListener('click')
  openHelpModal() {
    const options = { closeOnEscape: true, closeOnClickOutside: true };
    return this.modal.showCustom(FindSurgeryHelpModalComponent, null, 'surgery-help', options);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HomeAddress } from '@pushdr/common/types';

export interface HomeAddressManualComponentFormProps {
  Id: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  AddressLine4: string;
  AddressLine5: string;
  PostTown: string;
  County: string;
  PostCode: string;
}
@Component({
  selector: 'pushdr-home-address-manual',
  templateUrl: './home-address-manual.component.html',
})
export class HomeAddressManualComponent implements OnInit {
  @Input() address: HomeAddress = {
    Id: '',
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    AddressLine4: '',
    AddressLine5: '',
    PostTown: '',
    County: '',
    PostCode: '',
  };
  @Input() buttonText = 'Next';
  @Output()
  manualAddressSubmitted: EventEmitter<HomeAddressManualComponentFormProps> = new EventEmitter();

  form: UntypedFormGroup;
  submitted = false;
  postcodeRegex =
    /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))(\s*)?$/;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      Id: [this.address.Id, []],
      AddressLine1: [this.address.AddressLine1, [Validators.required]],
      AddressLine2: [this.address.AddressLine2, []],
      AddressLine3: [this.address.AddressLine3, []],
      AddressLine4: [this.address.AddressLine4, []],
      AddressLine5: [this.address.AddressLine5, []],
      PostTown: [this.address.PostTown, [Validators.required]],
      County: [this.address.County, [Validators.required]],
      PostCode: [
        this.address.PostCode,
        [Validators.required, Validators.pattern(this.postcodeRegex)],
      ],
    });
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid || this.submitted) return;
    this.submitted = true;
    this.manualAddressSubmitted.emit(this.form.value);
  }
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pushdr-password-valid-alert',
  templateUrl: './password-valid-alert.component.html',
  styleUrls: ['./password-valid-alert.component.scss'],
})
export class PasswordValidAlertComponent {
  @Input() parent: UntypedFormGroup;
  @Input() passwordValidText = 'Great! Your password is fully secure and you’re good to go';
}

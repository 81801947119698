// Hardcoded optimizely flags

export const private_registration = {
  openingHours: 'Open 8am - 8pm',
  prescriptionInfo: 'Express prescription collection',
  miscAppointmentInfo: 'Fit (sick) notes and private referral letters',
  privateUpsellArr: [
    'NHS trained and registered doctors',
    'Appointments available 8am - 8pm',
    'Instant fit (sick) notes',
    'Same day prescriptions',
    'Free cancellation up to 4 hours before your appointment',
  ],
  payment_polling_attempts: 30,
  payment_polling_intervals: 2000,
  enabled: true,
};

export const nhs_registration = {
  nhsUpsellArr: [
    'Free online GP appointments',
    'NHS prescriptions',
    'Fit (Sick) notes and NHS referrals',
  ],
};

export const videoSettings = {
  resolution: '1280x720',
  framerate: 30,
};

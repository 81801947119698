import { CustomerAddress, HomeAddress } from '@pushdr/common/types';
import { buildAddressLabel } from '@pushdr/common/utils';

const postcodeRegex =
  /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))(\s*)?$/;

export const addressFactory = defaults => {
  return Object.assign(
    {
      Id: '',
      AddressLine1: '',
      AddressLine2: '',
      AddressLine3: '',
      AddressLine4: '',
      AddressLine5: '',
      PostTown: '',
      County: '',
      PostCode: '',
      IsDefault: false,
    },
    defaults
  );
};

export const prepareAddressesForNgSelect = data => {
  return data.map(a => {
    return {
      id: a.Id,
      original: a,
      label: buildAddressLabel(
        a,
        [
          ['AddressLine1', 'AddressLine2', 'AddressLine3', 'AddressLine4', 'AddressLine5'],
          'PostTown',
          'PostCode',
        ],
        ', ',
        ['PostCode']
      ),
    };
  });
};

export const validPostcode = (postcode: string) => {
  if (!postcode) return false;
  const postcodeFormatted = postcode ? postcode.replace(/\s/g, '') : '';
  return postcodeRegex.test(postcodeFormatted);
};

export const validAddress = (a: HomeAddress | CustomerAddress) => {
  return (
    a &&
    typeof a.PostCode === 'string' &&
    validPostcode(a.PostCode) &&
    typeof a.PostTown === 'string' &&
    typeof a.County === 'string'
  );
};

<form
  novalidate
  #f="ngForm"
  class="c-form-part u-pt0@xs"
  (ngSubmit)="f.valid && fileToUpload && submit()">
  <h3 class="c-form-part__title">Identity update</h3>
  <p>
    Your account is linked to your medical record. We require you to upload ID to validate any
    changes. This is required for your safety.
  </p>
  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="f.submitted && freason?.errors?.required">
    <label class="c-form-part__label" for="reason">Reason for update</label>
    <input
      class="c-form-part__field"
      type="text"
      id="reason"
      name="reason"
      [(ngModel)]="reason"
      required
      #freason="ngModel" />
    <p class="c-form-part__error-message" *ngIf="reason?.errors?.required">A reason is required</p>
  </div>

  <div
    *ngIf="pageInfo"
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="f.submitted && !fileToUpload">
    <p for="file">We accept the following forms of ID:</p>
    <div *ngIf="pageInfo[0].IdList && pageInfo[0].IdList.length">
      <ul>
        <li *ngFor="let id of pageInfo[0].IdList" style="margin-bottom: 2px">{{ id.Name }}</li>
      </ul>
    </div>
    <input
      class="sessioncamexclude"
      type="file"
      #attachment
      name="identificationFile"
      id="file"
      class="c-btn"
      (change)="handleFileInput($event.target.files)" />
  </div>
  <button
    class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin"
    type="submit"
    value="Submit"
    [disabled]="!f.valid || !fileToUpload || disableUpload">
    Save
  </button>
</form>

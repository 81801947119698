import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderLike } from '../pd-header-interface';

@Component({
  selector: 'pushdr-header-funnel',
  templateUrl: './common-header-funnel.component.html',
  styleUrls: ['./common-header-funnel.component.scss'],
})
export class HeaderFunnelComponent implements HeaderLike {
  @Input() homeUrl = '';
  @Input() accountUrl = '';
  @Input() loggedIn = false;
  @Input() inAccount = false;
  @Output() signedOut = new EventEmitter();
}

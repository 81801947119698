import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { AppointmentSummaryV2 } from '@pushdr/patientapp/common/data-access/patient-api';
import { AnalyticsService, cancelOptions } from '@pushdr/common/data-access/analytics';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';
import { GuardsCheckEnd, GuardsCheckStart, Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pushdr-appointment-upcoming',
  templateUrl: './appointment-upcoming.component.html',
})
export class AppointmentUpcomingComponent implements OnInit, OnDestroy {
  @Input() appointments: AppointmentSummaryV2[] = [];
  @Output() cancel: EventEmitter<AppointmentSummaryV2> = new EventEmitter();
  @Output() book: EventEmitter<void> = new EventEmitter();
  @Output() startEvent: EventEmitter<AppointmentSummaryV2> = new EventEmitter();
  @Output() forceRefresh: EventEmitter<void> = new EventEmitter();

  private destroy$ = new Subject<void>();

  constructor(
    private profile: AccountProfileService,
    private envProxy: EnvironmentProxyService,
    private analytics: AnalyticsService,
    private router: Router,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.createGuardEventListeners();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createGuardEventListeners() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof GuardsCheckStart)
      )
      .subscribe(() => this.modal.showLoader());

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof GuardsCheckEnd)
      )
      .subscribe(() => this.modal.close());
  }

  logCancellationReason(cancellationReasonId: number) {
    const reason = cancelOptions.find(element => {
      return element.id === cancellationReasonId;
    });

    this.analytics.trackEvent({ action: 'cancel_appointment', properties: reason });
  }

  // TODO make this environment driven
  get downloadLink() {
    return this.envProxy.environment.download;
  }

  // TODO make this environment driven
  get androidPlayStoreLinkDefault() {
    return this.profile.isNHS
      ? 'https://play.google.com/store/apps/details?id=com.pushdr.pushdrnhs'
      : 'https://play.google.com/store/apps/details?id=com.pushdr.application';
  }

  // TODO make this environment driven
  get iosAppStoreLinkDefault() {
    return this.profile.isNHS
      ? 'https://itunes.apple.com/gb/app/push-doctor-nhs/id1363993059?mt=8'
      : 'https://itunes.apple.com/gb/app/push-doctor/id838218144?mt=8';
  }
}

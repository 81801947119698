<ng-container trackView="preferences">
  <section class="o-row">
    <div class="col-lg-offset-1 col-xs-12 col-md-10">
      <h3>Preferences</h3>
      <div class="flex flex-col">
        <ng-container *ngIf="allPreferences$ | async as allPreferences; else loading">
          <div *ngFor="let preference of allPreferences" class="flex flex-col w-full mb-4">
            <span class="u-mb8@xs">
              <p class="u-font-weight-normal u-mb0@xs">{{ preference.Name }}</p>
            </span>
            <pushdr-input-checkbox
              class="p-4 rounded border"
              [class.bg-grey-100]="!preference.Value"
              [class.border-white]="!preference.Value"
              [class.border-indigo-500]="preference.Value"
              [(ngModel)]="preference.Value"
              (ngModelChange)="updatePreference(preference)">
              {{ preference.Description }}
            </pushdr-input-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</ng-container>

<!--  Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>

import { Component, Input } from '@angular/core';
import { AppointmentSummaryDetail } from '@pushdr/patientapp/common/data-access/patient-api';

@Component({
  selector: 'pushdr-appointment-details-mini',
  templateUrl: './appointment-details-mini.component.html',
})
export class AppointmentDetailsMiniComponent {
  @Input() details: AppointmentSummaryDetail;
}

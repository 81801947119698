import { Environment } from '../models';

const patient = {
  siteURL: 'https://localhost:4200',
  surgeryURL: 'https://localhost:4201',
  accountURL: 'https://localhost:4202',
};

const signalRv3 = 'https://cdnjs.cloudflare.com/ajax/libs/microsoft-signalr/3.1.3/signalr.min.js';

// a name by which we can reference the current envirnoment
const id = 'sit';

// prettier-ignore
export const environment: Environment = {
  id,
  version: 'localhost',
  testingMode: true,
  webHooksAPI: 'https://sit-waf.internal.pushsvcs.com/hook/api',
  download: 'https://pushdr.test-app.link/download',
  homepage: 'https://www.pushdoctor.co.uk',
  patient: {
    api: 'https://sit-waf.internal.pushsvcs.com/patient',
    questionnaireAPI: 'https://sit-waf.internal.pushsvcs.com/questionnaire/v1',
    nhsAPI: 'https://sit-waf.internal.pushsvcs.com/nhs/api/v1',
    funnel: {
      signin: patient.siteURL + '/register/login',
      register: patient.siteURL + '/register/signup',
      forgotpass: patient.siteURL + '/register/getcode',
      updateterms: patient.siteURL + '/terms',
      maintenance: patient.siteURL + '/maintenance',
    },
    waitingRoom: {
      domainv3: 'https://pdrsit-signalrhubapp-sit.azurewebsites.net/api?h=cust_wait',
      signalRv3,
    },
    surgery: {
      domainv3: 'https://pdrsit-signalrhubapp-sit.azurewebsites.net/api?h=cust_cons',
      signalRv3,
      url: patient.surgeryURL + '/presurgery/start',
      postSurgery: patient.surgeryURL + '/postsurgery'
    },
    account: {
      url: patient.accountURL,
      preAppointment : patient.accountURL + '/preappointment/uploadid',
      appointments: patient.accountURL + '/account/appointments/upcoming',
      medicalComplaint: patient.accountURL + '/account/medicalcomplaint',
      paymentDetails: patient.accountURL + '/account/payment/details',
    },
    booking: {
      url: patient.siteURL + '/booking',
    },
    troubleshooting: {
      windows: {
        cameraIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/windows/camera-issues',
        microphoneIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/windows/microphone-issues',
        soundIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/windows/sound-issues',
        connectionIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/connection-issues'
      },
      mac: {
        cameraIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/mac/camera-issues',
        microphoneIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/mac/microphone-issues',
        soundIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/mac/sound-issues',
        connectionIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/connection-issues'
      },
      generic: {
        cameraIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/camera-issues',
        microphoneIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/microphone-issues',
        soundIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/sound-issues',
        connectionIssuesUrl: 'https://www.pushdoctor.co.uk/troubleshooting/connection-issues'
      },
    },
    siteURL: patient.siteURL,
    surgeryURL: patient.surgeryURL,
    pdPrivateWebsiteDownloadPage: 'https://www.pushdoctor.co.uk/private/download',
    pdPrivateWebsiteBookingPage: 'https://www.pushdoctor.co.uk/private/booking',
  },
  production: false,
  appInsights: {
    config: {
      instrumentationKey: '4acb6464-d86d-4d5f-97f0-9863826ef18a'
    }
  },
  google: {
    gtm: '',
  },
  sentry: {
    environment: id
  },
  nhsAuth : {
    nhsRootDomain: 'https://auth.aos.signin.nhs.uk',
    clientId: 'Push Dr',
    redirectUri: 'https://sit.pushdoctor.co.uk/auth'
  },
  firebase: {
    apiKey: 'AIzaSyA_fF_P2mONSAePsKNoSLTL_5Yae8lpA60',
    authDomain: 'push-doctor-dev.firebaseapp.com',
    databaseURL: 'https://push-doctor-dev.firebaseio.com',
    projectId: 'push-doctor-dev',
    storageBucket: 'push-doctor-dev.appspot.com',
    messagingSenderId: '872902460607',
    appId: '1:872902460607:web:0dbe4a089515f6c3fbf20a',
    measurementId: 'G-TP90SCBKDW'
  },
  zendesk: {
    key: 'c4bae40e-4d82-418e-a7d4-0170cc86c9c2',
  },
};

<div class="c-upcoming">
  <pushdr-appointment
    *ngFor="let appointment of appointments"
    [appointment]="appointment"
    (pdrStartAppointment)="startEvent.emit(appointment)"
    (pdrCancelAppointment)="cancel.emit(appointment)"></pushdr-appointment>
  <pushdr-no-appointment
    *ngIf="!appointments.length"
    (pdrBookAppointment)="book.emit()"></pushdr-no-appointment>
  <p class="text-bluegrey-800" *ngIf="appointments.length">
    If you are suffering from a medical emergency, please call 999 immediately.
  </p>
</div>

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { ModalService } from '@pushdr/common/overlay';
import { GPPartnershipType, GPPractice, GPShareStatus } from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/environment';
import { ApiAccountPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';
import { SearchSurgeryModalComponent } from './search-surgery-modal/search-surgery-modal.component';

@Component({
  selector: 'pushdr-surgery-medical-page',
  templateUrl: './medical-page.component.html',
})
export class MedicalPageComponent {
  isPrivateLike = this.profile.isPrivate;
  gpDetails$ = this.accountApi.getGP();

  isUpdatingGP = false;

  constructor(
    private accountApi: ApiAccountPatientService,
    private profile: AccountProfileService,
    private router: Router,
    private modal: ModalService,
    private envProxy: EnvironmentProxyService
  ) {}

  updateGpSurgery() {
    if (this.isUpdatingGP) {
      return;
    }

    this.isUpdatingGP = true;
    this.openSurgerySearch()
      .pipe(finalize(() => (this.isUpdatingGP = false)))
      .subscribe({
        error: err => this.modal.error(err.message),
      });
  }

  private openSurgerySearch() {
    return this.accountApi.validateActiveBooking().pipe(
      switchMap(() => this.modal.showCustom(SearchSurgeryModalComponent)),
      filter(selectedSurgery => !!selectedSurgery),
      switchMap(selectedSurgery => this.updateSurgery(selectedSurgery))
    );
  }

  goToRequestDetailsComponent(type: 'electronic' | 'post'): void {
    this.router.navigate(['account', 'records'], { queryParams: { type } });
  }

  private updateSurgery(selectedSurgery: GPPractice) {
    const shareStatus = getGpShareStatusFor(selectedSurgery);
    return this.accountApi
      .updateGP(`${selectedSurgery.Id}`, shareStatus)
      .pipe(tap(() => this.redirectToPrivateOrNhs(selectedSurgery)));
  }

  private redirectToPrivateOrNhs(surgery: GPPractice): void {
    const bookingUrl = this.envProxy.environment.patient.booking.url;
    if (surgery.PartnershipType === GPPartnershipType.NHS_PARTNER) {
      window.location.href = `${bookingUrl}/nhs-welcome`;
    } else {
      window.location.href = `${bookingUrl}/private`;
    }
  }
}

function getGpShareStatusFor(surgery: GPPractice): GPShareStatus {
  if (surgery.PartnershipType === GPPartnershipType.NOT_A_PARTNER) {
    return GPShareStatus.TBC;
  } else {
    return GPShareStatus.DO_NOT_SHARE;
  }
}

import { Component } from '@angular/core';
import { AbstractModal } from '../abstract-modal';
import { ButtonTypesEnum } from '../../types';

export interface ConfirmModalOptions {
  header: string;
  bottomText: string;
  confirm: string;
  cancel: string;
  buttonConfirmType: ButtonTypesEnum;
  customHTML?: string;
}

@Component({
  selector: 'pushdr-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: [],
})
export class ConfirmModalComponent extends AbstractModal<ConfirmModalOptions, boolean> {}

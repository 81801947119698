import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcceptancesPageComponent } from './account/acceptances-page/acceptances-page.component';
import { MedicalPageComponent } from './account/medical-page/medical-page.component';
import { AccountProfilePageComponent } from './account/profile-page/account-profile-page.component';
import { UploadIdPageComponent } from './account/upload-id-page/upload-id-page.component';
import { AppShellComponent } from './app-shell.component';
import { AppointmentMenuShellComponent } from './appointments/appointments-menu-shell/appointment-menu-shell.component';
import { AppointmentCancelComponent } from './appointments/cancel-page/appointment-cancel.component';
import { AppointmentFuturePageComponent } from './appointments/future-page/appointment-future-page.component';
import { AppointmentHistoryPageComponent } from './appointments/history-page/appointment-history-page.component';
import { AppointmentMedicalComplaintPageComponent } from './medical/medical-complaint-page/appointment-medical-complaint.component';
import { RequestDetailsPageComponent } from './account/request-details-page/request-details-page.component';
import { RequestDetailsPageResolver } from './account/request-details-page/request-details-page-resolver';
import { MocksComponent } from './mocks/mocks.component';
import { IsNHSCustomerGuard } from './guards/is-nhs-customer';

const routes: Routes = [
  { path: '', redirectTo: 'account', pathMatch: 'full' },
  { path: 'mocks', component: MocksComponent },
  {
    path: 'account',
    component: AppShellComponent,
    children: [
      { path: '', redirectTo: 'appointments', pathMatch: 'full' },
      {
        path: 'about',
        component: AccountProfilePageComponent,
      },
      {
        path: 'medical',
        component: MedicalPageComponent,
      },
      {
        path: 'records',
        component: RequestDetailsPageComponent,
        resolve: {
          details: RequestDetailsPageResolver,
        },
      },
      {
        path: 'acceptances',
        component: AcceptancesPageComponent,
      },
      {
        path: 'preferences',
        redirectTo: 'acceptances',
        pathMatch: 'full',
      },
      {
        path: 'appointments',
        component: AppointmentMenuShellComponent,
        children: [
          { path: '', redirectTo: 'upcoming', pathMatch: 'full' },
          {
            path: 'upcoming',
            component: AppointmentFuturePageComponent,
            canActivate: [IsNHSCustomerGuard],
          },
          {
            path: 'cancel/:id',
            component: AppointmentCancelComponent,
            canActivate: [IsNHSCustomerGuard],
          },
          {
            path: 'history',
            component: AppointmentHistoryPageComponent,
          },
        ],
      },
      {
        path: 'medicalcomplaint',
        component: AppointmentMedicalComplaintPageComponent,
      },
      {
        path: 'uploadid',
        component: UploadIdPageComponent,
      },
    ],
  },
  {
    path: 'preappointment',
    loadChildren: () =>
      import('@pushdr/patientapp/pre-appointment').then(m => m.PreAppointmentModule),
  },
  {
    path: 'feedback',
    loadChildren: () => import('@pushdr/feedback/shell').then(m => m.FeedbackShellModule),
  },
  { path: '**', redirectTo: 'account/appointments/upcoming', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [RequestDetailsPageResolver],
})
export class AccountAppRoutingModule {}

<div class="c-header__wrap">
  <div class="o-wrapper o-container o-container--fluid">
    <div class="o-row o-row--no-margin-bottom middle-xs">
      <div class="col-xs-8 col-sm-8 col-lg-3">
        <pushdr-home-logo [homeUrl]="homeUrl"></pushdr-home-logo>
      </div>
      <div class="col-xs-4 col-sm-4 col-lg-9 end-xs">
        <button class="c-header__meganav-toggle" (click)="toggleBurgerMenu()">
          <div
            class="c-hamburger-btn c-hamburger-btn--slider"
            [class.c-hamburger-btn--is-active]="menuIsOpen$ | async">
            <div class="c-hamburger-btn__box">
              <div class="c-hamburger-btn__inner"></div>
            </div>
          </div>
          <span class="c-header__btn-text">{{ (menuIsOpen$ | async) ? 'close' : 'menu' }}</span>
        </button>
        <nav class="c-header__meganav">
          <div class="pl-12">
            <ng-content></ng-content>
          </div>
          <ul class="c-header__meganav-list header-items">
            <li
              *ngIf="inAccount && (isBookingEnabled$ | async)"
              class="c-header__meganav-cta c-header__meganav-cta--primary">
              <a [href]="bookingURL" trackClick="book an appointment" title="Book appointment">
                Book appointment
              </a>
            </li>
            <li *ngIf="!inAccount" class="c-header__meganav-cta c-header__meganav-cta--transparent">
              <a [href]="accountUrl" title="My account">My account</a>
            </li>
            <li *ngIf="loggedIn" class="c-header__meganav-cta c-header__meganav-cta--anchor">
              <a href="javascript:void(0)" (click)="signedOut.emit()" title="Log out">Log out</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>

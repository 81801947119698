import { Directive, Input, HostListener, NgModule } from '@angular/core';
import { padStart } from 'lodash';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[leadingZeros]',
})
export class LeadingZerosDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('leadingZeros') countOfZeros: number;

  @HostListener('blur', ['$event.target', '$event.target.value'])
  onBlur(el: HTMLInputElement, value: string): void {
    if (value) {
      const zeroPaddedString = padStart(value.trim(), this.countOfZeros, '0');
      if (zeroPaddedString !== value) {
        el.value = zeroPaddedString;
        this.dispatchEvent(el, 'input');
      }
    }
  }

  private dispatchEvent(el: HTMLInputElement, eventType: string) {
    const event = document.createEvent('Event');
    event.initEvent(eventType, false, false);
    el.dispatchEvent(event);
  }
}

@NgModule({
  declarations: [LeadingZerosDirective],
  exports: [LeadingZerosDirective],
})
export class LeadingZerosModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { Account } from '@pushdr/common/types';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/account';
  }

  create(account: Account) {
    return this.post('', account);
  }

  update(account: Account) {
    return this.put('', account, this.headerService.headers());
  }

  getAccount() {
    return this.get();
  }

  getPartner() {
    return this.get('/partner', {}, this.headerService.headers());
  }

  updateMobile(MobileNumber: string, VerificationCode: string) {
    return this.post(
      '/UpdateMobile',
      {
        MobileNumber,
        VerificationCode,
      },
      this.headerService.headers()
    );
  }

  getMobileVerificationCode(MobileNumber: string) {
    return this.post(
      '/RequestMobileVerificationCode',
      {
        MobileNumber,
      },
      this.headerService.headers()
    );
  }

  requestNotificationForGPPartner(GpId: number | string, Email: string) {
    return this.post(
      '/notifygp',
      {
        GpId,
        Email,
      },
      this.headerService.headers()
    );
  }
}

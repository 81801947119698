import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';

@Injectable()
export class IsNHSCustomerGuard implements CanActivate {
  constructor(private accountProfile: AccountProfileService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    return this.accountProfile.isNHS ? true : this.router.createUrlTree(['/account/about']);
  }
}

import { Component } from '@angular/core';
import { CustomerPreference } from '@pushdr/common/types';
import { PreferenceApiService } from '@pushdr/patientapp/common/data-access/patient-api';
import { Observable } from 'rxjs';

@Component({
  selector: 'pushdr-account-acceptances-page',
  templateUrl: './acceptances-page.component.html',
})
export class AcceptancesPageComponent {
  allPreferences$: Observable<CustomerPreference[]> = this.preferencesApi.getPreferences();

  constructor(private preferencesApi: PreferenceApiService) {}

  updatePreference(preference: CustomerPreference) {
    this.preferencesApi.updatePreference(preference).subscribe();
  }
}

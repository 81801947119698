import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { ModalService } from '@pushdr/common/overlay';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';

export class EditPhoneNumberValidators {
  static checkNumber(control: AbstractControl) {
    const ukMobileRegexPattern = /^(07[\d]{8,12}|\+447[\d]{7,11})$/;
    const valid = ukMobileRegexPattern.test(control.value);
    return valid ? null : { invalidNumber: true };
  }
}

@Component({
  selector: 'pushdr-edit-phone-form',
  templateUrl: 'edit-phone-form.component.html',
})
export class EditPhoneFormComponent implements OnInit {
  @Input() phoneNumber = '';
  @Output() save: EventEmitter<{
    MobileNumber: string;
    VerificationCode: string;
  }> = new EventEmitter();

  mobileForm: UntypedFormGroup;
  codeRequested = false;
  verificationCode: string;
  numberCodeRequestedFor: string;

  constructor(
    private api: ApiNHSPatientService,
    private fb: UntypedFormBuilder,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.mobileForm = this.fb.group({
      MobileNumber: ['', [Validators.required, EditPhoneNumberValidators.checkNumber]],
      VerificationCode: ['', [Validators.pattern(/^[0-9]{4}$/)]],
    });
  }

  requestVerificationCode() {
    const number = this.mobileForm.get('MobileNumber').value;
    this.api.account.getMobileVerificationCode(number).subscribe(
      response => {
        this.numberCodeRequestedFor = number;
        this.codeRequested = true;
      },
      error => {
        this.numberCodeRequestedFor = '';
        this.modal.error(error.message);
      }
    );
  }

  submitForm() {
    if (this.numberCodeRequestedFor === this.mobileForm.get('MobileNumber').value)
      this.save.emit(this.mobileForm.value);
    else
      this.modal.error(
        'Please ensure the mobile number you have input matches the one the code was requested for.'
      );
  }
}

import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy } from '@angular/core';
import { uniqueId } from 'lodash';
import { Subscription } from 'rxjs';

import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

const provideCheckboxValueAccessor = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCheckboxComponent),
  multi: true,
};

@Component({
  selector: 'pushdr-input-checkbox, [pushdrInputCheckbox]',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideCheckboxValueAccessor],
  imports: [ReactiveFormsModule],
})
export class InputCheckboxComponent implements ControlValueAccessor, OnDestroy {
  protected inputId = uniqueId('checkbox-');
  protected control = new FormControl(false);

  protected valueChanges: Subscription;
  protected onTouched = () => void 0;

  get value(): boolean {
    return this.control.value;
  }

  set value(checked: boolean) {
    this.control.setValue(checked);
  }

  ngOnDestroy(): void {
    this.valueChanges?.unsubscribe();
    this.valueChanges = null;
  }

  toggle() {
    const newValue = !this.control.value;
    this.control.setValue(newValue);
  }

  writeValue(checked: boolean): void {
    this.control.setValue(checked);
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.valueChanges?.unsubscribe();
    this.valueChanges = this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.control[isDisabled ? 'disable' : 'enable']?.();
  }
}

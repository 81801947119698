import { Injectable } from '@angular/core';
import { defer, EMPTY, Observable, of } from 'rxjs';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { ModalService } from '@pushdr/common/overlay';
import { StorageService } from '@pushdr/common/utils';
import { FeedbackApiService, PendingFeedback } from '@pushdr/feedback/api';
import { ReminderModalComponent } from './reminder-modal/reminder-modal.component';
import { ReminderModalData } from './models';

@Injectable({
  providedIn: 'root',
})
export class FeedbackReminderService {
  constructor(
    private modal: ModalService,
    private storage: StorageService,
    private api: FeedbackApiService
  ) {}

  remind(): Observable<boolean> {
    return this.reminderPostponed().pipe(
      filter(postponed => !postponed),
      switchMap(() => this.api.getPendingFeedback()),
      switchMap(res => (res?.OrderId ? this.showReminderModal(res) : EMPTY)),
      switchMap(({ postpone, pending }) => {
        if (postpone) {
          this.postpone();
          return of(postpone);
        }
        const orderId = pending.OrderId;
        return this.api.rejectFeedback(orderId).pipe(mapTo(postpone));
      })
    );
  }

  postpone(): void {
    // Marks the postponed reminder into browser's session
    this.storage.set('FeedbackReminderPostponed', true, null, null, null);
  }

  private reminderPostponed(): Observable<boolean> {
    const fetchPostponedFromCookies = () => this.storage.get('FeedbackReminderPostponed');

    return defer(() => of(fetchPostponedFromCookies())).pipe(
      // Casts the storage result into boolean
      map(postponed => !!postponed)
    );
  }

  private showReminderModal(pending: PendingFeedback) {
    const modalData = buildReminderModalData(pending);
    return this.modal.showCustom(ReminderModalComponent, modalData, 'reminder').pipe(
      // Decorate the modal response by additional data
      map(postpone => ({ postpone, pending }))
    );
  }
}

function buildReminderModalData(pending: PendingFeedback): ReminderModalData {
  const orderId = pending.OrderId;
  const appointmentDateTime = moment.utc(pending.ConsulationDateTimeUtc);
  return { orderId, appointmentDateTime };
}

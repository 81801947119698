<account-content-wrapper>
  <div class="o-row o-row--no-margin-bottom">
    <div class="col-xs-12 col-md-8 u-mb16@xs u-mt16@xs">
      <div class="c-notification">
        <p class="c-notification__title u-mb0@xs">
          Do you have additional patients on your account?
        </p>
        <button
          class="c-btn-anchor c-notification__show-more"
          style="float: right"
          (click)="showMore = !showMore">
          <i class="material-icons c-material-icons-inline">{{
            showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }}</i>
        </button>
        <p *ngIf="showMore" class="c-notification__body u-mt16@xs u-mb0@xs">
          If additional or linked patient(s) are under the age of 16, their records will be sent
          with the account holders. If the patient(s) are over 16, please ask them to
          <a href="mailto:support@pushdoctor.co.uk" target="_blank">contact us</a>
        </p>
      </div>

      <div *ngIf="!sentRequest">
        <div *ngIf="type === 'electronic'" data-cy="electronic-container">
          <ng-container *ngTemplateOutlet="electronic"> </ng-container>
        </div>

        <div *ngIf="type === 'post'" data-cy="post-container">
          <ng-container *ngTemplateOutlet="post"> </ng-container>
        </div>
      </div>

      <div *ngIf="sentRequest" data-cy="sent-container">
        <ng-container *ngTemplateOutlet="sent"> </ng-container>
      </div>
    </div>
  </div>
</account-content-wrapper>

<ng-template #electronic>
  <h1 class="u-mb24@xs u-font-style-h3">Request your records electronically</h1>
  <p>Request an electronic copy of your medical records using the button&nbsp;below.</p>
  <p>
    You will receive an email notification along with a password to access your documents in your
    account within one calendar month.
  </p>
  <p>
    <strong>
      Please ensure your email address is correct before requesting your records. If you need to
      ammend your email address, please contact our Customer Experience team.
    </strong>
  </p>
  <hr class="grey-padded" />
  <h4>Email address</h4>
  <p>{{ email }}</p>
  <hr class="grey-padded" />
  <button
    class="c-btn c-btn--primary"
    type="submit"
    (click)="requestElectronicRecords()"
    data-cy="request-electronic">
    Request electronic records
  </button>
</ng-template>

<ng-template #post>
  <h1 class="u-mb24@xs u-font-style-h3">Request your records by post</h1>
  <p>Request an electronic copy of your medical records using the button&nbsp;below.</p>
  <p>
    You will receive an email notification along with a password to access your documents in your
    account within one calendar month.
  </p>
  <p>
    <strong>
      Please ensure your email address is correct before requesting your records. If you need to
      ammend your email address, please contact our Customer Experience team.
    </strong>
  </p>
  <hr class="grey-padded" />
  <h4>Home address</h4>
  <p>
    <span [innerHTML]="addressString(address, { delimiterBreak: true })"></span>
  </p>
  <hr class="grey-padded" />
  <button
    class="c-btn c-btn--primary"
    type="submit"
    (click)="requestPrintedRecords()"
    data-cy="request-post">
    Request printed records
  </button>
</ng-template>

<ng-template #sent>
  <h1 class="u-mb24@xs u-font-style-h3">Your request has been received</h1>
  <p style="font-weight: 300">
    We are now processing your request. Please note that this can take up to one calendar month.
  </p>
  <p>
    If you have any questions about your request, please
    <a href="mailto:support@pushdoctor.co.uk" target="_blank">contact us</a>.
  </p>
</ng-template>

<div class="o-row o-row--no-horizontal-margin">
  <div class="col-xs-12">
    <ul class="o-styled-list">
      <li class="o-styled-list__item o-styled-list__item--icon-cal">
        {{ details.StartUtc | date: 'EEEE, MMMM d, y' }}
      </li>
      <li class="o-styled-list__item o-styled-list__item--icon-clock">
        {{ details.StartUtc | date: 'h:mm a' }}
      </li>
    </ul>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputDobModule } from './input-dob/input-dob.module';
import { SpinnerLoaderModule } from './spinner-loader/spinner-loader.module';
import { AlertBarModule } from './alert-bar/alert-bar.module';
import { SharedLogoModule } from './home-logo/shared-logo.module';
import { FileHandlerModule } from './file-handler/file-handler.module';
import { InputWithIconModule } from './input-with-icon/input-with-icon.module';
import { SearchPostcodeModule } from './search-postcode/search-postcode.module';
import { IconWithRoundedBorderModule } from './icon-with-rounded-border/icon-with-rounder-border.module';
import { FormComponentsModule } from './form-components/form-components.module';
import { ModalsModule } from './modals/modals.module';
import { ConsultationChatModule } from './consultation-chat/consultation-chat.module';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputDobModule,
    SpinnerLoaderModule,
    AlertBarModule,
    SharedLogoModule,
    FileHandlerModule,
    InputWithIconModule,
    IconWithRoundedBorderModule,
    FormComponentsModule,
    ModalsModule,
    ConsultationChatModule,
    InputCheckboxComponent,
  ],
  exports: [
    InputDobModule,
    SpinnerLoaderModule,
    AlertBarModule,
    SharedLogoModule,
    FileHandlerModule,
    InputWithIconModule,
    SearchPostcodeModule,
    IconWithRoundedBorderModule,
    FormComponentsModule,
    ModalsModule,
    ConsultationChatModule,
    InputCheckboxComponent,
  ],
})
export class CommonComponentsModule {}

<div class="c-modal c-modal--is-visible">
  <div class="c-modal__wrap c-modal__wrap--40">
    <h3>Please select a reason why you have cancelled</h3>

    <div class="modal__body">
      <pushdr-selection-list
        [options]="cancelOptions"
        [multi]="false"
        buttonText="Continue"
        (selected)="confirmCancellation($event)">
      </pushdr-selection-list>
    </div>

    <div class="modal__footer">
      <button (click)="close()" class="c-btn c-btn--secondary">Skip</button>
    </div>
  </div>
</div>

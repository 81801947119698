import { SelectableOption } from '@pushdr/common/types';

type cancelReasons =
  | 'I got a face to face appointment'
  | 'I visited my local pharmacy'
  | 'I needed more urgent care'
  | 'I started to feel better'
  | "I couldn't make the time or day"
  | 'Other';

type cancelReasonsSummaries =
  | 'faceToFace'
  | 'pharmacy'
  | 'urgentCare'
  | 'feelBetter'
  | 'cannotMakeAppointment'
  | 'other';

export interface CancelReasonSelectableOptions extends SelectableOption {
  name: cancelReasons;
}

export const cancelOptions: CancelReasonSelectableOptions[] = [
  {
    id: 1,
    name: 'I got a face to face appointment',
  },
  {
    id: 2,
    name: 'I visited my local pharmacy',
  },
  {
    id: 3,
    name: 'I needed more urgent care',
  },
  {
    id: 4,
    name: 'I started to feel better',
  },
  {
    id: 5,
    name: `I couldn't make the time or day`,
  },
  {
    id: 6,
    name: 'Other',
  },
];

export const cancelReasonsMap: { [key in cancelReasons]: cancelReasonsSummaries } = {
  'I got a face to face appointment': 'faceToFace',
  'I visited my local pharmacy': 'pharmacy',
  'I needed more urgent care': 'urgentCare',
  'I started to feel better': 'feelBetter',
  "I couldn't make the time or day": 'cannotMakeAppointment',
  Other: 'other',
};

import { Environment } from '../models';

const patient = {
  siteURL: '#{patient.funnel.url}#',
  surgeryURL: '#{patient.surgery.url}#',
  accountURL: '#{patient.account.url}#',
};

const signalRv3 = 'https://cdnjs.cloudflare.com/ajax/libs/microsoft-signalr/3.1.3/signalr.min.js';

// a name by which we can reference the current envirnoment
const id = '#{apiEnvironment}#';

// prettier-ignore
export const environment: Environment = {
  id,
  version: '#{Build.BuildNumber}#',
  testingMode: false,
  webHooksAPI: '#{webHooksApi}#',
  download: '#{app.downloadUrl}#',
  homepage: 'https://www.pushdoctor.co.uk',
  patient: {
    api: '#{patient.api}#',
    questionnaireAPI: '#{patient.questionnaire.api}#',
    nhsAPI: '#{patient.nhs.api}#',
    funnel: {
      signin: patient.siteURL + '/register/login',
      register: patient.siteURL + '/register/signup',
      forgotpass: patient.siteURL + '/register/getcode',
      updateterms: patient.siteURL + '/terms',
      maintenance: patient.siteURL + '/maintenance',
    },
    waitingRoom: {
      domainv3: '#{patient.waitingroom.signalr}#',
      signalRv3,
    },
    surgery: {
      domainv3: '#{patient.surgery.signalr}#',
      signalRv3,
      url: patient.surgeryURL + '/presurgery/start',
      postSurgery: patient.surgeryURL + '/postsurgery'
    },
    account: {
      url: patient.accountURL,
      preAppointment : patient.accountURL + '/preappointment/uploadid',
      appointments: patient.accountURL + '/account/appointments/upcoming',
      medicalComplaint: patient.accountURL + '/account/medicalcomplaint',
      paymentDetails: patient.accountURL + '/account/payment/details',
    },
    booking: {
      url: patient.siteURL + '/booking',
    },
    troubleshooting: {
      windows: {
        cameraIssuesUrl: '#{patient.troubleshooting.windows.camera_issues_url}#',
        microphoneIssuesUrl: '#{patient.troubleshooting.windows.microphone_issues_url}#',
        soundIssuesUrl: '#{patient.troubleshooting.windows.sound_issues_url}#',
        connectionIssuesUrl: '#{patient.troubleshooting.windows.connection_issues_url}#'
      },
      mac: {
        cameraIssuesUrl: '#{patient.troubleshooting.mac.camera_issues_url}#',
        microphoneIssuesUrl: '#{patient.troubleshooting.mac.microphone_issues_url}#',
        soundIssuesUrl: '#{patient.troubleshooting.mac.sound_issues_url}#',
        connectionIssuesUrl: '#{patient.troubleshooting.mac.connection_issues_url}#'
      },
      generic: {
        cameraIssuesUrl: '#{patient.troubleshooting.generic.camera_issues_url}#',
        microphoneIssuesUrl: '#{patient.troubleshooting.generic.microphone_issues_url}#',
        soundIssuesUrl: '#{patient.troubleshooting.generic.sound_issues_url}#',
        connectionIssuesUrl: '#{patient.troubleshooting.generic.connection_issues_url}#'
      },
    },
    siteURL: patient.siteURL,
    surgeryURL: patient.surgeryURL,
    pdPrivateWebsiteDownloadPage: '#{patient.private.download_url}#',
    pdPrivateWebsiteBookingPage: '#{patient.private.booking_url}#'
  },
  production: false,
  appInsights: {
    config: {
      instrumentationKey: '#{appInsights.key}#'
    }
  },
  google: {
    gtm: '#{google.patientGtm}#'
  },
  sentry: {
    environment: id
  },
  nhsAuth : {
    nhsRootDomain: '#{nhsAuth.nhsRootDomain}#',
    clientId: '#{nhsAuth.clientId}#',
    redirectUri: '#{nhsAuth.redirectUri}#'
  },
  firebase: {
    apiKey: '#{firebase.apiKey}#',
    authDomain: '#{firebase.authDomain}#',
    databaseURL: '#{firebase.databaseURL}#',
    projectId: '#{firebase.projectId}#',
    storageBucket: '#{firebase.storageBucket}#',
    messagingSenderId: '#{firebase.messagingSenderId}#',
    appId: '#{firebase.appId}#',
    measurementId: '#{firebase.measurementId}#'
  },
  zendesk: {
    key: '#{zendesk.key}#',
  },
};

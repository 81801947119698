import { ErrorMessageAdaptor } from './utils';
import { PDServerError } from '@pushdr/common/types';
import { ErrorMessageCore } from './core.errors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationErrorsService extends ErrorMessageCore {
  login(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(error);
      case 404:
        return ErrorMessageAdaptor(error);
      default:
        return this.commonErrors(error);
    }
  }

  sendAuthenticationCode(error: PDServerError): { header: string; message: string } {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(error);
      default:
        return this.commonErrors(error);
    }
  }

  verification(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(error);
      default:
        return this.commonErrors(error);
    }
  }

  forgotPassword(error: PDServerError) {
    switch (error.status) {
      case 400:
        error.message = error.message.replace('Error - ', '');
        return ErrorMessageAdaptor(error);
      default:
        return this.commonErrors(error);
    }
  }

  resetPassword(error: PDServerError): PDServerError {
    switch (error.status) {
      case 400:
        error.message = error.message.replace('Error - ', '');
        return ErrorMessageAdaptor(error);
      default:
        return this.commonErrors(error);
    }
  }
}

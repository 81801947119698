import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { filter, map, mergeMap, shareReplay, timeInterval } from 'rxjs/operators';
import {
  ApiNHSPatientService,
  AppointmentSummaryStatus,
  AppointmentSummaryV2,
} from '@pushdr/patientapp/common/data-access/patient-api';

const bookedAppointmentStatuses = [
  AppointmentSummaryStatus.Scheduled,
  AppointmentSummaryStatus['In Progress'],
  AppointmentSummaryStatus.Pending,
];

@Injectable({
  providedIn: 'root',
})
export class PatientAppointmentsService {
  private _forceRefresh$ = new BehaviorSubject<void>(null);

  appointmentSummary$: Observable<AppointmentSummaryV2[]> = combineLatest([
    this._forceRefresh$,
    timer(0, 60000),
  ]).pipe(
    timeInterval(),
    mergeMap(() => this.api.booking.getBookedAppointments()),
    filter(res => !!res),
    shareReplay(1)
  );

  hasBookedAppointments$ = this.appointmentSummary$.pipe(
    map(items => items?.some(item => bookedAppointmentStatuses.includes(item.Status)))
  );

  constructor(private api: ApiNHSPatientService) {}

  forceRefresh() {
    this._forceRefresh$.next();
  }
}

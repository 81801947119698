import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppointmentDetailsMiniComponent } from './appointment-details-mini/appointment-details-mini.component';
import { AppointmentUpcomingComponent } from './appointment-upcoming/appointment-upcoming.component';
import { AppointmentDetailsComponent } from './appointments-details/appointment-details.component';
import { EditDetailsComponent } from './edit-forms/edit-details/edit-details.component';
import { EditDOBFormComponent } from './edit-forms/edit-dob/edit-dob-form.component';
import { EditGenderFormComponent } from './edit-forms/edit-gender/edit-gender-form.component';
import { EditPasswordFormComponent } from './edit-forms/edit-password/edit-password-form.component';
import { EditPhoneFormComponent } from './edit-forms/edit-phone/edit-phone-form.component';
import { EditUploadIdComponent } from './edit-forms/edit-upload-id/edit-upload-id.component';
import { CommonComponentsModule } from '@pushdr/common/components';
import { SharedEditFormModule, SystemSupportModule } from '@pushdr/patientapp/common/components';
import { CancelAppointmentModalComponent } from './cancel-appointment-modal/cancel-appointment-modal.component';
import { AppointmentCancelledModalComponent } from './appointment-cancelled-modal/appointment-cancelled-modal.component';
import { PatientappBookingCommonModule } from '@pushdr/patientapp/booking/common';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { PasswordInputModule } from '@pushdr/patientapp/common/components';
import { AccountProfileModule } from '@pushdr/patientapp/common/utils';
import { AppointmentComponent } from './appointment/appointment.component';
import { NoAppointmentComponent } from './no-appointment/no-appointment.component';

@NgModule({
  imports: [
    CommonModule,
    AnalyticsDirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    SharedEditFormModule,
    RouterModule,
    AccountProfileModule,
    CommonComponentsModule,
    SystemSupportModule,
    PatientappBookingCommonModule,
    PasswordInputModule,
    AppointmentComponent,
    NoAppointmentComponent,
    AppointmentDetailsComponent,
  ],
  declarations: [
    AppointmentUpcomingComponent,
    AppointmentDetailsMiniComponent,
    EditPhoneFormComponent,
    EditPasswordFormComponent,
    EditDOBFormComponent,
    EditGenderFormComponent,
    EditDetailsComponent,
    EditUploadIdComponent,
    CancelAppointmentModalComponent,
    AppointmentCancelledModalComponent,
  ],
  exports: [
    AppointmentUpcomingComponent,
    AppointmentDetailsComponent,
    AppointmentDetailsMiniComponent,
    EditPhoneFormComponent,
    EditPasswordFormComponent,
    EditDOBFormComponent,
    EditGenderFormComponent,
    EditUploadIdComponent,
    EditDetailsComponent,
  ],
})
export class SharedAccountComponentsModule {}

import { Injectable } from '@angular/core';
import {
  ApiAccountPatientService,
  ApiBookingPatientService,
  ApiConsultationPatientService,
  ApiEmergencyPatientService,
  ApiGeneralPatientService,
} from './endpoints';

@Injectable({
  providedIn: 'root',
})
export class ApiPatientService {
  constructor(
    public account: ApiAccountPatientService,
    public booking: ApiBookingPatientService,
    public consultation: ApiConsultationPatientService,
    public emergency: ApiEmergencyPatientService,
    public general: ApiGeneralPatientService
  ) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShowForNhsDirective } from './show-for-nhs.directive';
import { ShowForPrivateDirective } from './show-for-private.directive';
import { ShowForAnyPartnerDirective } from './show-for-partner.directive';
import { ShowForPrivateLikeDirective } from './show-for-private-like.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ShowForNhsDirective,
    ShowForPrivateDirective,
    ShowForAnyPartnerDirective,
    ShowForPrivateLikeDirective,
  ],
  exports: [
    ShowForNhsDirective,
    ShowForPrivateDirective,
    ShowForAnyPartnerDirective,
    ShowForPrivateLikeDirective,
  ],
})
export class AccountProfileModule {}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ClinicianTypeNamePipe, CommonPipesModule } from '@pushdr/common/utils';
import {
  AppointmentSummaryStatus,
  AppointmentSummaryV2,
  BookingApiService,
} from '@pushdr/patientapp/common/data-access/patient-api';
import { defer, of, shareReplay, switchMap } from 'rxjs';

@Component({
  selector: 'pushdr-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss'],
  standalone: true,
  imports: [CommonModule, CommonPipesModule, ClinicianTypeNamePipe],
})
export class AppointmentDetailsComponent {
  opened = false;
  @Input() appointment: AppointmentSummaryV2;
  AppointmentStatus = AppointmentSummaryStatus;

  appointmentDetails$ = defer(() => of(this.appointment.Id)).pipe(
    switchMap(id => this.api.getBookedAppointmentDetail(id)),
    shareReplay(1)
  );

  constructor(private api: BookingApiService) {}
}

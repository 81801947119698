<ng-container *ngIf="details$ | async as details; else loading">
  <account-content-wrapper>
    <h3 class="mb-8" trackView="cancel">Your Appointment</h3>
    <div [ngSwitch]="cancellationType" class="mb-8">
      <div *ngSwitchCase="CancellationType.CHARGE">
        <p *ngIf="details.Price">
          As you are cancelling less than {{ details.CancellationDeadline / 60 }} hours before your
          appointment,
          <strong>you will not be eligible for a refund.</strong>
        </p>
        <p>Are you sure you want to cancel your appointment?</p>
      </div>
      <div *ngSwitchCase="CancellationType.NO_CHARGE">
        <p *ngIf="details.Price">
          As you are cancelling more than {{ details.CancellationDeadline / 60 }} hours before your
          appointment,
          <strong>you are eligible for a full refund,</strong>
          which will take 5-10 business days.
        </p>
        <p>Are you sure you want to cancel your appointment?</p>
      </div>
      <div *ngSwitchCase="CancellationType.NHS">
        <p>You will not be able to re-book the appointment after cancelling.</p>
        <p>Are you sure you want to cancel your appointment?</p>
      </div>
    </div>
    <pushdr-appointment-details-mini [details]="details"></pushdr-appointment-details-mini>
    <div class="o-row o-row--no-horizontal-margin">
      <div class="col-xs-12">
        <div class="u-text-center">
          <button class="c-btn c-btn--primary u-mb16@xs u-mr0@xs" (click)="submit()" type="submit">
            Yes, cancel my appointment
          </button>
          <button (click)="back()" class="c-btn c-btn--secondary u-mr0@xs" type="cancel">
            No, keep my appointment
          </button>
        </div>
      </div>
    </div>
  </account-content-wrapper>
</ng-container>

<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClinicianTypeNamePipe, CommonPipesModule } from '@pushdr/common/utils';
import { SystemSupportModule } from '@pushdr/patientapp/common/components';
import {
  AppointmentSummaryStatus,
  AppointmentSummaryV2,
} from '@pushdr/patientapp/common/data-access/patient-api';

@Component({
  selector: 'pushdr-appointment',
  templateUrl: './appointment.component.html',
  standalone: true,
  imports: [CommonModule, ClinicianTypeNamePipe, SystemSupportModule, CommonPipesModule],
})
export class AppointmentComponent {
  @Input() appointment: AppointmentSummaryV2;
  @Output() pdrStartAppointment = new EventEmitter<void>();
  @Output() pdrCancelAppointment = new EventEmitter<void>();

  AppointmentStatus = AppointmentSummaryStatus;
}

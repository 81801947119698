import { ErrorMessageAdaptor } from './utils';
import { PDServerError } from '@pushdr/common/types';
import { ErrorMessageCore } from './core.errors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorsService extends ErrorMessageCore {
  canRegisterMobile(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(error, 'This is not a valid UK mobile phone number');
      case 404:
        return ErrorMessageAdaptor(
          error,
          'Something went wrong checking this mobile number, please try again. If the problem persists contact customer services.'
        );
      case 409:
        return ErrorMessageAdaptor(
          error,
          'That mobile number is already registered to another account.'
        );
      case 412:
        return ErrorMessageAdaptor(
          error,
          'Please check your details are correct or contact your GP surgery to update. ',
          'Sorry the details you provided don’t match your medical records'
        );
      default:
        return this.commonErrors(error);
    }
  }
  canRegisterEmail(error: PDServerError) {
    if ([400, 404, 409].includes(error.status)) {
      return ErrorMessageAdaptor(error, error.message);
    }
    return this.commonErrors(error);
  }
  dob(error: PDServerError) {
    switch (error.status) {
      case 400:
        return ErrorMessageAdaptor(
          error,
          error.original.error.Errors[0].Message, //TODO parse this kind of error
          'Error'
        );
      case 412:
        return ErrorMessageAdaptor(
          error,
          'Please check your details are correct or contact your GP surgery to update. ',
          'Sorry the details you provided don’t match your medical records'
        );
      case 404:
        return ErrorMessageAdaptor(
          error,
          'Something has gone wrong with your invitation code, contact your surgery for a new one'
        );
      default:
        return this.commonErrors(error);
    }
  }
}

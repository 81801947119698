<form novalidate #f="ngForm" (ngSubmit)="(f.valid)">
  <pushdr-search-postcode (search)="searchPostcode($event)"></pushdr-search-postcode>
</form>

<div *ngIf="results?.length" [class.c-form-part__entry--is-invalid]="submitted" class="u-mb24@xs">
  <label class="c-form-part__field-label" for="ipt-address">Please select your address</label>

  <div class="c-form-part__margin-wrap">
    <ng-select
      data-cy="select"
      [(ngModel)]="selectedResult"
      id="ipt-address"
      (change)="selectAddress($event)"
      (clear)="clearSelection()"
      name="ipt-address"
      [items]="results"
      placeholder="Select your address"
      [searchable]="false"
      bindValue="id"
      bindLabel="label"
      aria-live="polite">
    </ng-select>
    <p data-cy="select-error" class="c-form-part__error-message" *ngIf="submitted">
      This selection is required
    </p>
  </div>
</div>

<button
  data-cy="submit-button"
  *ngIf="!!address"
  class="c-btn c-btn--primary c-form-part__btn"
  (click)="submit()"
  trackClick="buttonText">
  {{ buttonText }}
</button>

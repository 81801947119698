import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectListAddress } from '@pushdr/common/types';
import { HomeAddress } from '@pushdr/common/types';
import { prepareAddressesForNgSelect } from '../common/utils';

@Component({
  selector: 'pushdr-home-address-search',
  templateUrl: './home-address-search.component.html',
})
export class HomeAddressSearchComponent {
  postCode = '';
  address: HomeAddress;
  selectedResult = null;
  results: SelectListAddress[] = [];
  submitted = false;
  @Output() addressSelected: EventEmitter<any> = new EventEmitter();
  @Input() buttonText = 'Next';

  searchPostcode(response) {
    this.results = prepareAddressesForNgSelect(response);
  }

  clearSelection() {
    this.address = null;
    this.addressSelected.emit(null);
  }

  selectAddress(event) {
    if (!event) {
      this.clearSelection();
    } else {
      this.address = Object.assign({}, event.original, { IsDefault: true });
    }
  }

  submit() {
    this.addressSelected.emit(this.address);
  }
}

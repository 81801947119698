<div class="c-modal c-modal--is-visible">
  <div class="c-modal__wrap c-modal__wrap--40">
    <button type="button" class="absolute top-0 right-0 p-2" (click)="done(null)">
      <i class="text-3xl material-icons">cancel</i>
    </button>
    <h3>Let’s find your new GP surgery</h3>
    <div class="modal__body">
      <pushdr-select-surgery
        class="max-h-[600px]"
        (pdrSelected)="confirmSelectedSurgery($event)"></pushdr-select-surgery>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { Observable, of } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { LinkedAccount } from '@pushdr/common/types';

@Injectable({
  providedIn: 'root',
})
export class LinkedAccountsApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/linkedCustomer';
  }

  getLinkedAccounts(): Observable<LinkedAccount[]> {
    if (!this.headerService.hasHeaders()) {
      return of([]);
    }
    return this.get<{ LinkedCustomerList: LinkedAccount[] }>(
      ``,
      null,
      this.headerService.headers()
    ).pipe(pluck('LinkedCustomerList'));
  }
}

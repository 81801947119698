import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppIntegrationService {
  app = false;
  ios = false;
  android = false;

  constructor(private storage: StorageService) {}

  initialise() {
    const appCookie = this.storage.get('app');
    if (appCookie === 'pdr_ios') {
      this.app = true;
      this.ios = true;
    }
    if (appCookie === 'pdr_android') {
      this.app = true;
      this.android = true;
    }
  }
}

<h3>Mocking API Responses</h3>

<div class="col">
  <button
    *ngIf="accountProfilePrivate as item"
    (click)="item.toggle()"
    [class.active]="item.enabled()">
    <div></div>
    <span>Account Profiles as Private</span>
  </button>

  <button *ngIf="gpPrivate as item" (click)="item.toggle()" [class.active]="item.enabled()">
    <div></div>
    <span>GP as Private</span>
  </button>
</div>

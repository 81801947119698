import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { ManualGPSearch, GPSearchResult, CustomerGPDetails } from '@pushdr/common/types';

@Injectable({
  providedIn: 'root',
})
export class GeneralPracticeService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/generalpractice';
  }

  //GET /api/v1/generalpractice/postcode

  search(postcode = '') {
    return this.get('/postcode', { postcode });
  }

  searchExistingGp(manualGp: ManualGPSearch) {
    return this.post<GPSearchResult[]>(
      '/searchForExistingGp',
      manualGp,
      this.headerService.bearerTokenHeaders()
    );
  }

  //GET /api/v2/generalpractice/{odsCode}

  getSurgery(odsCode = '') {
    return this.get<CustomerGPDetails>(
      '/' + odsCode,
      null,
      this.headerService.bearerTokenHeaders(),
      2
    );
  }

  //GET /api/v1/generalpractice/search?searchTerm={searchTerm}

  searchSurgery(searchTerm = '') {
    const params = { searchTerm };
    return this.get('/search', params, this.headerService.bearerTokenHeaders(), 2);
  }
}

<pushdr-alert-bar *ngIf="isIE10" type="warning" dismissable="true" top="true">
  <strong>Improve your experience!</strong>
  You are using an outdated browser; please upgrade to a newer browser for a better experience on
  PushDoctor. Here are the instructions on how to
  <strong>
    <a href="http://browsehappy.com/" target="_blank"> upgrade your web browser </a>
  </strong>
  .
</pushdr-alert-bar>

<pushdr-header type="pdr_default">
  <pushdr-account-nav-list class="u-display-none@lg"></pushdr-account-nav-list>
</pushdr-header>
<div
  class="o-wrapper o-container o-container--fluid"
  style="min-height: 600px; padding-bottom: 100px">
  <div class="o-row o-row--no-margin-bottom">
    <div class="col-lg-3 u-display-none@xs u-display-inline-block@lg" *ngIf="inAccount">
      <div class="c-header__meganav c-header__meganav--sidebar">
        <pushdr-account-nav-list></pushdr-account-nav-list>
      </div>
    </div>
    <div class="col-xs-12" [ngClass]="{ 'col-lg-9': inAccount }">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<pushdr-footer *ngIf="inAccount" [type]="getFooterType()"></pushdr-footer>

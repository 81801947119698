<div class="flex gap-1" (click)="opened = !opened">
  <div class="grow">
    <h2 class="font-normal text-base text-[#57575c] mb-0">
      {{ appointment.StartUtc | isoDate: 'dddd Do MMMM' }}
    </h2>
    <p>{{ appointment.StartUtc | date: 'hh:mma' }}</p>
    <p class="font-light text-base text-[#57575c] mb-3">
      {{ appointment.ClinicianType | clinicianTypeName }}
    </p>
    <p class="mt-6">
      <i>
        {{ AppointmentStatus[appointment.Status] }}
      </i>
    </p>
  </div>
  <div class="flex justify-center items-center">
    <svg
      class="fill-gray-200"
      width="14px"
      height="14px"
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      [class.-rotate-90]="!opened">
      <path
        d="m24 6.5556c0 0.46667-0.15 0.77778-0.45 1.0889l-10.5 10.889c-0.6 0.62222-1.5 0.62222-2.1 0l-10.5-10.889c-0.6-0.62222-0.6-1.5556 0-2.1778 0.6-0.62222 1.5-0.62222 2.1 0l9.45 9.8 9.45-9.8c0.6-0.62222 1.5-0.62222 2.1 0 0.3 0.31111 0.45 0.62222 0.45 1.0889z" />
    </svg>
  </div>
</div>

<ng-container *ngIf="opened">
  <div *ngIf="appointmentDetails$ | async as details; else loading">
    <div class="flex">
      <div class="w-1/2" *ngIf="details.Id">
        <h5 class="font-medium mb-2 text-[#57575c]">Reference number</h5>
        <p class="m-0">{{ details.Id }}</p>
      </div>
      <div class="w-1/2" *ngIf="details.Clinician?.Id">
        <h5 class="font-medium mb-2 text-[#57575c]">Clinician</h5>
        <p class="m-0">
          {{ details.Clinician.Title }} {{ details.Clinician.FirstName }}
          {{ details.Clinician.LastName }}
        </p>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <h3 class="font-medium">Loading...</h3>
  </ng-template>
</ng-container>

import { Component, OnInit } from '@angular/core';

import { AbstractModal } from '@pushdr/common/overlay';
import { EnvironmentProxyService } from '@pushdr/environment';
import { ReminderModalData } from '../models';

@Component({
  selector: 'pushdr-reminder-modal',
  templateUrl: './reminder-modal.component.html',
  styleUrls: ['./reminder-modal.component.scss'],
})
export class ReminderModalComponent
  extends AbstractModal<ReminderModalData, boolean>
  implements OnInit
{
  orderId = 0;
  dateTime = new Date();

  get leaveFeedbackUrl(): string {
    return this.envProxy.environment.patient.account.url + `/feedback/${this.orderId}`;
  }

  constructor(private envProxy: EnvironmentProxyService) {
    super();
  }

  ngOnInit(): void {
    this.orderId = this.data.orderId ?? 0;
    this.dateTime = this.data.appointmentDateTime?.toDate() ?? new Date();
  }
}

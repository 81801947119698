<header class="c-header">
  <ng-container *ngIf="!isApp">
    <ng-container [ngSwitch]="type">
      <pushdr-pdr-header-default
        *ngSwitchCase="'pdr_default'"
        [homeUrl]="homeUrl"
        [accountUrl]="accountUrl"
        [loggedIn]="loggedIn"
        (signedOut)="signOut()"
        [inAccount]="inAccount">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </pushdr-pdr-header-default>
      <pushdr-header-funnel
        *ngSwitchCase="'common_funnel'"
        [homeUrl]="homeUrl"
        [accountUrl]="accountUrl"
        [loggedIn]="loggedIn"
        (signedOut)="signOut()"
        [inAccount]="inAccount">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </pushdr-header-funnel>
      <pushdr-header-funnel
        *ngSwitchCase="'common_postsurgery'"
        [homeUrl]="homeUrl"
        [accountUrl]="accountUrl"
        [loggedIn]="loggedIn"
        (signedOut)="signOut()"
        [inAccount]="inAccount">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </pushdr-header-funnel>
    </ng-container>
  </ng-container>
</header>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>

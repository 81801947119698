import { Injectable } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { GP, GPShareStatus, GPPartnershipType } from '@pushdr/common/types';
import { take } from 'rxjs/operators';

@Injectable()
export class EditSurgeryService {
  constructor(private modal: ModalService) {}

  patientHasSurgery(gp: GP) {
    return (
      gp &&
      gp.ShareStatus !== GPShareStatus.NO_NHS_GP &&
      !this.patientSurgeryPendingVerification(gp)
    );
  }

  patientDoesNotHaveSurgery(gp: GP) {
    return (
      !gp ||
      gp.ShareStatus === GPShareStatus.NO_NHS_GP ||
      this.patientSurgeryPendingVerification(gp)
    );
  }

  patientSurgeryPendingVerification(gp: GP) {
    return gp.ShareStatus === GPShareStatus.VERIFYING;
  }

  defaultShareStatusForGP(gp: GP) {
    return gp.PartnershipType === GPPartnershipType.NOT_A_PARTNER
      ? GPShareStatus.TBC
      : GPShareStatus.DO_NOT_SHARE;
  }

  showModalPresentingShareOptions(gp: GP) {
    if (this.patientDoesNotHaveSurgery(gp)) {
      return this.showNoSurgeryNoShareModal();
    } else {
      return this.showSharingOptionsForCurrentSurgery(gp);
    }
  }

  showModalPresentingUnShareOptions(gp: GP) {
    return this.showConfirmUnSharingOptionsForCurrentSurgery(gp);
  }

  showNoSurgeryNoShareModal() {
    return new Promise<GPShareStatus>((res, rej) => {
      // TODO: btn should be === 'I understand'
      this.modal
        .acknowledge('', 'You must select your GP surgery to share your records')
        .pipe(take(1))
        .subscribe(() => {
          this.modal.close();
          rej('You must select your GP surgery to share your records');
        });
    });
  }

  showFreeConsultationsModal() {
    return new Promise<void>((res, rej) =>
      this.modal
        .acknowledge(
          `You’re eligible for free appointments!`,
          `Great! Your surgery has partnered with Push Doctor to provide online appointments. This means you can use the service for free. Please check your email for details`
        )
        .pipe(take(1))
        .subscribe(() => res())
    );
  }

  showManualAddressSubmitted() {
    return new Promise<void>((res, rej) => {
      this.modal
        .acknowledge(
          'Surgery submitted',
          'Thanks for manually submitting your surgery. Our team will now work to find your surgery and will add it to your account once successful.'
        )
        .pipe(take(1))
        .subscribe(() => res());
    });
  }

  showSharingOptionsForCurrentSurgery(gp: GP = null) {
    return new Promise<GPShareStatus>((res, rej) => {
      const isSharing = gp ? gp.ShareStatus === GPShareStatus.DO_SHARE : false;
      const shareButtonLabel = isSharing ? `Keep sharing` : `Share my records`;
      const cancelButtonLabel = isSharing ? `Turn off sharing` : `Do not share my records`;
      this.modal
        .confirm(
          'Sharing with your GP',
          'Sharing enables your GP to access all records held by Push Doctor',
          shareButtonLabel,
          cancelButtonLabel
        )
        .pipe(take(1))
        .subscribe(isConfirmed => {
          if (isConfirmed) {
            res(GPShareStatus.DO_SHARE);
          } else {
            res(GPShareStatus.DO_NOT_SHARE);
          }
        });
    });
  }

  showConfirmUnSharingOptionsForCurrentSurgery(gp: GP = null) {
    return new Promise<GPShareStatus>((res, rej) => {
      // TODO: ideally confirm text should have line breaks.
      this.modal
        .confirm(
          'Are you sure?',
          `If you choose not to share, you won't be able to share your medical records with your NHS GP. If you change your mind, you can turn on sharing from your account area.`,
          `Do not share`,
          `Cancel`
        )
        .pipe(take(1))
        .subscribe(isConfirmed => {
          if (isConfirmed) {
            res(GPShareStatus.DO_NOT_SHARE);
          } else {
            res(null);
          }
        });
    });
  }

  showConfirmSharingOptionsForCurrentSurgery(gp: GP = null) {
    return new Promise<GPShareStatus>((res, rej) => {
      this.modal
        .confirm(
          'Sharing with your GP',
          'Sharing enables your GP to access all records held by Push Doctor',
          `Share all records`,
          `Cancel`
        )
        .pipe(take(1))
        .subscribe(isConfirmed => {
          if (isConfirmed) {
            res(GPShareStatus.DO_SHARE);
          } else {
            res(null);
          }
        });
    });
  }
}

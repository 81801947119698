import { of } from 'rxjs';
import { ClinicianType } from '../partner/partner';
// expand these Stubs as required

export const ModalServiceStub = {
  showLoader: () => of({}),
  confirm: () => of({}),
  success: () => of({}),
  acknowledge: () => of({}),
  close: () => of({}),
  error: () => of({}),
};

let store = {};
export const storageServiceStub = {
  deleteSessionStorage: () => {
    store = {};
  },
  setLocalStorage: (key: string, value: any) => {
    store[key] = JSON.stringify(value);
  },
  getLocalStorage: () => ({}),
  getSessionStorage: (key: string, returnAsJSON = false): any => {
    return returnAsJSON
      ? JSON.parse(key in store ? store[key] : null)
      : key in store
      ? store[key]
      : null;
  },
  setSessionStorage: (key: string, value: any) => {
    store[key] = JSON.stringify(value);
  },
  set: () => {},
  get: () => {},
  delete: () => {},
};
export const scriptInjectorServiceStub = {
  removeMultipleScripts: () => ({}),
  injectMultipleScripts: () => ({ subscribe: () => ({}) }),
};
export const webrtcDevicesetupServiceStub = {
  detachAllStreams: () => ({}),
  getUserMediaStream: () =>
    of({
      permissions: {
        videoEnabled: true,
      },
    }),
};
export const tokenServiceStub = {
  delete: () => ({}),
  get: () => '',
  set: () => '',
  exists: () => true,
};
export const insightServiceStub = {
  initialise: (appIdentifier: string) => {},
  logPageView: (name?: string, uri?: string) => {},
  setUserId: (userId: string) => {},
  clearUserId: () => {},
};
export const patientApiServiceStub = {
  booking: {
    getAppointmentSummary: () => of([{}]),
    canConsult: () => of({ intReason: 0, intResult: 0, strText: '' }),
  },
  account: {
    uploadIdentityDocument: () => of({}),
    getIdentificationWarningText: () => of([]),
    getGP: () => of({}),
    getDetails: () => ({}),
    updateGP: () => of({}),
  },
  consultation: {
    enterWaitingRoom: () => ({ subscribe: () => ({}) }),
  },
};

export const customerAddressFactory = (isDefault = true) => {
  return [
    {
      Id: '9|0|M26 3XY',
      Provider: 0,
      AddressLine1: '',
      AddressLine2: '11',
      AddressLine3: '',
      AddressLine4: 'STREET ROAD',
      AddressLine5: '',
      PostTown: 'MANCHESTER',
      County: '',
      PostCode: 'M00 0AA',
      StartDate: null,
      EndDate: null,
      isDefault,
    },
  ];
};

export const completedPrivateCustomer = () => {
  return {
    ArmyEnlistDate: null,
    EligiblePostcode: null,
    Email: 'name.here@pushdr.com',
    Mobile: '07000000000',
    FirstName: 'registered',
    LastName: 'customer',
    DOB: '1982-10-19T00:00:00.0000000',
    Gender: 1,
    Status: 2,
    PreviousNames: [],
    Addresses: customerAddressFactory(),
    GpShare: 2,
    AccountProfiles: [],
  };
};

export const registeredCustomerFactory = () => {
  return {
    ArmyEnlistDate: null,
    EligiblePostcode: null,
    Email: 'newuser2019@pushdr.com',
    Mobile: '+447000000111',
    FirstName: null,
    LastName: null,
    DOB: '0001-01-01T00:00:00.0000000',
    Gender: 3,
    Status: 1,
    PreviousNames: [],
    Addresses: [],
    GpShare: 4,
    AccountProfiles: [],
  };
};

export const profileCompleteCustomerFactory = () => {
  return {
    ArmyEnlistDate: null,
    EligiblePostcode: null,
    Email: 'kevin.jones+new@pushdr.com',
    Mobile: '07000000111',
    FirstName: 'Testing',
    LastName: 'User',
    DOB: '2000-01-01T00:00:00.0000000',
    Gender: 1,
    Status: 1,
    PreviousNames: [],
    Addresses: customerAddressFactory(),
    GpShare: 4,
    AccountProfiles: [],
  };
};

export const consultationStatusFactory = (
  ProfileComplete = false,
  CustomerStatus: 'AwaitingRegistration' | 'Registered' = 'AwaitingRegistration'
) => {
  return {
    CanConsult: false,
    ProfileComplete,
    KycCheckStatus: 'Failed',
    IdCheckStatus: 'Failed',
    CustomerStatus,
    ProfileChangeInProgress: false,
  };
};

export const apiNHSPatientServiceStub = {
  actionRequest: { getActionRequest: () => {} },
  account: { getMyAddress: () => of(''), findGPPractices: () => of('') },
  booking: {
    bookSpecificAppointment: () => of({}),
    confirmAppointment: () => of([{}]),
    applyDiscountCode: () =>
      of({
        DiscountAmount: 1399,
        DiscountCode: 'SUMMER35PERCENTAGE',
        DiscountFormatted: '35%',
        FullPrice: 3999,
        OrderId: 100011366,
        Price: 259,
      }),
  },
  authentication: {
    invalidateToken: () => of(''),
    login: () => of({}),
    generateToken: () => of({}),
  },
  customer: {
    getCustomer: () => of({}),
    consultationStatus: () => of({}),
    getGPDetails: () => of({}),
    setSurgery: () => of({}),
  },
  validation: { canRegisterEmail: () => ({}), dob: () => of({}) },
  errorMessages: {
    validation: { canRegisterEmail: () => ({}), dob: () => ({}) },
    customer: { commonCustomerErrors: () => ({}) },
  },
  childAccount: {
    getChildAccounts: () => of([]),
    createChildAccount: (params?) => of({}),
    getOneChild: () =>
      of({
        Id: 123456789,
        FirstName: 'John',
        LastName: 'Doe',
        NhsNumber: '4505577104',
        Dob: '2009-08-10T00:00:00.0000000',
        Gender: 1,
        ExternalId: 'C133BB47-D909-4E3D-B1EC-6770E4F7B296',
      }),
  },
  triage: { shouldTriage: () => of({ TriageSetting: 1 }) },
  gppractices: {
    search: () => of([]),
    searchSurgery: () => of([]),
  },
  partners: {
    getMarketplacePartners: () => of([]),
  },
};
export const apiPartnerPortalServiceStub = {
  availability: {
    getPercentageAvailability: () => of([]),
    getAvailableAppointmentSlots: () => of({ availability: [] }),
    getAvailableAppointmentSlotsOverNext: () => of({ availability: [] }),
  },
  partner: {
    addPartner: () => of({}),
    getPartner: () => of({}),
    getPartnerLinks: () => of({}),
    getPartners: () => of({}),
    deletePartner: () => of({}),
    updatePartner: () => of(''),
    addCorporatePartner: () => of({}),
    addNHSPartner: () => of({}),
    updateNHSPartner: () => of(''),
    clearCustomers: () => of(''),
    setPartnerLive: () => of(''),
    getPartnerPromotions: () => of([]),
    addPartnerPromotion: () => of(''),
    editPartnerPromotion: () => of(''),
    enablePartnerSignpost: () => of(''),
    disablePartnerSignpost: () => of(''),
    getPartnerSpecificSignposts: () => of(''),
    createPartnerQuestionnaireEmailAction: () => of(true),
    updatePartnerQuestionnaireEmailAction: () => of(true),
    createPartnerQuestionnaireAvailabilityAction: () => of({}),
    updatePartnerQuestionnaireAvailabilityAction: () => of({}),
    getRelations: () => of([]),
    createRelation: () => of({}),
    deleteRelation: () => of({}),
    updateProperty: () => of({}),
  },
  organisation: {
    getAll: () => of([]),
    get: () => of([]),
    create: () => of([]),
    edit: () => of([]),
  },
  allowance: {
    getAllowance: () => of({}),
    putAllowance: () => of({}),
    createAllowance: () => of({}),
    allowanceAddNhsPartner: () => of({}),
    getAllowances: () => of({}),
  },
  promotions: {
    getPromotions: () => of([]),
  },
  enums: {
    getEnumPartnerTypes: () => of({}),
    getEnumApplicationTypes: () => of([]),
  },
  invite: {
    sendInvite: () => of(''),
    sendInviteForSlot: () => of(''),
    getInvites: () => of([]),
    getActiveInviteTypes: () => of([]),
  },
  info: {
    getPartnerInfo: () => of({}),
    getServiceHours: () => of([]),
  },
  booking: {
    getBookings: () => of([]),
    getTotalPartnerBookingCountsToday: () => of([]),
    getTotalPartnerBookingCountsTomorrowOnwards: () => of([]),
  },
  common: {
    getQuestionnaires: () => of([]),
    getQuestionnairesIdToNameMap: () => of([]),
    getDefaultSignposts: () => of([]),
    getDefaultSignpost: () => of([]),
    createDefaultSignpost: () => of([]),
    updateDefaultSignpost: () => of([]),
    deleteDefaultSignpostById: () => of([]),
    getPartnerSignpostConfiguration: () => of([]),
    getPartnerQuestionnaireConfiguration: () => of([]),
  },
};
export const signInPartnerPortalServiceStub = {
  currentUser: { id: 99 },
  user$: of({ partnerType: 1 }),
  userList$: of([{ partnerType: 1 }]),
  signIn: () => of(true),
  signOut: () => {},
  getUserAccountInfo: () => of(true),
  getUserName: () => '',
  setCurrentUserName: () => {},
  redirectBasedOnUserType: () => Promise.resolve(true),
};

export const locationStub = {
  go: () => '',
  back: () => '',
  path: () => '',
};
export const featureToggleServiceStub = {
  get: () => {},
  isActive: () => true,
  track: () => ({}),
  configuration: () => ({}),
};
export const signOutServiceStub = {
  flushUser: () => of(''),
  signout: () => of(''),
};
export const customerAcceptancesServicesStub = {
  getAcceptancesAsNewUser: () =>
    of([
      {
        Type: 1,
      },
      {
        Type: 2,
      },
      {
        Type: 3,
      },
    ]),
  getAcceptanceHTML: () => new Promise(() => ''),
};
export const appIntegrationServiceStub = {
  app: false,
};
export const accountProfileServiceStub = {
  resolve: () => Promise.resolve({}),
  getLinkedAccounts: () => of([{ Name: 'Test', CustomerType: 2, Id: 1 }]),
};
export const systemCheckServiceStub = {
  isCompatible: true,
  isBrowserCompatible: true,
  isWindows: true,
  browser: '',
  OS: '',
  userAgent: '',
};
export const documentStub = {
  location: {
    href: 'test',
  },
  hasFocus: () => {},
  querySelectorAll: () => {},
};
export const analyticsBusServiceStub = {
  trackEvent: () => {},
};
export const networkSpeedServiceStub = {
  start: () => of({ speed: 0, ping: 0 }),
};

const mockNotification = function () {
  return this;
};
mockNotification.permission = 'default';
mockNotification.requestPermission = () => new Promise(() => mockNotification.permission);

export const windowStub = {
  scrollTo: () => {},
  location: {
    href: '',
    pathname: '',
    origin: '',
    reload: () => {},
  },
  open: () => {},
  Notification: mockNotification,
  AudioContext: function () {
    this.createAnalyser = function () {
      this.fftSize = 0;
      this.frequencyBinCount = 0;
      this.getByteTimeDomainData = () => {};
      return this;
    };
    this.createMediaStreamSource = function () {
      this.connect = () => {};
      return this;
    };
    return this;
  },
  webkitAudioContext: function () {
    return this;
  },
  confirm: () => {},
  console: {
    log: () => {},
  },
  sessionStorage: {
    setItem: () => {},
    removeItem: () => {},
  },
  URL: {
    createObjectURL: () => {},
  },
  gapi: {
    auth2: {
      getAuthInstance: function () {
        return Promise.resolve({
          grantOfflineAccess: a => {
            return { code: 'fake-token' };
          },
        });
      },
    },
  },
  FB: {
    login: res => ({ authResponse: true }),
  },
  history: {
    replaceState() {
      return true;
    },
  },
};
export const headerServiceStub = {
  menuIsOpen: false,
  toggleBurgerMenu: () => ({}),
  closeHeaderMenu: () => ({}),
  openHeaderMenu: () => ({}),
};
export const seoServiceStub = {
  unblockRobots: () => ({}),
  setTitle: () => ({}),
  resetTitle: () => ({}),
  blockRobots: () => ({}),
};
export const browserDetectorServiceStub = {
  isIE10: () => ({}),
};

export const signpostingQuestionnaireMock = {
  id: '84ac6a41-896f-47b1-82d1-5b02f172fa5c',
  firstPageId: '065a2a39-fd7f-49a4-aa47-e33e0b00efdf',
  pages: [
    {
      id: '065a2a39-fd7f-49a4-aa47-e33e0b00efdf',
      title: 'Child Consultation',
      header: 'What you will need for a child consulation',
      content:
        "<ul><li>Provide  <strong>valid ID</strong>  for yourself</li>  <li>Both be registered at the same  <strong>GP surgery</strong></li>  <li>Be the child's  <strong>legal parent or guardian</strong></li>  <li>Provide  <strong>valid ID</strong>  for the child, either a passport or birth certificate</li>  <li>If you are unable to supply ID, you will be asked questions on your  <strong>child's previous medical history</strong></li></ul>",
      cta: [
        {
          title: 'Confirm',
          path: null,
          type: 0,
          order: 0,
          requiresValidation: true,
          nextPageId: '4c0e84e3-bcee-47ff-b7ad-35ebdbba1e2b',
          clinicianType: ClinicianType.GeneralPractitioner,
        },
      ],
      inputs: [
        {
          id: 'c3a3cfe4-d1f6-41f1-86b8-a580a185c09a',
          type: 0,
          label:
            'I can provide proof that I am the legal guardian of the child and we are registered at the same surgery',
          validations: {
            mandatory: true,
            maxCharacterLimit: null,
            minCharacterLimit: null,
            invalidCharacters: null,
          },
          placeholder: null,
        },
      ],
    },
    {
      id: '4c0e84e3-bcee-47ff-b7ad-35ebdbba1e2b',
      title: 'Child Consultation',
      header: null,
      content:
        '<div style="background-color:#FFEBEE;padding: 32px 16px;">  <h3 style="color:#D50000;">Is it an emergency?</h3>  <p>If your child is experiencing any of the below, please call 999 or go to A&E.</p>  <h4>Unresponsive or floppy</h4>  <p>Your child is floppy and unresponsive and won\'t wake up</p>  <h4>Breathing problems</h4>  <p>Your child has problems breathing such as rapid breathing or panting, finding it hard to get their breath, is sucking their stomach in under their ribs or has stopped breathing</p>  <h4>Severe bleeding</h4>  <p>Severe bleeding that can not be stopped</p>  <h4>Fits</h4>  <p>Your child is having fits that are not stopping</p>  <h4>Swallowing something harmful</h4>  <p>Your child is unwell after swallowing something harmful such as medicine or button battery</p>  <h4>Severe burns</h4>  <p>They have severe burns anywhere on their body</p>  <h4>Severe allergic reactions</h4> <p>They are having a sudden or severe allergic reaction</p> <h4>Cold or discoloured hands and/or feet</h4> <p>Their body is warm but their hands and feet are cold or discoloured</p> <h4>Severe arm and/or leg pains</h4> <p>Their arms and/or legs are in severe pain for no obvious reason</p> <h4>Unusual skin colour</h4> <p>Their skin looks pale, blue or dusky around lips</p> <h4>Spotty, purple or red rash</h4> <p>A spotty, purple or red rash that does not fade when a glass is rolled over it</p> <h4>High temperature</h4> <p>40 C or higher that is not improving with paracetamol and/or Ibuprofen or very low temperature.</p> <h4>Serious injuries</h4> <p>If you think someone may have seriously injured your child</p> </div>',
      cta: [
        {
          title: "I don't have an emergency",
          path: '/booking/availability',
          type: 0,
          order: 0,
          requiresValidation: false,
          nextPageId: null,
          clinicianType: ClinicianType.GeneralPractitioner,
        },
      ],
      inputs: [],
    },
  ],
};

import { Pipe, PipeTransform } from '@angular/core';
import { ClinicianType } from '@pushdr/common/types';

@Pipe({
  name: 'clinicianTypeName',
  standalone: true,
})
export class ClinicianTypeNamePipe implements PipeTransform {
  transform(value: ClinicianType): string {
    return clinicianTypeTitleMap[value] ?? 'N/D';
  }
}

const clinicianTypeTitleMap = {
  [ClinicianType.Unknown]: 'Unknown Type',
  [ClinicianType.GeneralPractitioner]: 'General Practitioner',
  [ClinicianType.MinorAilmentsPharmacist]: 'Minor Ailments Pharmacist',
  [ClinicianType.Physio]: 'Physiotherapist',
  [ClinicianType.MentalHealthTherapist]: 'Mental Health Therapist',
};

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
  PasswordStrengthConditions,
  PasswordStrengthConditionsNotMet,
  PDServerError,
} from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import { Observable } from 'rxjs';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';

interface PasswordFormValues {
  password: string;
  oldPassword: string;
  confirmPassword: string;
}

@Component({
  selector: 'pushdr-edit-password-form',
  templateUrl: 'edit-password-form.component.html',
})
export class EditPasswordFormComponent implements OnInit {
  rules$: Observable<PasswordStrengthConditions>;
  errors = [];
  form: UntypedFormGroup;
  @Output() saved: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private apiNhsPatient: ApiNHSPatientService,
    private apiPatientService: ApiPatientService,
    private modal: ModalService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.rules$ = this.apiNhsPatient.validation.passwordConditions();
    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
      oldPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  submitForm({ value }: { value: PasswordFormValues }) {
    this.modal.showLoader();
    this.apiNhsPatient.validation.passwordStrength(value.password).subscribe(
      res => {
        this.modal.close();
        this.updatePassword(value.oldPassword, value.password);
      },
      (err: PDServerError) => {
        this.modal.close();
        this.errors = (err.original.error as PasswordStrengthConditionsNotMet).conditionsNotMet;
      }
    );
  }

  updatePassword(password, newPassword) {
    this.modal.showLoader({ bottomText: 'Updating password' });
    this.apiPatientService.account.updatePassword(password, newPassword).subscribe(res => {
      this.modal.close();
      if (res && res.UpdatePasswordResult === 'Incorrect password.') {
        this.form.controls['oldPassword'].setErrors({ wrongPassword: true });
      } else {
        this.saved.emit(true);
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { tap, catchError, mapTo } from 'rxjs/operators';
import { CanConsultService } from './can-consult.service';
import { ModalService } from '@pushdr/common/overlay';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CanConsultGuard implements CanActivate {
  constructor(
    private canConsult: CanConsultService,
    private router: Router,
    private modal: ModalService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const orderId = route.queryParams.orderId;
    return this.canConsult.canConsult(orderId).pipe(catchError(err => this.canConsulModal(err)));
  }

  canConsulModal(err: Error): Observable<false> {
    const navigateTo = ['/account', 'appointments', 'upcoming'];
    return this.modal.error(err.message).pipe(
      tap(() => this.router.navigate(navigateTo, { replaceUrl: true })),
      mapTo(false)
    );
  }
}

<footer class="c-footer u-pt48@xs u-pb48@xs">
  <div class="flex justify-between px-4">
    <div class="c-footer__logo">
      <a class="logo" title="Push Doctor Logo" href="https://www.pushdoctor.co.uk">
        <span class="u-sr-only"> Push Doctor homepage </span>
      </a>
    </div>
    <div class="flex flex-col md:flex-row">
      <a
        *ngIf="(!isAndroid && !isIos) || isIos"
        aria-label="Download the Push Doctor iOS App from the Apple App store"
        class="mb-2 js-app-link md:my-auto md:mr-2"
        target="_blank"
        title="App Store"
        [href]="downloadLink">
        <img
          alt="Download the app on the Apple App Store"
          aria-hidden="true"
          class="c-footer__app-logo"
          src="./assets/images/apple_store.png" />
      </a>
      <a
        *ngIf="(!isAndroid && !isIos) || isAndroid"
        aria-label="Download the Push Doctor Android App from the Google Play store"
        class="js-app-link md:my-auto"
        target="_blank"
        title="Play Store"
        [href]="downloadLink">
        <img
          alt="Download the app on the Google Play Store"
          aria-hidden="true"
          class="c-footer__app-logo"
          src="./assets/images/google_play.png" />
      </a>
    </div>
  </div>
</footer>

import { Injectable, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PasswordStrengthConditions } from '@pushdr/common/types';
import {
  MappedPasswordConditions,
  passwordConditionMessagesMap,
} from './password-mapping/password-conditions-map';

@Injectable({ providedIn: 'root' })
export class PasswordInputsService {
  showHidePassword(passwordInput: ElementRef): void {
    const shown = passwordInput.nativeElement.getAttribute('type') === 'password-show';
    if (shown) {
      passwordInput.nativeElement.setAttribute('type', 'password');
    } else {
      passwordInput.nativeElement.setAttribute('type', 'password-show');
    }
  }

  resetPasswordField(passwordInput: ElementRef, form: UntypedFormGroup, formControlName: string) {
    form.get(formControlName).patchValue('');
    passwordInput.nativeElement.focus();
  }

  mapConditions(
    rules: PasswordStrengthConditions,
    clinicians?: boolean
  ): MappedPasswordConditions[] {
    let output: MappedPasswordConditions[] = [];
    if (rules?.conditionsToMeet) {
      output = rules.conditionsToMeet
        .reduce((rulesArray, nextRule) => {
          const nextRuleConditions = passwordConditionMessagesMap[nextRule];
          if (nextRuleConditions) {
            return [
              ...rulesArray,
              new MappedPasswordConditions(passwordConditionMessagesMap[nextRule]),
            ];
          } else {
            return rulesArray;
          }
        }, [])
        .sort((a, b) => a.displayPosition - b.displayPosition);
    }
    if (clinicians) {
      output.forEach(condition => {
        condition.innerHTML = cliniciansPasswordConditionMessagesMap[condition.dataCy];
      });
    }
    return output;
  }
}

const cliniciansPasswordConditionMessagesMap = {
  'password-tip-unallowed-chars': "We don't accept < or >, please choose another",
  'password-tip-length':
    '10 characters <span class="check-icon"><i class="material-icons-outlined">done</i></span>',
  'password-tip-uppercase':
    'One uppercase letter <span class="check-icon"><i class="material-icons-outlined">done</i></span>',
  'password-tip-lowercase':
    'One lowercase letter <span class="check-icon"><i class="material-icons-outlined">done</i></span>',
  'password-tip-number':
    '1 number <span class="check-icon"><i class="material-icons-outlined">done</i></span>',
  'password-tip-special-char':
    '1 symbol <span class="check-icon"><i class="material-icons-outlined">done</i></span>',
};

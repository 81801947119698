<ng-container *ngIf="gpDetails$ | async as gpDetails; else loading">
  <account-content-wrapper>
    <h3 class="u-mb24@xs">Medical</h3>
    <ng-container *ngIf="gpDetails?.Id; else noGpDetails">
      <div class="border-y -mb-px border-bluegrey-400 p-4">
        <h2 class="font-medium text-base text-[#57575c] mb-2">Name of surgery</h2>
        <div class="flex justify-between">
          <p class="m-0">{{ gpDetails.Name }}</p>
          <button type="button" (click)="updateGpSurgery()">
            <i class="material-icons">edit</i>
          </button>
        </div>
      </div>
      <div *ngIf="gpDetails.Telephone" class="border-y -mb-px border-bluegrey-400 p-4">
        <h2 class="font-medium text-base text-[#57575c] mb-2">Surgery phone number</h2>
        <p class="m-0">{{ gpDetails.Telephone }}</p>
      </div>
      <div *ngIf="gpDetails.Address" class="border-y -mb-px border-bluegrey-400 p-4">
        <h2 class="font-medium text-base text-[#57575c] mb-2">Surgery Address</h2>
        <p class="m-0">
          <ng-container
            *ngTemplateOutlet="gpAddress; context: { $implicit: gpDetails.Address }"></ng-container>
        </p>
      </div>
      <div
        *ngIf="isPrivateLike"
        class="flex border-y -mb-px border-bluegrey-400 p-4 cursor-pointer hover:bg-[#f8f8f8] hover:ease-out transition ease-in duration-300 delay-[0ms]"
        (click)="goToRequestDetailsComponent('electronic')">
        <div class="grow">
          <h2 class="font-medium text-base text-[#57575c] mb-2">
            Request your records electronically
          </h2>
          <p class="m-0">Receive a digital copy of your records</p>
        </div>
        <div class="flex justify-center items-center">
          <svg
            class="fill-gray-200 -rotate-90"
            width="14px"
            height="14px"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="m24 6.5556c0 0.46667-0.15 0.77778-0.45 1.0889l-10.5 10.889c-0.6 0.62222-1.5 0.62222-2.1 0l-10.5-10.889c-0.6-0.62222-0.6-1.5556 0-2.1778 0.6-0.62222 1.5-0.62222 2.1 0l9.45 9.8 9.45-9.8c0.6-0.62222 1.5-0.62222 2.1 0 0.3 0.31111 0.45 0.62222 0.45 1.0889z" />
          </svg>
        </div>
      </div>
      <p class="p-4 m-0" *ngIf="!isPrivateLike">
        Your Push Doctor medical records are shared with your GP surgery
      </p>
    </ng-container>
    <ng-template #noGpDetails>
      <button class="c-btn c-btn--primary">Select your gp surgery</button>
    </ng-template>
  </account-content-wrapper>
</ng-container>

<ng-template #gpAddress let-address>
  <span *ngIf="address.Line1">{{ address.Line1 | titlecase }} <br /></span>
  <span *ngIf="address.Line2">{{ address.Line2 | titlecase }} <br /></span>
  <span *ngIf="address.Line3">{{ address.Line3 | titlecase }} <br /> </span>
  <span *ngIf="address.Line4">{{ address.Line4 | titlecase }} <br /></span>
  <span *ngIf="address.Line5">{{ address.Line5 | titlecase }} <br /></span>
  <span *ngIf="address.Postcode">{{ address.Postcode | uppercase }}</span>
</ng-template>

<!-- Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>

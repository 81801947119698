import { ErrorMessageAdaptor } from './utils';
import { PDServerError } from '@pushdr/common/types';

export class ErrorMessageCore {
  constructor() {}

  commonErrors(error: PDServerError): PDServerError {
    switch (error.status) {
      case 0:
        return ErrorMessageAdaptor(
          error,
          'You are not connected to the service, this might be a network issue or our service might be down',
          'Error'
        );
      // TBD - this would override the handler in app.component which is global to redirect back to login
      // case 401:
      // case 403:
      // 	return ErrorMessageAdaptor(
      //  error,
      // 		'Your session has expired, you need to login again.',
      // 		'Unauthorised'
      // 	);
      case 404:
        return ErrorMessageAdaptor(
          error,
          'Sorry, we cannot find what we are looking for! If the problem persists contact customer services',
          'Oops'
        );
      case 500:
      case 501:
      case 502:
        return ErrorMessageAdaptor(error, 'Sorry something unexpected occured on the server.');
      default:
        return ErrorMessageAdaptor(
          error,
          'Something went wrong, please try again. If the problem persists contact customer services.'
        );
    }
  }
}

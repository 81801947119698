import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { filter, mergeMap, timeInterval } from 'rxjs/operators';
import {
  ApiNHSPatientService,
  AppointmentSummaryV2,
} from '@pushdr/patientapp/common/data-access/patient-api';

@Component({
  selector: 'pushdr-appointment-history-page',
  templateUrl: './appointment-history-page.component.html',
})
export class AppointmentHistoryPageComponent implements OnInit {
  appointmentSummary$: Observable<AppointmentSummaryV2[]>;

  constructor(
    private api: ApiNHSPatientService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.appointmentSummary$ = this.createAppointmentSummarySharedStream();
  }

  createAppointmentSummarySharedStream() {
    return timer(0, 60000).pipe(
      timeInterval(),
      mergeMap(() => this.api.booking.getBookedAppointments()),
      filter(res => !!res)
    );
  }

  showAppointmentDetails(summary: AppointmentSummaryV2) {
    this.router.navigate([summary.Id], {
      relativeTo: this.route,
    });
  }

  trackByAppointmentId(index: number, appointment: AppointmentSummaryV2) {
    return appointment.Id;
  }
}

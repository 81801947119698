import { Directive, Input, OnInit, ElementRef, Renderer2, NgModule } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[pdFocus]' })
export class FocusOnElementDirective implements OnInit {
  @Input('pdFocus') isFocused: boolean;

  constructor(private hostElement: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.focus();
  }

  focus() {
    if (this.isFocused) {
      this.hostElement.nativeElement.setAttribute('tabindex', 0);
      setTimeout(() => this.hostElement.nativeElement.focus(), 300);
    }
  }
}

@NgModule({
  declarations: [FocusOnElementDirective],
  exports: [FocusOnElementDirective],
})
export class FocusOnElementModule {}

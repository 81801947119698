import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import {
  BookSpecificAppointmentResult,
  GetAppointmentDetailResult,
  GetAppointmentSummaryResult,
  GetBookingConfirmationResult,
} from '@pushdr/common/types';
import { DATETIME_FORMAT } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiBookingPatientService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.api + '/booking.svc/bookingW/';
  }

  canConsult() {
    return this.post('CanConsult', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('CanConsultResult')
    );
  }

  bookSpecificAppointment(date: string) {
    const body = { DateTimeString: date.replace(/\//gi, '-') };
    return this.post<BookSpecificAppointmentResult>(
      'BookSpecificAppointment',
      body,
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.BookSpecificAppointmentResult));
  }

  getAppointmentSummary() {
    return this.post<GetAppointmentSummaryResult>(
      'GetAppointmentSummary',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.GetAppointmentSummaryResult));
  }

  getAppointmentDetail(appointmentNumber: string) {
    const body = { strAppointmentNumber: appointmentNumber };
    return this.post<GetAppointmentDetailResult>(
      'GetAppointmentDetail',
      body,
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.GetAppointmentDetailResult));
  }
}

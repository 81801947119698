import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgIfContext } from '@angular/common';
import { AccountProfileService } from './account-profile.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[pdIfPrivate]',
})
export class ShowForPrivateDirective {
  private _context: NgIfContext = new NgIfContext();
  private _thenTemplateRef: TemplateRef<NgIfContext> | null = null;
  private _thenViewRef: EmbeddedViewRef<NgIfContext> | null = null;

  constructor(
    private _viewContainer: ViewContainerRef,
    private profile: AccountProfileService,
    templateRef: TemplateRef<NgIfContext>
  ) {
    this._thenTemplateRef = templateRef;
  }

  @Input()
  set pdIfPrivate(condition) {
    const useCondition = typeof condition !== 'undefined' && condition !== null;
    if (useCondition)
      this._context.$implicit = this._context.ngIf = condition && this.profile.isPrivate;
    else this._context.$implicit = this._context.ngIf = this.profile.isPrivate;
    this._updateView();
  }

  private _updateView() {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(
            this._thenTemplateRef,
            this._context
          );
        }
      }
    } else {
      this._viewContainer.clear();
      this._thenViewRef = null;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { finalize } from 'rxjs/operators';
import { ModalService } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-upload-id-page',
  templateUrl: './upload-id-page.component.html',
  styleUrls: ['./upload-id-page.component.scss'],
})
export class UploadIdPageComponent implements OnInit {
  uploadInProgress: boolean;

  constructor(
    private api: ApiPatientService,
    private modal: ModalService,
    private router: Router
  ) {}

  ngOnInit() {
    this.uploadInProgress = false;
  }

  uploadID($event: { fileToUpload: File | ArrayBuffer | string | null; reason: string }) {
    this.uploadInProgress = true;
    this.api.account
      .uploadIdentityDocument($event.fileToUpload as string, $event.reason)
      .pipe(
        finalize(() => {
          this.uploadInProgress = false;
        })
      )
      .subscribe(
        response => {
          this.router.navigate(['account', 'about']);
          this.modal.acknowledge(
            '',
            'Document uploaded, we will verify your details within the next 12 hours. Please note, you will not be able to enter a consultation while your request is pending.',
            'Ok'
          );
        },
        error => {
          this.modal.error(error.message);
        }
      );
  }
}

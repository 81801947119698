import { Component, OnInit } from '@angular/core';
import { StorageService } from '@pushdr/common/utils';

@Component({
  templateUrl: './mocks.component.html',
  styleUrls: ['./mocks.component.scss'],
})
export class MocksComponent implements OnInit {
  mocks;

  constructor(private storage: StorageService) {}

  ngOnInit() {
    this.mocks = this.storage.getLocalStorage('ApiMocks', true);
    if (!(this.mocks instanceof Object)) this.mocks = {};
  }

  private saveMocks() {
    this.storage.setLocalStorage('ApiMocks', this.mocks);
  }

  get accountProfilePrivate() {
    return this.mockSwitch('/nhs/api/v1/Customer', { AccountProfiles: ['private'] });
  }

  get gpPrivate() {
    return this.mockSwitch('/patient/account.svc/accountW/GetGP', {
      GetGPResult: { PartnershipType: 0 },
    });
  }

  private mockSwitch(url: string, patch) {
    return {
      enabled: () => this.mocks[url],
      toggle: () => {
        if (this.mocks[url]) {
          delete this.mocks[url];
        } else {
          this.mocks[url] = { dataPatch: patch };
        }
        this.saveMocks();
      },
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './pd-footer.component';
import { PdrFooterDefaultComponent } from './footer-types/pdr-footer-default/pdr-footer-default.component';
import { NhsFooterDefaultComponent } from './footer-types/nhs-footer-default/nhs-footer-default.component';
import { CommonFooterFunnelComponent } from './footer-types/common-footer-funnel/common-footer-funnel.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FooterComponent,
    PdrFooterDefaultComponent,
    NhsFooterDefaultComponent,
    CommonFooterFunnelComponent,
  ],
  exports: [FooterComponent],
})
export class SharedFooterModule {}

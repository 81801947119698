import { ConsultationStatus, HealthRecordShareStatusEnum, PartnerInfo } from '@pushdr/common/types';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { Customer, CustomerGPDetails } from '@pushdr/common/types';
import { CustomerAcceptance } from '@pushdr/common/types';
import { iif, of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/Customer';
  }

  getCustomer() {
    return this.get<Customer>('', {}, this.headerService.headers()).pipe(
      map(customer => {
        if (customer.DOB === '0001-01-01T00:00:00.0000000') customer.DOB = null;
        if (customer.DOB === '1901-01-01T00:00:00.0000000') customer.DOB = null;
        return customer;
      })
    );
  }

  updateCustomer(patient: Customer) {
    const noKeyPatient = Object.assign({}, patient);
    delete noKeyPatient.Key;
    return this.put('', noKeyPatient, this.headerService.headers());
  }

  getGPDetails() {
    return this.get<CustomerGPDetails>('/gpdetails', {}, this.headerService.headers());
  }

  setSurgery(odsCode: string) {
    return this.put(`/surgery?ODSCode=${odsCode}`, {}, this.headerService.headers());
  }

  uploadImage(type: 'ID' | 'Photo', file: File) {
    return this.upload<any>(
      '/images/' + type,
      file,
      this.headerService.bearerTokenHeadersFormData()
    );
  }

  getIdChecks() {
    return this.get('/idchecks', [], this.headerService.headers());
  }

  getPartner(): Observable<PartnerInfo> {
    return this.get('/partner', [], this.headerService.headers());
  }

  getCustomerAcceptances(): Observable<CustomerAcceptance[]> {
    return iif(
      () => this.headerService.hasHeaders(),
      this.get<CustomerAcceptance[]>('/acceptances', [], this.headerService.headers()),
      of([])
    ).pipe(catchError(err => of([])));
  }

  updateCustomerAcceptances(data: CustomerAcceptance[]) {
    return this.post('/acceptances', data, this.headerService.headers());
  }

  consultationStatus() {
    return this.get<ConsultationStatus>('/consultationstatus', {}, this.headerService.headers());
  }

  getLink() {
    return this.get('/GetLink', [], this.headerService.headers());
  }

  logSarsRequest(type: 'electronic' | 'post') {
    // 1 for electronic/email, 2 for post
    const SarsDeliveryType = type === 'electronic' ? 1 : 2;
    return this.post('/logsarsrequest', { SarsDeliveryType }, this.headerService.headers());
  }

  updateHealthRecordShareStatus(HealthRecordShareStatus: HealthRecordShareStatusEnum) {
    return this.put(
      '/UpdateHealthRecordShareStatus',
      { HealthRecordShareStatus },
      this.headerService.headers()
    );
  }
}

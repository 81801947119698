import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { DATETIME_FORMAT } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  AvailabilityForDay,
  BookingDetails,
  QueuePosition,
  QueueQuery,
  AppointmentAvailability,
  ClinicianType,
} from '@pushdr/common/types';
import { map, pluck, switchMap } from 'rxjs/operators';
import { Moment } from 'moment';
import { Observable, of, throwError } from 'rxjs';
import * as moment from 'moment';

export interface BookedAppointment {
  OrderId: number;
  StartUtc: string;
  EndUtc: string;
  Price: number;
  DiscountDetails: {
    FullPrice: number;
    DiscountAmount: number;
    DiscountFormatted: string;
  };
  CancellationDetails: {
    Deadline: number;
  };
}

export enum AppointmentSummaryStatus {
  'Scheduled' = 1,
  'In Progress' = 2,
  'Completed' = 3,
  'Cancelled' = 4,
  'Missed' = 5,
  'Pending' = 6,
}

export enum AppointmentSummaryPaymentStatus {
  'Not Started' = 0,
  'Pending' = 1,
  'Failed' = 2,
  'Cancelled' = 3,
  'Complete' = 4,
  'Refunded' = 5,
}
export interface AppointmentSummaryV2 {
  CancellationDeadline: number;
  Id: number;
  Price: number;
  StartUtc: Moment;
  Status: AppointmentSummaryStatus;
  ClinicianType: ClinicianType;
}

export interface AppointmentSummaryDetail {
  CancellationDeadline: number;
  Id: number;
  Price: number;
  StartUtc: Moment;
  Clinician: {
    Id: number;
    FirstName: string;
    LastName: string;
    Title: string;
  };
  Status: AppointmentSummaryStatus;
  OrderLines: [
    {
      Id: number;
      PaymentStatus: AppointmentSummaryPaymentStatus;
      ServiceType: AppointmentSummaryServiceType;
      Price: number;
      Discount?: string;
    }
  ];
}

export enum AppointmentSummaryServiceType {
  'Appointment Cancellation' = 8,
  'Credit' = 11,
  'Promotion' = 13,
  'NHS Consultation' = 59,
  'Missed Appointment' = 60,
  'Push Doctor Consultation' = 65,
}

@Injectable({
  providedIn: 'root',
})
export class BookingApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/booking';
  }

  getBookedAppointments(): Observable<AppointmentSummaryV2[]> {
    return this.get('', {}, this.headerService.bearerTokenHeaders(), 3).pipe(
      pluck('Appointments'),
      map(res =>
        res.map((appointment: AppointmentSummaryV2) => ({
          ...appointment,
          StartUtc: moment.utc(appointment.StartUtc),
        }))
      )
    );
  }

  getBookedAppointmentDetail(orderId: number): Observable<AppointmentSummaryDetail> {
    return this.get(`/${orderId}`, {}, this.headerService.bearerTokenHeaders(), 3).pipe(
      map(appointment => ({
        ...appointment,
        StartUtc: moment.utc(appointment.StartUtc),
      }))
    );
  }

  currentqueue() {
    return this.get<QueueQuery>('/currentqueue', {}, this.headerService.bearerTokenHeaders());
  }

  joinqueue() {
    return this.post<QueuePosition>('/joinqueue', {}, this.headerService.bearerTokenHeaders());
  }

  // POST /api/v1/booking/appointmentavailabilitybydate

  getAppointmentAvailabilityForTheDay(DateString?) {
    DateString = DateString.replace(/\//g, '-') || moment().format('DD-MM-YYYY');
    return this.post<AvailabilityForDay[]>(
      '/appointmentavailabilitybydate',
      { DateString },
      this.headerService.bearerTokenHeaders()
    );
  }

  // GET /api/v2/booking/appointmentavailability

  getAppointmentAvailability() {
    return this.get<any[]>(
      `/appointmentavailability`,
      {},
      this.headerService.bearerTokenHeaders(),
      2
    ).pipe(
      map((res: any) => {
        return new AppointmentAvailability(res);
      })
    );
  }

  //POST /api/v1/booking/bookspecificappointment
  /**
   * @param DateTimeString format
   * @param version number api version number
   */

  bookSpecificAppointment(DateTimeString: string, version: number = 1) {
    const formattedDated = DateTimeString.replace(/\//gi, '-');
    return this.post<BookingDetails>(
      '/bookspecificappointment',
      {
        DateTimeString: formattedDated,
      },
      this.headerService.bearerTokenHeaders(),
      version
    );
  }

  //POST /api/v3/booking/cancel
  /**
   *
   */

  cancel(OrderId: string) {
    return this.post<BookingDetails>(
      '/cancel',
      { OrderId },
      this.headerService.bearerTokenHeaders(),
      3
    );
  }

  cancelMarketplaceOrder(OrderId: string) {
    return this.post(
      '/CancelMarketplaceOrder',
      { OrderId },
      this.headerService.bearerTokenHeaders()
    );
  }

  //POST /api/v2/booking/BookWithInvite

  bookWithInvite(
    DateTimeString: string,
    Code?: string,
    clinicianType?: ClinicianType,
    version: number = 2
  ) {
    const formattedDate = DateTimeString.replace(/\//gi, '-');
    return this.post<BookingDetails>(
      '/BookWithInvite',
      { DateTimeString: formattedDate, Code: Code, clinicianType },
      this.headerService.bearerTokenHeaders(),
      version
    );
  }

  // check particular appointment is ready for consult.
  canConsult(orderId: number) {
    return this.get(`/${orderId}/canConsult`, {}, this.headerService.bearerTokenHeaders()).pipe(
      switchMap(res => (res.Result === 0 ? throwError(new Error(res.Reason)) : of(true)))
    );
  }

  //POST /api/v3/booking/AppointmentBookedByClinician

  appointmentBookedByClinician(
    clinicianType: ClinicianType
  ): Observable<{ ClinicianTypeBooked: boolean }> {
    return this.post(
      `/AppointmentBookedByClinician?clinicianType=${clinicianType}`,
      { clinicianType: clinicianType },
      this.headerService.bearerTokenHeaders(),
      3
    );
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
  RestRetryBackoffConfig,
} from '@pushdr/common/data-access/rest-http-core';
import {
  GetGPResult,
  GetLastConsultationStatusResult,
  GetPharmacyPrescriptionsResult,
  GetPostConsultationThankyouMessagesResult,
  ManualGPInput,
} from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/environment';
import { retryBackoff } from 'backoff-rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { delay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiConsultationPatientService extends RestClient {
  errorRetryConfig: RestRetryBackoffConfig = {
    initialInterval: 500,
    maxRetries: 3,
    retryOnStatusCodes: [400],
    backoffDelay: (iteration, initialInterval) =>
      Math.pow(1.5, iteration) * initialInterval * 0.5 * initialInterval,
  };

  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.api + '/consultation.svc/consultationW/';
  }

  logBrowserDetails(browserName: string, OSName: string) {
    return this.post(
      'LogBrowserDetails',
      {
        strBrowser: browserName,
        strOS: OSName,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  enterWaitingRoom() {
    return this.post('EnterWaitingRoom', {}, this.headerService.authorisedHeadersLegacy);
  }

  getLastConsultationStatus() {
    return this.post<GetLastConsultationStatusResult>(
      'GetLastConsultationStatus',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.GetLastConsultationStatusResult));
  }

  consultationGetGP() {
    return this.post<GetGPResult>('GetGP', {}, this.headerService.authorisedHeadersLegacy).pipe(
      map(res => res.GetGPResult)
    );
  }

  getPharmacyPrescriptions() {
    return this.post<GetPharmacyPrescriptionsResult>(
      'GetPharmacyPrescriptions',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.GetPharmacyPrescriptionsResult));
  }

  // TODO: replace with the real API when it's ready
  getPostSurgeryCharges() {
    return of({ extensions: 3, delivery1h: true }).pipe(delay(1000));
  }

  selectLocation(postCode, upgradeToGold = false) {
    return this.post(
      'SelectLocation',
      {
        postCode: postCode,
        upgradeToGold: upgradeToGold,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  getPostConsultationThankYouMessages() {
    return this.post<GetPostConsultationThankyouMessagesResult>(
      'GetPostConsultationThankYouMessages',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.GetPostConsultationThankyouMessagesResult));
  }

  updateGPForLinkedPatient(
    strID: string = null,
    intShareStatusID: number = null,
    strGPName: string = null
  ) {
    return this.post(
      'UpdateGP',
      {
        strID,
        intShareStatusID,
        strGPName,
      },
      this.headerService.authorisedHeadersLegacy
    ).pipe(take(1));
  }

  updateGPManualForLinkedPatient(intShareStatusID: number, manualAddress: ManualGPInput) {
    return this.post(
      'UpdateGPManual',
      {
        intShareStatusID,
        ...manualAddress,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  /** @param intFullyEnded integer 1=OK 0=DISRUPTED */
  exitConsultation(intFullyEnded: number = 1) {
    return this.post(
      'exitConsultation',
      {
        intFullyEnded,
      },
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(res => res.ExitConsultationResult));
  }

  addPatientLogRangeForConsultation(Logs: any[]) {
    return this.post(
      'AddPatientLogRange',
      {
        Logs,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  /** @param strImageContents base64 image data string */
  uploadImage(strImageContents: string) {
    return this.post(
      'UploadImage',
      {
        strImageContents,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  getConsultationCredentials() {
    return this.post(
      'GetConsultationCredentialsV2',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      retryBackoff({ ...this.errorRetryConfig, shouldRetry: error => this.shouldRetry(error) }),
      map(res => res.GetConsultationCredentialsV2Result)
    );
  }

  private isHttpError(error: HttpErrorResponse) {
    return error.status !== undefined;
  }

  private shouldRetry(error) {
    if (this.isHttpError(error)) {
      return this.errorRetryConfig.retryOnStatusCodes.indexOf(parseInt(error.status, 10)) !== -1;
    }
    return false;
  }
}

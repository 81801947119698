<div class="c-modal c-modal--is-visible">
  <div class="c-modal__wrap c-modal__wrap--40">
    <h3>There was an error processing your request</h3>

    <div class="modal__body">
      Please try again - if the problem persists please contact our customer experience team
    </div>

    <div class="modal__footer">
      <button (click)="close()" class="c-btn c-btn--secondary">Ok</button>
    </div>
  </div>
</div>

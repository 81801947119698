import { Component } from '@angular/core';
import { SystemCheckService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';

@Component({
  selector: 'pushdr-system-support',
  templateUrl: './system-support.component.html',
})
export class SystemSupportComponent {
  deviceSupported = this.systemCheck.isDeviceCompatible;
  browserSupported = this.systemCheck.isBrowserCompatible;
  downloadLink = this.envProxy.environment.download;
  isAndroid: boolean = this.systemCheck.isAndroid;
  isIos: boolean = this.systemCheck.isiOS;

  constructor(private systemCheck: SystemCheckService, private envProxy: EnvironmentProxyService) {}
}

<div [formGroup]="parent">
  <div class="c-form-part__field-wrap" [class.c-form-part__entry--is-invalid]="parent.invalid">
    <label class="c-form-part__field-label" for="ipt-password">{{ passwordLabelText }} </label>
    <label
      class="c-form-part__field-label show-password-label"
      tabindex="0"
      (click)="showHidePassword()"
      (keyup.enter)="showHidePassword()"
      data-cy="show-hide-pass"
      >{{ showHidePasswordText }}
      <span class="u-sr-only"> Show password </span>
    </label>
    <pushdr-input-with-icon>
      <input
        input-field
        #oldPasswordInput
        name="oldPassword"
        formControlName="oldPassword"
        class="c-form-part__field password-input"
        id="ipt-old-password"
        aria-required="true"
        type="password"
        (input)="errors = null"
        data-cy="old-password-input"
        autocomplete="off" />
      <i
        input-icon
        *ngIf="parent.get('oldPassword').value"
        class="material-icons clear-password-input"
        data-cy="clear-pass"
        tabindex="0"
        (click)="resetPasswordField()"
        (keyup.enter)="resetPasswordField()">
        close
      </i>
    </pushdr-input-with-icon>
    <p
      class="c-form-part__error-message"
      id="err-msg-required"
      *ngIf="parent.get('oldPassword').hasError('required') && parent.get('oldPassword').dirty"
      role="alert">
      Your old password is required to change your password.
    </p>
    <p
      class="c-form-part__error-message"
      *ngIf="parent.get('oldPassword').hasError('wrongPassword') && parent.get('oldPassword').dirty"
      role="alert">
      Your current password is not correct.
    </p>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GPPractice } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-select-surgery-item',
  templateUrl: './select-surgery-item.component.html',
  styleUrls: ['./select-surgery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class SelectSurgeryItemComponent {
  @Input() surgery: GPPractice;
}

import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ModalService } from '@pushdr/common/overlay';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';

@Component({
  selector: 'pushdr-appointment-medical-complaint',
  templateUrl: './appointment-medical-complaint.component.html',
})
export class AppointmentMedicalComplaintPageComponent {
  hasConsultationNumber = false;

  constructor(
    private _location: Location,
    private api: ApiPatientService,
    private modal: ModalService
  ) {}

  cancel() {
    this._location.back();
  }

  submit(form: NgForm) {
    const { consultationNum, strComplaint } = form.value;
    this.tellUserWeAreSending();
    this.api.account
      .medicalComplaint(consultationNum, strComplaint)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.modal.acknowledge('Complaint received - thank you', '');
          form.resetForm();
        },
        error: err => {
          this.modal.error(err);
        },
      });
  }

  private tellUserWeAreSending() {
    this.modal.showLoader({
      header: 'Sending your complaint...',
      bottomText: 'Please wait while we send it',
    });
  }
}

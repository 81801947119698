<form *ngIf="form" [formGroup]="form" (submit)="submit()">
  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="
      form.get('AddressLine1').touched && form.get('AddressLine1').invalid
    ">
    <label class="c-form-part__field-label" for="AddressLine1">Address line 1</label>
    <input type="text" class="c-form-part__field" formControlName="AddressLine1" />
    <p class="c-form-part__error-message" *ngIf="form.get('AddressLine1').errors?.required">
      Please enter the first line of your address.
    </p>
  </div>

  <div class="c-form-part__field-wrap">
    <label class="c-form-part__field-label" for="AddressLine2">Address line 2</label>
    <input
      type="text"
      name="AddressLine2"
      class="c-form-part__field"
      formControlName="AddressLine2" />
  </div>

  <div class="c-form-part__field-wrap">
    <label class="c-form-part__field-label" for="AddressLine3">Address line 3</label>
    <input
      type="text"
      name="AddressLine3"
      class="c-form-part__field"
      formControlName="AddressLine3" />
  </div>

  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="
      form.get('PostTown').touched && form.get('PostTown').invalid
    ">
    <label class="c-form-part__field-label" for="PostTown">Town/City</label>
    <input type="text" class="c-form-part__field" name="PostTown" formControlName="PostTown" />
    <p class="c-form-part__error-message" *ngIf="form.get('PostTown').errors?.required">
      Please enter your town/city.
    </p>
  </div>

  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="
      form.get('County').touched && form.get('County').invalid
    ">
    <label class="c-form-part__field-label" for="County">County</label>
    <input type="text" class="c-form-part__field" name="County" formControlName="County" />
    <p class="c-form-part__error-message" *ngIf="form.get('County').errors?.required">
      Please enter your county.
    </p>
  </div>

  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="
      form.get('PostCode').touched && form.get('PostCode').invalid
    ">
    <label class="c-form-part__field-label" for="PostCode">Postcode</label>
    <input type="text" class="c-form-part__field" name="PostCode" formControlName="PostCode" />
    <p class="c-form-part__error-message" *ngIf="form.get('PostCode').errors?.required">
      Please enter your postcode.
    </p>
    <p class="c-form-part__error-message" *ngIf="form.get('PostCode').errors?.pattern">
      Please enter a valid postcode.
    </p>
  </div>

  <button class="c-btn c-btn--primary c-form-part__btn" type="submit" [trackClick]="buttonText">
    {{ buttonText }}
  </button>
</form>

<div
  [formGroup]="addressForm"
  (submit)="submitted = true"
  [class.c-form-part__entry--is-invalid]="submitted && postCode.invalid"
  class="u-mb24@xs">
  <label class="c-search-input" data-cy="label-search-input"
    >Enter your postcode <span class="u-sr-only">, then press search</span>
    <span class="c-search-input__input_wrap">
      <input
        data-cy="search-input"
        type="text"
        id="previousPostcode"
        name="previousPostcode"
        (input)="search.emit([])"
        class="c-search-input__field u-mb0@xs"
        autocomplete="postal-code"
        formControlName="postcode" />
      <button
        data-cy="search-submit"
        (click)="handleSearchClick($event)"
        class="c-search-input__btn-search"
        trackClick="search"
        aria-label="search"
        title="search">
        Search
      </button>
    </span>
    <p data-cy="search-input-error" class="c-form-part__error-message u-mb24@xs">
      Please enter a valid UK postcode.
    </p>
  </label>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  AppointmentSummaryV2,
  AppointmentSummaryStatus,
} from '@pushdr/patientapp/common/data-access/patient-api';
import { PatientAppointmentsService } from '@pushdr/patientapp/common/services';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'pushdr-appointment-future-page',
  templateUrl: './appointment-future-page.component.html',
})
export class AppointmentFuturePageComponent implements OnInit {
  appointmentSummary$ = this.patientAppointmentsService.appointmentSummary$.pipe(
    map(appointments => appointments.filter(appointment => isAppointmentActive(appointment))),
    map(appointments => this.mapAppointmentsOrder(appointments)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private envProxy: EnvironmentProxyService,
    private patientAppointmentsService: PatientAppointmentsService
  ) {}

  ngOnInit() {
    this.patientAppointmentsService.forceRefresh();
  }

  showCancelScreen(summary: AppointmentSummaryV2) {
    this.router.navigate(['../cancel', summary.Id], {
      relativeTo: this.route,
    });
  }

  startAppointment(details: AppointmentSummaryV2) {
    this.router.navigate(['preappointment'], { queryParams: { orderId: details.Id } });
  }

  bookAppointment() {
    window.location.href = `${this.envProxy.environment.patient.booking.url}/emergency`;
  }

  forceRefresh() {
    this.patientAppointmentsService.forceRefresh();
  }

  private mapAppointmentsOrder(appointments: AppointmentSummaryV2[]) {
    return appointments.sort((a1, a2) => {
      if (a1.StartUtc > a2.StartUtc) {
        return 1;
      }
      if (a1.StartUtc < a2.StartUtc) {
        return -1;
      }
      return 0;
    });
  }
}

function isAppointmentActive(appointment: AppointmentSummaryV2): boolean {
  const appointmentStatuses = [
    AppointmentSummaryStatus.Scheduled,
    AppointmentSummaryStatus['In Progress'],
  ];
  return appointmentStatuses.includes(appointment.Status);
}

import { Gender } from './customer';

export class Account {
  Email?: string = null;
  Password?: string = null;
  Mobile?: string = null;
  SurgeryOds?: string = null;
  Postcodes?: Postcode[] = [{ Postcode: '', Type: 1 }]; // remove this when API no longer expects
  Acceptances?: CustomerAcceptance[] = [];
  SocialSignIn?: string = null;
}

export enum LinkedAccountCustomerType {
  Unknown = 0,
  Adult = 1,
  Child = 2,
}

export interface ChildAccount {
  Id: number;
  FirstName: string;
  LastName: string;
  NhsNumber: string;
  Dob: string;
  Gender: Gender;
  ExternalId: string;
}
export interface LinkedAccount {
  CustomerType: LinkedAccountCustomerType;
  Id: number;
  Age: number;
  Name: string;
}

export interface Postcode {
  Postcode: string;
  Type: number;
}

export enum AcceptanceTypes {
  NOT_SPECIFIED,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  MARKETING_SUBSCRIPTION,
  MEDICAL_RECORDS_CONSENT,
}

export const AcceptanceGroupName = {
  MARKETING: 'marketing',
  NHS: 'nhs',
};

export const AcceptanceTypeName = {
  NOT_SPECIFIED: 'NotSpecified',
  TERMS_AND_CONDITIONS: 'TermsAndConditions',
  PRIVACY_POLICY: 'PrivacyPolicy',
  MARKETING_SUBSCRIPTION: 'MarketingSubscription',
  MEDICAL_RECORDS_CONSENT: 'MedicalRecordsConsent',
};

export interface Acceptance {
  Content?: string;
  Created?: string;
  Group: number;
  Type: number;
  Version: number;
  Mandatory: boolean;
  Name?: string;
  CallToAction?: string;
}

export interface CustomerPreference {
  Id: number;
  Name: string;
  Description: string;
  Value: boolean;
  PreferenceType: number;
}

export interface CustomerAcceptance {
  Accepted?: boolean;
  DateStamp?: string;
  Group: number;
  Type: number;
  Version: number;
  Mandatory: boolean;
}

export interface ExtendedCustomerAcceptance extends CustomerAcceptance, Acceptance {}

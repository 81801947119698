import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { toCamelCase } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { Observable } from 'rxjs';
import { ActionRequest } from '@pushdr/common/types';

@Injectable({
  providedIn: 'root',
})
export class ActionRequestApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/actionRequest';
  }

  getActionRequest(actionRequestId: string): Observable<ActionRequest> {
    return this.get(`/${actionRequestId}`).pipe(toCamelCase());
  }

  claimActionRequest(actionRequestId: string): Observable<any> {
    return this.post(`/${actionRequestId}/claim`, {}, this.headerService.headers());
  }
}

<footer class="pdr-footer-component">
  <div class="o-wrapper o-container o-container--fluid">
    <div class="o-row o-row--margin-bottom-small">
      <div class="col-xs-12">
        <h4 class="text-grey-700 funnel-footer-tagline">SAFE AND SECURE</h4>
        <div class="o-pack o-pack--middle center-xs">
          <div class="o-pack__img">
            <a href="https://www.cqc.org.uk/provider/1-1207461908">
              <img
                class="funnel-footer-image cqc-logo"
                src="/assets/images/cqc_logo.svg"
                alt="CQC logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

import { Component, Input } from '@angular/core';

@Component({
  // TODO undo this - tslint disabled because it would make this PR massively noisy
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'account-content-wrapper',
  templateUrl: './account-content-wrapper.component.html',
})
export class AccountContentWrapperComponent {
  @Input()
  hasNav = true;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountContentWrapperComponent } from './account-content-wrapper.component';

@NgModule({
  declarations: [AccountContentWrapperComponent],
  imports: [CommonModule],
  exports: [AccountContentWrapperComponent],
})
export class AccountContentWrapperModule {}

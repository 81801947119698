export enum ClinicianType {
  Unknown = 0,
  GeneralPractitioner = 1,
  MinorAilmentsPharmacist = 2,
  Physio = 3,
  MentalHealthTherapist = 4,
}

export const clinicianTypeLabels: Record<ClinicianType, string> = {
  [ClinicianType.Unknown]: 'UNKNOWN',
  [ClinicianType.GeneralPractitioner]: 'General Practitioner',
  [ClinicianType.MinorAilmentsPharmacist]: 'Minor Ailments Pharmacist',
  [ClinicianType.Physio]: 'Physiotherapist',
  [ClinicianType.MentalHealthTherapist]: 'Mental Health Therapist',
};

<div [formGroup]="parent">
  <div
    class="c-form-part__field-wrap"
    [class.c-form-part__entry--is-invalid]="errors?.length || parent.invalid">
    <label class="c-form-part__field-label" for="ipt-password"
      >{{ passwordLabelText }}
      <span class="u-sr-only">
        <span *ngFor="let condition of rules?.conditionsToMeet">{{ condition }}</span>
      </span>
    </label>
    <label
      class="c-form-part__field-label show-password-label"
      tabindex="0"
      (click)="showHidePassword()"
      (keyup.enter)="showHidePassword()"
      data-cy="show-hide-pass"
      >{{ showHidePasswordText }}
      <span class="u-sr-only"> Show password </span>
    </label>
    <pushdr-input-with-icon>
      <input
        input-field
        #passwordInput
        name="Password"
        formControlName="password"
        class="c-form-part__field password-input"
        id="ipt-password"
        aria-required="true"
        type="password"
        (input)="errors = null"
        data-cy="password-input"
        autocomplete="off"
        aria-describedby="password-tip"
        (ngModelChange)="checkPassword($event)" />
      <i
        input-icon
        class="material-icons clear-password-input"
        data-cy="clear-pass"
        tabindex="0"
        (click)="resetPasswordField()"
        (keyup.enter)="resetPasswordField()">
        close
      </i>
    </pushdr-input-with-icon>
    <p
      class="c-form-part__error-message"
      id="err-msg-required"
      *ngIf="parent.get('password').hasError('required') && parent.get('password').dirty"
      role="alert">
      A password is required to sign up.
    </p>
    <p
      *ngFor="let error of errors"
      class="c-form-part__error-message"
      role="alert"
      [attr.data-cy]="error">
      {{ error }}
    </p>
  </div>

  <ng-container *ngIf="!errors?.length && parent.get('password').hasError('notValid')">
    <div id="password-tip-errors">
      <ng-template ngFor let-condition [ngForOf]="conditions">
        <p
          *ngIf="condition.isErrorCondition && condition.errorMatched"
          [innerHTML]="condition.innerHTML"
          [attr.data-cy]="condition.dataCy"></p>
      </ng-template>
    </div>
    <div id="password-tip">
      <div class="passwordHelp">
        <p>Must have:</p>
        <ng-template ngFor let-condition [ngForOf]="conditions">
          <p
            *ngIf="!condition.isErrorCondition"
            [innerHTML]="condition.innerHTML"
            [attr.data-cy]="condition.dataCy"
            [ngClass]="{
              'condition-matched': condition.matched,
              'error-matched': condition.errorMatched
            }"></p>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>

import { of } from 'rxjs';

export const RestClientStub = {
  post: () => of(true),
  get: () => of(true),
  put: () => of(true),
  delete: () => of(true),
};
export const RestErrorClientStub = {
  pipeErrorHandlers: () => of(true),
};
export const RestHttpHeaderServiceStub = {
  bearerTokenHeaders: () => ({}),
  unauthorisedHeaders: () => ({}),
  encryptedUserHeaders: () => ({}),
  headers: () => ({}),
  user: { Mobile: '+44123456789' },
};

export const HttpClientStub = {
  post: () => of(true),
  get: () => of(true),
  put: () => of(true),
  delete: () => of(true),
};

export const RestErrorParserServiceStub = {
  pipeErrorHandlers: (apiCall, method) => of(true),
};

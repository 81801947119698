<account-content-wrapper>
  <h3 class="u-mb24@xs" trackView="medical complaint">Medical complaints</h3>
  <div class="box">
    <p>
      If you would like to make a complaint regarding the medical service you received on the Push
      Doctor platform you can do so here.
    </p>
    <p>
      Please note that this is not to be used for general customer service issues, but only if you
      are specifically complaining about the quality of the care that you have received.
    </p>
    <p>
      If you have general customer service issues then please contact us by calling
      <a href="tel:03308084702" class="font-bold whitespace-nowrap">0330 808 4702</a> rather than
      using this form.
    </p>
    <p>
      <a href="https://www.pushdoctor.co.uk/hubfs/legal/medical-complaints.html" target="_blank"
        >Find out more about our complaints process here</a
      >.
    </p>
    <h4>To register your medical complaint:</h4>
    <ul>
      <li>
        Please enter the consultation code of the session that you would like to report. You can
        find this in your
        <a [routerLink]="['/account', 'appointments', 'history']">consultation history</a>.
      </li>
      <li>Describe your issue in the text box below.</li>
    </ul>
    <form #f="ngForm" (ngSubmit)="f.valid && submit(f)">
      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="f.submitted && consultationNum?.errors">
        <label class="c-form-part__label" for="consultationNum"> Consultation number</label>
        <input
          id="consultationNum"
          class="c-form-part__field"
          type="text"
          name="consultationNum"
          ngModel
          #consultationNum="ngModel"
          [required]="!hasConsultationNumber"
          NumberOnly
          maxlength="9"
          minlength="9" />
        <p class="c-form-part__error-message" *ngIf="consultationNum?.errors?.required">
          A consultation number is required
        </p>
        <p class="c-form-part__error-message" *ngIf="consultationNum?.errors?.minlength">
          A consultation number must be 9 digits
        </p>
      </div>
      <pushdr-input-checkbox
        class="p-4 rounded border mb-6"
        style="display: inline-flex"
        [class.bg-grey-100]="!hasConsultationNumber"
        [class.border-white]="!hasConsultationNumber"
        [class.border-indigo-500]="hasConsultationNumber"
        [(ngModel)]="hasConsultationNumber"
        [ngModelOptions]="{ standalone: true }">
        I can't find my consultation number
      </pushdr-input-checkbox>
      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="f.submitted && strComplaint?.errors?.required">
        <label class="c-form-part__label" for="complaintText">
          Your complaint (please provide as much detail as possible)*
        </label>
        <textarea
          id="complaintText"
          ngModel
          #strComplaint="ngModel"
          class="u-mb0@xs"
          name="strComplaint"
          cols="80"
          rows="5"
          required></textarea>
        <p class="c-form-part__error-message">Details about your complaint are required</p>
      </div>

      <button class="c-btn c-btn--primary" type="submit">Submit complaint</button>
    </form>
    <button (click)="cancel()" class="c-btn c-btn--secondary" type="cancel">Cancel</button>
  </div>
</account-content-wrapper>

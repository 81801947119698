import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import {
  Invite,
  InviteResponse,
  InviteTypeString,
  InviteType,
  InviteMeta,
  PartnerType,
  MetaDataNHS,
} from '@pushdr/common/types';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvitationsApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/invitations';
  }

  //GET /api/v2/invitations/{partnerId}/{promotionId}/{code}

  getExtendedInvite(invite: Invite) {
    return this.get<InviteResponse>(
      `/nhs/${invite.partnerId}/${invite.promotionId}/${invite.code}`,
      {},
      this.headerService.unauthorisedHeaders(),
      2
    ).pipe(map(res => this.mapResponseToInvite(res)));
  }

  //GET /api/v2/invitations/{partnerId}/{promotionId}/{code}

  getInvites(partnerPromotionApplicationType: InviteTypeString, partnerId: string) {
    if (!this.headerService.hasHeaders()) {
      return of([]);
    }

    return this.get<InviteResponse[]>(
      ``,
      { partnerPromotionApplicationType, partnerId },
      this.headerService.headers(),
      2
    ).pipe(map(res => res.map(item => this.mapResponseToInvite(item))));
  }

  private mapResponseToInvite(invite: InviteResponse): Invite {
    const out: Invite = {
      partnerId: invite.PartnerId,
      promotionId: invite.PromotionId,
      code: invite.Code,
      inviteStatus: invite.InviteStatus,
      link: invite.Link,
      type: invite.ApplicationType || InviteType.TBD,
      partnerType: invite.PartnerType,
      meta: this.parseMeta(invite),
    };
    return out;
  }

  private parseMeta(invite: InviteResponse): InviteMeta {
    if (!invite.MetaData) return {};
    switch (invite.PartnerType) {
      case PartnerType.NHS: {
        const nhsMeta: MetaDataNHS = invite.MetaData as MetaDataNHS;
        return {
          name: nhsMeta.SurgeryName,
          gp: {
            address: nhsMeta.SurgeryAddress,
            id: nhsMeta.SurgeryId,
            odscode: nhsMeta.SurgeryOdsCode,
          },
        };
      }
      default:
        //Private
        return {};
    }
  }

  //PUT /api/v2/invitations/{partnerId}/{promotionId}/{code}

  claimTheInvite(invite: Invite) {
    return this.put<any>(
      `/${invite.partnerId}/${invite.promotionId}/${invite.code}`,
      {},
      this.headerService.headers(),
      2
    ).pipe(map(inv => this.mapResponseToInvite(inv)));
  }

  //POST /api/v2/invitations/{partnerId}/{promotionId}

  createAndClaimTheInvite(invite: Invite) {
    return this.post<any>(
      `/${invite.partnerId}/${invite.promotionId}`,
      {},
      this.headerService.headers(),
      2
    ).pipe(map(inv => this.mapResponseToInvite(inv)));
  }

  spendTheInvite(invite: Invite) {
    return this.post<any>(`/spend/${invite.code}`, {}, this.headerService.headers(), 2);
  }
}

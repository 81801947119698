import { Component, Input } from '@angular/core';
import { FooterLike } from '../../pd-footer-interface';

@Component({
  selector: 'pushdr-pdr-footer-default',
  templateUrl: './pdr-footer-default.component.html',
  styleUrls: ['./pdr-footer-default.component.scss'],
})
export class PdrFooterDefaultComponent implements FooterLike {
  @Input() homeUrl;
  @Input() downloadLink;
}

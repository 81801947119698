import { ErrorMessageAdaptor } from './utils';
import { PDServerError } from '@pushdr/common/types';
import { ErrorMessageCore } from './core.errors';
import { Injectable } from '@angular/core';

export const InvitationErrorMessageAdaptor = (
  error: PDServerError,
  message: string = 'If you believe this is an error, please contact us at support@pushdoctor.co.uk',
  header?: string
): PDServerError => {
  error.header = header || error.message || 'Sorry';
  error.message = message;
  return error;
};

@Injectable({
  providedIn: 'root',
})
export class InvitationErrorsService extends ErrorMessageCore {
  commonInviteErrors(error: PDServerError) {
    switch (error.status) {
      case 400:
        return InvitationErrorMessageAdaptor(error);
      case 404:
        return ErrorMessageAdaptor(
          error,
          'The invitation you were sent has expired or is no longer valid. Please contact your invite provider for another.'
        );
      default:
        return this.commonErrors(error);
    }
  }
}

import { AuthenticationErrorsService } from './authentication.errors';
import { AddressErrorsService } from './address.errors';
import { ValidationErrorsService } from './validation.errors';
import { CustomerErrorsService } from './customer.errors';
import { AccountErrorsService } from './account.errors';
import { Injectable } from '@angular/core';
import { InvitationErrorsService } from './invitations.errors';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorMessageService {
  constructor(
    public account: AccountErrorsService,
    public addresses: AddressErrorsService,
    public authentication: AuthenticationErrorsService,
    public customer: CustomerErrorsService,
    public invitations: InvitationErrorsService,
    public validation: ValidationErrorsService
  ) {}
}

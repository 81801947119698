import { StorageService } from '@pushdr/common/utils';
import { EncryptedUser } from '@pushdr/common/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { sha256 } from 'js-sha256';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EncryptedUserService {
  private userSubject$: BehaviorSubject<EncryptedUser> = new BehaviorSubject(null);
  user$: Observable<EncryptedUser>;
  userId$: Observable<string>;

  constructor(private store: StorageService) {
    this.user$ = this.userSubject$.asObservable();
    this.userId$ = this.userSubject$
      .asObservable()
      .pipe(map(user => (user ? user.CustomerId.toString() : '')));
    this.user = this.fetchStoredUser();
  }

  set user(newUser: EncryptedUser) {
    this.userSubject$.next(newUser);
    if (newUser) {
      this.store.set('euser', newUser, 0, 0, null);
      this.storeEncryptedEmail(newUser.Email);
    } else {
      this.store.delete('euser');
      this.store.delete('eemail');
    }
  }

  get user() {
    const user = this.fetchStoredUser();
    if (!this.userSubject$.value) {
      this.userSubject$.next(user);
    }
    return user;
  }

  private fetchStoredUser() {
    return this.store.get('euser', true);
  }

  reset() {
    this.user = null;
  }

  private storeEncryptedEmail(email: string) {
    const formatEmail = email.toLowerCase();
    const hashEmail = sha256(formatEmail);

    this.store.set('eemail', hashEmail, 0, 0, null);
  }
}

import {
  PasswordStrengthConditionsNotMet,
  PasswordStrengthConditions,
  DOBErrors,
} from '@pushdr/common/types';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { Invite } from '@pushdr/common/types';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/validation';
  }

  /**
   * returns 200 { conditionsToMeet: string[]}
   */

  passwordConditions() {
    return this.get<PasswordStrengthConditions>('/passwordstrength');
  }

  /**
   * returns
   *  200 no content - all is well
   *  412 with PasswordStrengthConditionsNotMet in original error body
   * @param Value is password string
   */

  passwordStrength(Value: string) {
    return this.post('/passwordstrength', {
      Value,
    });
  }

  /**
   * returns 200 - no errors
   * or 412 and DOBErrors
   * @param dateOfBirth format yyyy-mm-dd
   */

  dob(DateOfBirth: string, invite?: Invite) {
    // return of(true);
    let body: any = { DateOfBirth };
    if (invite && invite.code) {
      body = {
        ...body,
        PartnerId: invite.partnerId,
        PromotionId: invite.promotionId,
        Code: invite.code,
      };
    }
    return this.post('/dob', body, this.headerService.unauthorisedHeaders(), 2);
  }

  canRegisterMobile(Mobile: string, invite?: Invite) {
    let body: any = { Mobile };
    if (invite && invite.code) {
      body = {
        ...body,
        PartnerId: invite.partnerId,
        PromotionId: invite.promotionId,
        Code: invite.code,
      };
    }
    return this.post('/canregistermobile', body, this.headerService.unauthorisedHeaders(), 2);
  }

  canRegisterEmail(Email: string) {
    const body: any = { Email };
    return this.post('/canregisteremail', body);
  }
}

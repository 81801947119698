<ng-container *ngIf="appointmentSummary$ | async as appointments; else loading">
  <account-content-wrapper>
    <h3 class="u-mb24@xs" trackView="history">Appointment history</h3>
    <ng-container *ngIf="appointments.length; else noAppointments">
      <pushdr-appointment-details
        *ngFor="let appointment of appointments; trackBy: trackByAppointmentId"
        [appointment]="appointment"></pushdr-appointment-details>
    </ng-container>
  </account-content-wrapper>
</ng-container>

<ng-template #noAppointments>
  <p>You have no previous appointments.</p>
</ng-template>

<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvironmentProxyService } from '@pushdr/environment';

import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';

import { Answer, PendingFeedback, Question } from './models';

@Injectable({
  providedIn: 'root',
})
export class FeedbackApiService extends RestClient {
  constructor(
    protected http: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParser: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(http, headerService, errorParser, proxy);
  }

  endpoint = () => this.proxy.environment.patient.nhsAPI + '/CustomerSurvey';

  getQuestions(): Observable<Question[]> {
    const header = this.headerService.bearerTokenHeaders();
    const request$ = this.post<{ Questions: Question[] }>('', {}, header, 4);

    const sortBySequenceNo = (a: Question, b: Question) =>
      a.QuestionSequenceNo - b.QuestionSequenceNo;

    return request$.pipe(
      map(res => res.Questions),
      map(questions => questions.sort(sortBySequenceNo))
    );
  }

  sendAnswers(orderId: number, answers: Answer[]): Observable<void> {
    const headers = this.headerService.bearerTokenHeaders();
    const body = { OrderId: orderId, Answers: answers };
    return this.post('/LeaveFeedback', body, headers, 4);
  }

  getPendingFeedback(): Observable<PendingFeedback> {
    const headers = this.headerService.bearerTokenHeaders();
    return this.post('/PendingFeedback', void 0, headers, 4);
  }

  rejectFeedback(orderId: number): Observable<void> {
    const headers = this.headerService.bearerTokenHeaders();
    return this.post('/NoThanks', { OrderId: orderId }, headers, 4);
  }
}

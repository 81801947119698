import { AfterContentInit, Directive, Input } from '@angular/core';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[trackView]',
})
export class AnalyticsTrackViewDirective implements AfterContentInit {
  @Input('trackView') view: string;

  constructor(private analytics: AnalyticsBusService) {}

  ngAfterContentInit() {
    const action = 'view';
    const view = this.view;
    this.analytics.trackEvent({
      view,
      target: view,
      action,
      properties: {
        href: location.href,
      },
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchPostcodeComponent } from './search-postcode.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [SearchPostcodeComponent],
  declarations: [SearchPostcodeComponent],
})
export class SearchPostcodeModule {}

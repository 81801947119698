import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DatesService } from '@pushdr/common/utils';
import * as moment from 'moment';

@Component({
  selector: 'pushdr-common-edit-details-form',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss'],
})
export class EditDetailsComponent implements OnChanges {
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() dob = '';
  @Input() legacy = true;
  dobComposite = { dd: '', mm: '', yyyy: '' };

  @Input() gender = 0;
  @Output() save: EventEmitter<{
    firstName;
    lastName;
    dob;
    gender;
  }> = new EventEmitter();

  constructor(private _dates: DatesService) {}

  ngOnChanges(simple: SimpleChanges) {
    if (simple.dob) {
      this.populateDate(simple.dob.currentValue);
    }
  }

  populateDate(dateString: string) {
    let [dd, mm, yyyy] = ['', '', ''];
    if (dateString.includes('/')) {
      [dd, mm, yyyy] = this.splitLegacyDate(dateString);
    } else if (moment(dateString).isValid()) {
      [dd, mm, yyyy] = moment(dateString).format('dd-MM-YYYY').split('-');
    } else {
      this.splitLegacyDate(dateString);
    }

    this.dobComposite = { dd: dd, mm: mm, yyyy: yyyy };
  }

  private splitLegacyDate(original) {
    const delims = ['-', '/'];
    let output = ['', '', ''];
    delims.forEach(delim => {
      if (original.indexOf(delim) > -1) {
        output = original.split(delim);
      }
    });

    return output;
  }

  dd_mm_yyyy(d = '-') {
    return `${this.dobComposite.dd}${d}${this.dobComposite.mm}${d}${this.dobComposite.yyyy}`;
  }

  submit(form) {
    if (form.invalid) return null;

    const dob = this.legacy
      ? this.dd_mm_yyyy()
      : this._dates.getUTCDate(this.dobComposite.yyyy, this.dobComposite.mm, this.dobComposite.dd);
    const firstName = this.firstName;
    const lastName = this.lastName;
    const gender = this.gender;
    this.save.emit({ firstName, lastName, dob, gender });
  }
}

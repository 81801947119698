import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { Acceptance, AcceptanceGroupName } from '@pushdr/common/types';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

const fallbackHTML = `<body data-gr-c-s-loaded="true">
  <div class="container">
      <h1 style="font-size: 1.5em; margin-top: 48px;">
          NHS health and care record information sharing with Push Doctor
      </h1>

      <p>
          In order to provide you with this digital service, our clinicians may need to access your health records, which might include information about your medication, allergies, vaccinations, previous illnesses and test results, hospital discharge summaries, appointment letters and referral letters.
      </p>
      <p>
          For further details on how we use your information, please refer to our Privacy Policy.
      </p>
      <p>
          <strong>
              All clinicians with access are fully qualified and trained in the NHS. They are registered with the General Medical Council and on the NHS England Medical Performers list.
          </strong>
      </p>
  </div>
</body>`;

@Injectable({
  providedIn: 'root',
})
export class AcceptanceApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/acceptance';
  }

  query(
    groups: string = `${AcceptanceGroupName.NHS},${AcceptanceGroupName.MARKETING}`,
    type?: string
  ) {
    const queryParams: any = { groups };
    if (type) {
      queryParams.type = type;
    }
    return this.get<Acceptance[]>('', queryParams);
  }

  htmlContent(acceptance: Acceptance) {
    return this.http
      .get(acceptance.Content, { responseType: 'text' })
      .pipe(
        catchError(e =>
          this.http.get('https://www.pushdoctor.co.uk/hubfs/legal/consent-alt.html', {
            responseType: 'text',
          })
        ),
        catchError(e => of(fallbackHTML)),
        map((html: any) => {
          const pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
          return pattern.exec(html)[0] || '';
        })
      )
      .toPromise();
  }
}

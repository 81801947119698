import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReminderModalComponent } from './reminder-modal/reminder-modal.component';
import { FeedbackReminderService } from './feedback-reminder.service';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [ReminderModalComponent],
  providers: [FeedbackReminderService],
})
export class FeedbackReminderModule {}

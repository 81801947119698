import { Injectable } from '@angular/core';
import {
  AnalyticsBusService,
  GoogleAnalyticsService,
  AnalyticsService,
  PushDoctorLogsService,
  GTMEventTrackingService,
} from '@pushdr/common/data-access/analytics';
import { EnvironmentProxyService } from '@pushdr/environment';
import { FirebaseAnalyticsService } from './firebase/firebase-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class PatientAnalyticsService extends AnalyticsService {
  private _initialised = false;

  constructor(
    protected bus: AnalyticsBusService,
    private env: EnvironmentProxyService,
    private gaService: GoogleAnalyticsService,
    private logs: PushDoctorLogsService,
    private gtm: GTMEventTrackingService,
    private fbase: FirebaseAnalyticsService
  ) {
    super(bus);
  }

  initialise(appIdentifier: string) {
    if (!this._initialised) {
      this._initialised = true;
      this.fireBaseInitialized$ = this.fbase.firebaseInitialized$;
      this.fbase.initialise(this.env.environment.firebase, {
        user: [''],
        events: [
          'availability_ui_reached',
          'booking_ui_started',
          'logout',
          'login',
          'purchase',
          'client_registration_started',
          'client_registration_complete',
          'preferred_appointment_time_selected',
          'appointment_selected',
          'appointment_confirmed',
          'pageView',
          'selected_content',
          'non_governance_acceptance_agreed',
          'non_governance_acceptance_not_agreed',
          'governance_acceptance_consented',
          'governance_acceptance_declined',
          'changed_mind_after_governance_decline',
          'navigated_home_after_governance_decline',
        ],
      });
      this.gtm.initialise(
        this.env.environment.google.gtm,
        {
          user: [''],
          events: ['userType', 'view', 'click'],
        },
        'Patient'
      );
      this.logs.initialise({ user: [''], events: ['log', 'error', 'info'] });
      this.gaService.initialise({
        user: [''],
        events: [
          'cancel_appointment',
          'non_governance_acceptance_agreed',
          'non_governance_acceptance_not_agreed',
          'governance_acceptance_consented',
          'governance_acceptance_declined',
          'changed_mind_after_governance_decline',
          'navigated_home_after_governance_decline',
        ],
      });
    } else {
      throw Error('Analytics service has already initialised');
    }
  }
}

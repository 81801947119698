import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { SelectableOption } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionListComponent implements OnInit, OnChanges {
  @Input() options: SelectableOption[];
  @Input() selectedIds: number[];
  @Input() multi = false;
  @Input() buttonText = 'Next';
  @Input() customAriaLabel = '';
  @Output() selected: EventEmitter<number[]> = new EventEmitter();
  selection: SelectionModel<number>;

  constructor() {}

  ngOnInit() {
    this.selection = this.populateSelectionModel(this.selection, this.selectedIds);
  }

  selectOption(id: number) {
    if (this.multi) {
      this.selection.toggle(id);
    } else {
      this.selection.select(id);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedIds) {
      this.selection = this.populateSelectionModel(this.selection, this.selectedIds);
    }
  }

  private populateSelectionModel(selectionModel: SelectionModel<number>, ids: number[]) {
    if (!selectionModel) {
      return new SelectionModel<number>(this.multi, ids);
    } else {
      selectionModel.clear();
      selectionModel.select(...ids);
      return selectionModel;
    }
  }
}

import { of } from 'rxjs';
import { InviteType } from '../nhs';

export const inviteServiceStub = {
  getInvite: (type: InviteType) => of(null),
  claimInvite: (type: InviteType) => of(null),
  spendInvite: (type: InviteType) => of(null),
  getInviteFromLocalState: () => {},
  flushInviteCookie: () => {},
};

export const simpleModalServiceStub = {
  addModal: (component, data?, options?: any) => of(true),
  removeModal: (component): Promise<any> => Promise.resolve(true),
  removeAll: (): Promise<any> => Promise.resolve(true),
};

export const canConsultServiceStub = { canConsult: () => of(true) };

export const messageQueueServiceStub = {
  chatHub: { on: () => ({}) },
  init: () => of(''),
  start: () => of(''),
  stop: () => of(''),
  destroy: () => of(''),
  onMessage: () => of(''),
  onDisconnect: () => of(''),
  onError: () => of(''),
  send: () => of(''),
};

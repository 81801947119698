import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';

@Component({
  selector: 'pushdr-edit-upload-id',
  templateUrl: './edit-upload-id.component.html',
  styleUrls: ['./edit-upload-id.component.scss'],
})
export class EditUploadIdComponent implements OnInit {
  @Output() save: EventEmitter<{
    fileToUpload: File | ArrayBuffer | string | null;
    reason: string;
  }> = new EventEmitter();
  @Input() disableUpload: boolean;

  // eslint-disable-next-line
  @ViewChild('attachment') attachment: any;

  // eslint-disable-next-line
  reason: any;
  fileToUpload: File | ArrayBuffer | string | null;

  constructor(private api: ApiPatientService, private modal: ModalService) {}

  pageInfo = null;

  ngOnInit() {
    this.getPageInfo();
  }

  getPageInfo() {
    this.api.account.getIdentificationWarningText().subscribe(response => {
      this.pageInfo = response.GetIdentificationWarningTextV2Result;
    });
  }

  fileExtensionValid(file: File): boolean {
    const permittedFileExtensions = ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'tif', 'webp'];
    const fileExt = file.name.split('.').pop().toLowerCase();

    return permittedFileExtensions.indexOf(fileExt) !== -1;
  }

  handleFileInput(files: FileList) {
    const file: File = files.item(0);
    const fileReader: FileReader = new FileReader();

    if (!this.fileExtensionValid(file)) {
      this.modal.error(
        'Please ensure your file is a valid image format (jpg, jpeg, png, gif, tif, tiff, webp)'
      );
      this.attachment.nativeElement.value = '';
      return;
    }

    fileReader.onloadend = ev => {
      this.fileToUpload = fileReader.result as string;
    };
    fileReader.readAsDataURL(file);
  }

  submit() {
    this.save.emit({ fileToUpload: this.fileToUpload, reason: this.reason });
  }
}

<div class="mt-[2px] w-5 h-5 cursor-pointer">
  <div
    class="w-5 h-5 relative bg-white border border-grey-300 rounded-sm focus-within:border-indigo-500">
    <input
      type="checkbox"
      class="block appearance-none w-5 h-5 absolute inset-0 cursor-pointer peer focus:outline-none"
      [id]="inputId"
      [formControl]="control"
      (blur)="onTouched()" />
    <img
      aria-hidden="true"
      class="object-cover m-[4px] opacity-0 transition-opacity pointer-events-none select-none peer-checked:opacity-100"
      src="./assets/icons/tick-blue.svg"
      alt="Check" />
  </div>
</div>

<label class="grow cursor-pointer" [for]="inputId">
  <ng-content></ng-content>
</label>

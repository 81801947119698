<div class="c-modal__wrap" [class.c-modal__wrap--loader]="loading">
  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
    <div *ngIf="!loading" class="modal__body">
      <h2 class="c-modal__title">Log in</h2>
      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="emailMobile.invalid && f.submitted">
        <label for="ipt-email-or-mobile">Email address / UK mobile number</label>
        <input
          [(ngModel)]="username"
          #emailMobile="ngModel"
          name="emailMobile"
          class="c-form-part__field"
          id="ipt-email-or-mobile"
          required
          aria-required="true"
          type="text"
          required />
        <p
          class="c-form-part__error-message"
          id="err-msg-email-required"
          *ngIf="emailMobile?.errors?.required">
          An email or UK mobile is required
        </p>
      </div>

      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="pw.invalid && f.submitted">
        <label class="c-form-part__field-label" for="ipt-password">Password</label>
        <input
          [(ngModel)]="password"
          name="Password"
          #pw="ngModel"
          required
          class="c-form-part__field"
          id="ipt-password"
          aria-required="true"
          type="password" />
        <p
          class="c-form-part__error-message"
          id="err-msg-password-required"
          *ngIf="pw?.errors?.required">
          A password is required
        </p>
      </div>
    </div>
    <div *ngIf="!loading" class="modal__footer">
      <button
        class="c-btn c-btn--primary c-form-part__btn c-form-part__btn--bigger-margin u-mb16@xs"
        value="Log in">
        Log in
      </button>
      <p class="u-text-center u-text-quaternary" *ngIf="error">{{ error }}</p>
      <p class="u-text-center">
        Need an account?
        <a (click)="signUp()" style="cursor: pointer">Sign up</a>
      </p>
      <p class="u-text-center">
        Forgot your password?
        <a (click)="reset()" style="cursor: pointer">Reset it</a>
      </p>
    </div>
    <div *ngIf="loading">
      <h2 class="c-modal__title">Logging in</h2>
      <div class="c-modal__loader">
        <div class="o-loader">
          <div class="o-loader__item">Loading...</div>
        </div>
      </div>
    </div>
  </form>
</div>

<div [formGroup]="dateForm" class="c-form-part__margin-wrap">
  <label class="c-form-part__label u-display-block@xs">Date of birth</label>
  <div class="c-form-part__three-date-field-wrap u-display-inline-block@xs">
    <input
      class="c-form-part__three-field c-form-part__three-field-1 sessioncamexclude"
      formControlName="dd"
      (blur)="onTouched()"
      aria-label="Day of birth such as 01"
      leadingZeros="2"
      placeholder="DD"
      id="ipt-dob-day"
      name="ipt-dob-day"
      type="tel"
      maxlength="2"
      minlength="2"
      required
      (input)="focusOnNextInputOnInput($event)"
      (keydown.ArrowRight)="focusOnNextInput($event)" />
    /
    <input
      class="c-form-part__three-field c-form-part__three-field-2 sessioncamexclude"
      formControlName="mm"
      pattern="\d{1,2}"
      (blur)="onTouched()"
      aria-label="Month of birth for example 02 for February"
      placeholder="MM"
      id="ipt-dob-month"
      name="ipt-dob-month"
      type="tel"
      maxlength="2"
      minlength="2"
      required
      leadingZeros="2"
      (input)="focusOnNextInputOnInput($event)"
      (keydown.ArrowLeft)="focusOnPreviousInput($event)"
      (keydown.ArrowRight)="focusOnNextInput($event)"
      (keydown.Backspace)="deleteLastNumberOfPreviousInput($event)" />
    /
    <input
      class="c-form-part__three-field c-form-part__three-field-3 sessioncamexclude"
      formControlName="yyyy"
      (blur)="onTouched()"
      aria-label="Year of birth written in full like 1982"
      placeholder="YYYY"
      id="ipt-dob-year"
      name="ipt-dob-year"
      type="tel"
      maxlength="4"
      minlength="4"
      required
      (input)="focusOnNextInputOnInput($event)"
      (keydown.ArrowLeft)="focusOnPreviousInput($event)"
      (keydown.Backspace)="deleteLastNumberOfPreviousInput($event)" />
  </div>
</div>

import { Component } from '@angular/core';
import { PDServerError } from '@pushdr/common/types';
import { EnvironmentProxyService } from '@pushdr/environment';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Observable } from 'rxjs';
export interface SignInModel {
  username: string;
  processLogin: (username, password) => Observable<string>;
}

@Component({
  selector: 'pushdr-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss'],
})
export class SignInModalComponent
  extends SimpleModalComponent<SignInModel, any>
  implements SignInModel
{
  username: string;
  password: string;
  error: string;
  loading = false;
  processLogin: (username, password) => Observable<string>;

  constructor(private envProxy: EnvironmentProxyService) {
    super();
  }
  submit() {
    this.loading = true;
    this.processLogin(this.username, this.password).subscribe(
      res => {
        if (res) {
          this.result = res;
          this.close();
        }
      },
      (error: PDServerError) => {
        this.loading = false;
        this.error = error.message;
      }
    );
  }

  signUp() {
    window.location.href = this.envProxy.environment.patient.funnel.register;
  }

  reset() {
    window.location.href = this.envProxy.environment.patient.funnel.forgotpass;
  }
}

import { Location } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AppIntegrationService, TokenService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { SignOutService } from '@pushdr/patientapp/common/utils';
import { filter, map } from 'rxjs/operators';
import { HeaderService } from './pd-header.service';

declare let App: any;

export const HEADER_TYPE = {
  PDR_DEFAULT: 'pdr_default',
  COMMON_FUNNEL: 'common_funnel',
  COMMON_POSTSURGERY: 'common_postsurgery',
};

@Component({
  selector: 'pushdr-header',
  templateUrl: 'pd-header.component.html',
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() type = HEADER_TYPE.PDR_DEFAULT;
  homeUrl = '';
  loggedIn: boolean;

  @HostListener('window:resize')
  onResize() {
    this.header.closeHeaderMenu();
  }

  constructor(
    private router: Router,
    private token: TokenService,
    private location: Location,
    private env: EnvironmentProxyService,
    private appIntegration: AppIntegrationService,
    private signoutService: SignOutService,
    private header: HeaderService
  ) {}

  ngOnInit() {
    // TODO change when i have the URL's so i can test for domain... this doesn't survive logged out no auth
    this.homeUrl = this.env.environment.homepage;
  }

  ngAfterViewInit() {
    this.getLoggedInState();
  }

  get isApp() {
    return this.appIntegration.app;
  }

  getLoggedInState() {
    this.router.events
      .pipe(
        filter(event => {
          return event instanceof NavigationStart;
        }),
        map(url => {
          return this.token.exists();
        })
      )
      .subscribe(state => {
        this.loggedIn = state;
      });
  }

  get accountUrl() {
    return this.env.environment.patient.account.url;
  }

  get inAccount() {
    return this.location.path().indexOf('/account') > -1;
  }

  signOut() {
    this.signoutService.signout(false).subscribe();
  }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';

@Component({
  selector: 'pushdr-no-appointment',
  templateUrl: './no-appointment.component.html',
  standalone: true,
  imports: [AnalyticsDirectivesModule],
})
export class NoAppointmentComponent {
  @Output() pdrBookAppointment = new EventEmitter<boolean>();
}

import { Injectable, OnDestroy } from '@angular/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  AnalyticsBusService,
  AnalyticsIntegrationConfig,
} from '@pushdr/common/data-access/analytics';
import { AccountProfileService } from '../../account-profile/account-profile.service';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

export interface FirebaseAnalyticsConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}
@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService implements OnDestroy {
  private _config: AnalyticsIntegrationConfig;
  private _ngUnsubscribe$ = new Subject<void>();
  private _analytics: firebase.default.analytics.Analytics;
  private _isLoggedIn: boolean;

  firebaseInitialized$ = new Subject<void>();

  constructor(private bus: AnalyticsBusService, private acc: AccountProfileService) {}

  initialise(firebaseConfig: FirebaseAnalyticsConfig, config: AnalyticsIntegrationConfig) {
    this.acc.externalId$.pipe(take(1)).subscribe(externalId => {
      try {
        this._analytics = firebase.default.initializeApp(firebaseConfig).analytics();
        this._isLoggedIn = true;
        this._config = config;
        this.setupUser(externalId);
        this.subscribeToEvents();
        this.firebaseInitialized$.next();
      } catch (e) {
        throw Error('Something went wrong on FirebaseAnalytics initialize');
      }
    });
  }

  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  setCustomUserAttribute(key: string, value: string) {
    this._analytics.setUserProperties({ [key]: value });
  }

  setupUser(userId: string) {
    this._analytics.setUserId(userId);
  }

  private logCustomEvent(event) {
    if (this._isLoggedIn) {
      this._analytics.logEvent(`${event.action}`, event.properties || {});
    }
  }

  private subscribeToEvents() {
    this.bus.eventBus$
      .pipe(
        filter(event => {
          return this._config.events.includes(event.action);
        }),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(event => {
        this.logCustomEvent(event);
      });
  }
}

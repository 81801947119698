import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiEmergencyPatientService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.api + '/emergency.svc/emergencyW/';
  }

  iNeedImmediateHelp() {
    return this.post('/INeedImmediateHelp', {}, this.headerService.authorisedHeadersLegacy);
  }
}

import { Component, OnInit } from '@angular/core';
import { private_registration } from '@pushdr/common/utils';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';

@Component({
  selector: 'pushdr-account-nav-list',
  templateUrl: './account-nav-list.component.html',
})
export class AccountNavListComponent implements OnInit {
  shouldShowAppointments = false;
  isNhsAccount = this.profile.isNHS;

  constructor(private profile: AccountProfileService) {}

  ngOnInit() {
    // Replaces optimizely 'private_registration' flag
    // TODO: Add proper config for 'private_registration' flag
    const privateRegistrationConfig = private_registration;
    this.shouldShowAppointments = this.profile.isNHS || privateRegistrationConfig.enabled;
  }
}

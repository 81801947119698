import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[NumberOnly]',
})
export class InputNumberOnlyDirective implements OnDestroy, OnInit {
  private subscription: Subscription;
  @Input() maxNumber: string;

  constructor(private ngControl: NgControl) {}

  mapToNumberOnly(input) {
    return input.replace(/[^0-9]/g, '');
  }

  ngOnInit() {
    const ctrl = this.ngControl.control;

    this.subscription = ctrl.valueChanges
      .pipe(map(v => this.mapToNumberOnly((v || '').toString())))
      .subscribe(v => ctrl.setValue(v, { emitEvent: false }));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
